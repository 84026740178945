import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServiceWorkerModule } from "@angular/service-worker";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { BrowserModule } from "@angular/platform-browser";
import { LOCATION_INITIALIZED } from "@angular/common";
import { Store, StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
  NgModule,
  Injector,
} from "@angular/core";

// Actions
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";

// Configurations
import { localToCmsLanguageConfigurations } from "src/app/configurations/main.configurations";

// Components
import { UnclaimedFreeSpinRewardCardComponent } from "src/app/modules/rewards/components/unclaimed-free-spin-reward-card/unclaimed-free-spin-reward-card.component";
import { RewardBonusTermsConditionsComponent } from "src/app/modules/rewards/components/reward-bonus-terms-conditions/reward-bonus-terms-conditions.component";
import { PromotionContentPageComponent } from "src/app/modules/promotions/components/promotion-content-page/promotion-content-page.component";
import { GamePlayFullWindowComponent } from "src/app/modules/game-groups/components/game-play-full-window/game-play-full-window.component";
import { CashbackPromoRewardComponent } from "src/app/modules/rewards/components/cashback-promo-reward/cashback-promo-reward.component";
import { TransactionHistoryComponent } from "src/app/modules/transactions/components/transaction-history/transaction-history.component";
import { FreeSpinRewardCardComponent } from "src/app/modules/rewards/components/free-spin-reward-card/free-spin-reward-card.component";
import { RecentSearchGamesComponent } from "src/app/modules/game-groups/components/recent-search-games/recent-search-games.component";
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";
import { UpcomingRewardCardComponent } from "src/app/modules/rewards/components/upcoming-reward-card/upcoming-reward-card.component";
import { ProvideGamesDisplay } from "src/app/modules/game-groups/components/provider-games-display/provider-games-display.component";
import { DepositRewardCardComponent } from "src/app/modules/rewards/components/deposit-reward-card/deposit-reward-card.component";
import { ConfirmIdentityKycComponent } from "src/app/modules/kyc/components/confirm-identity-kyc/confirm-identity-kyc.component";
import { BottomNavigationComponent } from "src/app/modules/navigation/components/bottom-navigation/bottom-navigation.component";
import { PromoRaceCounterComponent } from "src/app/modules/rewards/components/promo-race-counter/promo-race-counter.component";
import { GamePlayWindowComponent } from "src/app/modules/game-groups/components/game-play-window/game-play-window.component";
import { RewardsIndicatorComponent } from "src/app/modules/rewards/components/rewards-indicator/rewards-indicator.component";
import { PendingWithdrawComponent } from "src/app/modules/account/components/pending-withdrawal/pending-withdraw.component";
import { LeftNavigationComponent } from "src/app/modules/navigation/components/left-navigation/left-navigation.component";
import { BrandPromotionComponent } from "src/app/modules/promotions/components/brand-promotion/brand-promotion.component";
import { ProofOfIdentityComponent } from "src/app/modules/kyc/components/proof-of-identity/proof-of-identity.component";
import { AllPromotionsComponent } from "src/app/modules/promotions/components/all-promotions/all-promotions.component";
import { PromotionCardComponent } from "src/app/modules/promotions/components/promotion-card/promotion-card.component";
import { RewardCongratsComponent } from "src/app/modules/rewards/components/reward-congrats/reward-congrats.component";
import { CountdownTimerComponent } from "src/app/modules/shared/components/countdown-timer/countdown-timer.component";
import { ProofOfPaymentComponent } from "src/app/modules/kyc/components/proof-of-payment/proof-of-payment.component";
import { ProofOfAddressComponent } from "src/app/modules/kyc/components/proof-of-address/proof-of-address.component";
import { HostedCashierComponent } from "src/app/modules/account/components/hosted-cashier/hosted-cashier.component";
import { CookieMessageComponent } from "./modules/country-block/components/cookie-message/cookie-message.component";
import { SelfExclusionComponent } from "src/app/modules/limits/components/self-exclusion/self-exclusion.component";
import { SessionLimitsComponent } from "src/app/modules/limits/components/session-limits/session-limits.component";
import { QuickDepositComponent } from "src/app/modules/account/components/quick-deposit/quick-deposit.component";
import { PaymentBonusComponent } from "src/app/modules/account/components/payment-bonus/payment-bonus.component";
import { BetHistoryComponent } from "src/app/modules/transactions/components/bet-history/bet-history.component";
import { NotificationsComponent } from "src/app/modules/chat/components/notifications/notifications.component";
import { SourceOfFundComponent } from "src/app/modules/kyc/components/source-of-fund/source-of-fund.component";
import { AccountMenuComponent } from "src/app/modules/account/components/account-menu/account-menu.component";
import { LandingPageComponent } from "src/app/modules/shared/components/landing-page/landing-page.component";
import { GameLobbyComponent } from "src/app/modules/game-groups/components/game-lobby/game-lobby.component";
import { AllRewardsComponent } from "src/app/modules/rewards/components/all-rewards/all-rewards.component";
import { PreferencesComponent } from "src/app/modules/shared/components/preferences/preferences.component";
import { TagGameComponent } from "src/app/modules/game-groups/components/tag-game/tag-game.component";
import { RgLimitsComponent } from "src/app/modules/limits/components/rg-limits/rg-limits.component";
import { FooterComponent } from "src/app/modules/static-pages/components/footer/footer.component";
import { RedirectComponent } from "src/app/modules/shared/components/redirect/redirect.component";
import { CashierComponent } from "src/app/modules/account/components/cashier/cashier.component";
import { LimitsComponent } from "src/app/modules/limits/components/limits/limits.component";
import { HeaderComponent } from "src/app/modules/shared/components/header/header.component";
import { HomeComponent } from "src/app/modules/shared/components/home-page/home.component";
import { BonusComponent } from "src/app/modules/rewards/components/bonus/bonus.component";
import { TimeComponent } from "src/app/modules/shared/components/time/time.component";
import { AppComponent } from "src/app/app.component";

import { LiveCasinoComponent } from "src/app/modules/game-groups/components/live-casino/live-casino.component";

// Directives
import { InputCurrencyPlacementDirective } from "src/app/modules/shared/directives/input-currency-placement.directive";

// Effects
import { CountryBlockEffects } from "src/app/modules/country-block/store/effects/country-block.effects";

// Enums
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";

// Environments
import { environment } from "src/environments/environment";

// Firebase
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from "@angular/fire";

// Interceptors
import { AuthenticationInterceptor } from "src/app/modules/auth/interceptors/authentication.interceptor";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { MultiLanguagesModule } from "src/app/modules/multi-languages/multi-languages.module";
import { RegistrationModule } from "src/app/modules/registration/registration.module";
import { DynamicFormModule } from "src/app/modules/dynamic-form/dynamic-form.module";
import { GameGroupsModule } from "src/app/modules/game-groups/game-groups.module";
import { ValidatorsModule } from "src/app/modules/validators/validators.module";
import { LivespinsModule } from "src/app/modules/livespins/livespins.module";
// import { MaterialModule } from "src/app/modules/material/material.module";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { BannerModule } from "src/app/modules/banner/banner.module";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AuthModule } from "src/app/modules/auth/auth.module";
import { UserModule } from "src/app/modules/user/user.module";

// Pipes
import { PaymentMethodNamePipe } from "src/app/modules/shared/pipes/payment-method-name.pipe";

// Reducers
import * as fromCountryBlockReducer from "src/app/modules/country-block/store/reducers/country-block.reducer";
import {
  reducerProvider,
  REDUCERS_TOKEN,
  metaReducers,
  AppState,
} from "src/app/store/reducers";

// Services
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { MainService } from "src/app/modules/shared/services/main.service";

// Utilities
import { loadProperLanguage } from "src/app/modules/shared/utilities/extract-proper-language.utilities";

@NgModule({
  declarations: [
    UnclaimedFreeSpinRewardCardComponent,
    RewardBonusTermsConditionsComponent,
    InputCurrencyPlacementDirective,
    PromotionContentPageComponent,
    CashbackPromoRewardComponent,
    GamePlayFullWindowComponent,
    TransactionHistoryComponent,
    ConfirmIdentityKycComponent,
    FreeSpinRewardCardComponent,
    UpcomingRewardCardComponent,
    RecentSearchGamesComponent,
    SharedTransactionComponent,
    DepositRewardCardComponent,
    BottomNavigationComponent,
    RewardsIndicatorComponent,
    PromoRaceCounterComponent,
    PendingWithdrawComponent,
    ProofOfIdentityComponent,
    GamePlayWindowComponent,
    LeftNavigationComponent,
    ProofOfPaymentComponent,
    RewardCongratsComponent,
    CountdownTimerComponent,
    BrandPromotionComponent,
    ProofOfAddressComponent,
    NotificationsComponent,
    SelfExclusionComponent,
    SessionLimitsComponent,
    PromotionCardComponent,
    AllPromotionsComponent,
    HostedCashierComponent,
    CookieMessageComponent,
    PaymentBonusComponent,
    QuickDepositComponent,
    SourceOfFundComponent,
    PaymentMethodNamePipe,
    AccountMenuComponent,
    PreferencesComponent,
    AccountMenuComponent,
    PreferencesComponent,
    LandingPageComponent,
    LiveCasinoComponent,
    BetHistoryComponent,
    BetHistoryComponent,
    AllRewardsComponent,
    ProvideGamesDisplay,
    GameLobbyComponent,
    RgLimitsComponent,
    RedirectComponent,
    TagGameComponent,
    CashierComponent,
    HeaderComponent,
    FooterComponent,
    LimitsComponent,
    LimitsComponent,
    BonusComponent,
    HomeComponent,
    TimeComponent,
    AppComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    MultiLanguagesModule,
    ReactiveFormsModule,
    RegistrationModule,
    DynamicFormModule,
    DynamicFormModule,
    AppRoutingModule,
    HttpClientModule,
    GameGroupsModule,
    ValidatorsModule,
    LivespinsModule,
    // MaterialModule,
    BrowserModule,
    SharedModule,
    BannerModule,
    FormsModule,
    MbscModule,
    UserModule,
    AuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([CountryBlockEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreModule.forFeature(
      fromCountryBlockReducer.CountryBlockStateFeatureKey,
      fromCountryBlockReducer.reducer
    ),
  ],
  providers: [
    PromotionsService,
    reducerProvider,
    MainService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, Store],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.apiUrl}${environment.languagePath}`,
    `.json?cb=${new Date().getTime()}`
  );
}

/*
  This AppInitializer Factory is used for making API call 
  during app initialization itself

  Basically Here we apss default language of our application & also 
  store them in local storage for futher use.. 
*/
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  store: Store<AppState>
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized: Promise<any> = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );

      locationInitialized.then(() => {
        let windowPathName: string = window.location.pathname;

        let languageCode: string = loadProperLanguage(windowPathName);

        let language: string = localToCmsLanguageConfigurations[languageCode];

        store.dispatch(
          languageCodeLoaded({
            language: LanguageCodes[language],
            languageCode,
          })
        );

        translate.setDefaultLang(language);

        translate.use(language).subscribe(
          () => {},
          () => {},
          () => {
            resolve(null);
          }
        );
      });
    });
}
