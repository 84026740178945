import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TotalJackpotFeed } from "src/app/modules/game-groups/models/jackpot/total-jackpot-feed.model";
import { MicroGamingJackpot } from "src/app/modules/game-groups/models/micro-gaming-jackpot.model";
import { JackpotFeed } from "src/app/modules/game-groups/models/jackpot/jackpot-feed.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class JackpotService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Arrays
  microGamingJackpotFeed?: JackpotFeed[] = [];

  // Others
  mircroGameJackpots?: MicroGamingJackpot = {};

  // ---------------------------------------------------------//
  // Behaviour Subjects
  public mircoGamingJackpotDataBehavourSubject: BehaviorSubject<MicroGamingJackpot> =
    new BehaviorSubject<MicroGamingJackpot>(this.mircroGameJackpots);
  public mircoGamingJackpotDataBehavourSubject$: Observable<MicroGamingJackpot> =
    this.mircoGamingJackpotDataBehavourSubject.asObservable();

  // --------------------------------------------------------
  // Subscriptions
  jackpotSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private mainService: MainService,
    private store: Store<AppState>,
    private httpClient: HttpClient
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.onGetMessagesJackpots();

    this.subscription =
      this.userDetailsService.userProfileUpdatedSubject$.subscribe(() =>
        this.onGetMessagesJackpots()
      );
  }

  // -----------------------------------------------------------------
  // Get Methods

  onGetMicroGamingTotalJackpotsAmount(
    currencyCode: WorldCurrencyCode
  ): Observable<TotalJackpotFeed> {
    return this.apiInteractor.getJackpot<TotalJackpotFeed>(
      `${environment.microGamingJackpotsUrl}/jackpots/total?currencyIsoCode=${currencyCode}`
    );
  }

  getJackpotFeedList(): Observable<JackpotFeed[]> {
    return this.onGetMicroGamingJackpotFeed(
      this.userDetailsService.getCurrencyCode()
    ).pipe(
      map((jackpotFeedList: JackpotFeed[]) => {
        return jackpotFeedList;
      })
    );
  }

  onGetMicroGamingJackpotFeed(
    currencyCode: WorldCurrencyCode
  ): Observable<JackpotFeed[]> {
    return this.apiInteractor.getJackpot<JackpotFeed[]>(
      `${environment.microGamingJackpotsUrl}/counters?currencyIsoCode=${currencyCode}`
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetMessagesJackpots(): void {
    this.jackpotSubscription = this.getJackpotFeedList().subscribe(
      (jackpotFeedList: JackpotFeed[]) => {
        this.microGamingJackpotFeed = jackpotFeedList;

        this.mircroGameJackpots = {};
        if (jackpotFeedList.length > 0) {
          this.store
            .select(selectAllGames)
            .subscribe((gamesList: GamePregmatic[]) => {
              let microGamingGamesList: GamePregmatic[] = _.where(gamesList, {
                gameType: "progressive_slots",
              });

              jackpotFeedList.filter((jackpotFilter: JackpotFeed) => {
                let gameName: string = jackpotFilter.friendlyName
                  .replace(/[\W_]+/g, " ")
                  .trim();

                microGamingGamesList.filter(
                  (filterGamePregmatic: GamePregmatic) => {
                    if (
                      filterGamePregmatic.name
                        .toUpperCase()
                        .indexOf(gameName.toUpperCase()) > -1 &&
                      jackpotFilter.gamePayId == 0
                    ) {
                      this.mircroGameJackpots[filterGamePregmatic.gameCode] =
                        jackpotFilter.startAtValue;
                    }
                  }
                );
              });

              this.onBroadCastMicroGamingJackpotSuccess();
            });
        }
      }
    );
  }

  onBroadCastMicroGamingJackpotSuccess(): void {
    this.mircoGamingJackpotDataBehavourSubject.next(this.mircroGameJackpots);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.jackpotSubscription) this.jackpotSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
