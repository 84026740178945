import { ValidationErrors, FormControl, ValidatorFn } from "@angular/forms";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

// Configurations
import { userMinimumRGLimitCheckConfigurations } from "src/app/configurations/main.configurations";
import { nameRegex } from "src/app/modules/validators/configurations/regex.configurations";

// Models
import { NetDepositLimitsResponse } from "src/app/modules/limits/models/net-deposit-limits/net-deposit-limits-response.model";
import { ValidationResponse } from "src/app/modules/registration/models/validation/validation-response.model";
import { ValidationRequest } from "src/app/modules/registration/models/validation/validation-request.model";
import { LimitIntervals } from "src/app/modules/limits/models/limits-intervals.model";
import { LimitsResponse } from "src/app/modules/limits/models/limits-response.model";
import {
  ValidateMessage,
  ValidateResult,
} from "src/app/modules/shared/models/validation/validate-message.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Injectable({
  providedIn: "root",
})
export class CustomValidatorService {
  // Strings
  currencySymbol: string = "";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    public translationService: TranslationService,
    public userDetailsService: UserDetailsService,
    public currencyFormatPipe: CurrencyFormatPipe
  ) {
    this.subscriptions = [
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods
  getTranslation = (key: string, interPolateParams?: Object): string => {
    if (interPolateParams) {
      let response: string = this.translationService.get(
        key,
        interPolateParams
      );

      return response;
    } else {
      let response: string = this.translationService.get(key);

      return response;
    }
  };

  /*
    Remaining:
      None
  */
  validateConfirmPassword(): ValidateResult {
    let getTranslation = this.getTranslation;

    let validationList: ValidateMessage = {};

    return (control: FormControl): ValidateMessage => {
      if (!control.get("password").value) {
        let messagePartOne: string = getTranslation("errors.error1");

        control.get("password").setErrors({
          message: messagePartOne,
        });

        validationList = {
          message: messagePartOne,
        };
      }
      if (!control.get("confirmPassword").value) {
        let messagePartOne: string = getTranslation("errors.error1");

        control.get("confirmPassword").setErrors({
          message: messagePartOne,
        });

        validationList = {
          message: messagePartOne,
        };
      } else if (
        control.get("confirmPassword").value &&
        control.get("password").value &&
        control.get("password").value !== control.get("confirmPassword").value
      ) {
        let messagePartTwo: string = getTranslation("errors.error2");

        control.get("confirmPassword").setErrors({
          message: messagePartTwo,
        });

        validationList = {
          message: messagePartTwo,
        };
      } else {
        control.get("confirmPassword").setErrors(null);

        validationList = {};
      }

      return validationList;
    };
  }

  /*
    Remaining:
      form-control-validators.utilities.
  */
  validateName(minChar: number, maxChar: number): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const controlValue: string = control.value;

      let regex: RegExp = /_/;

      let validationList: ValidateMessage = {};

      if (!controlValue) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (controlValue && controlValue.length < minChar) {
        let messagePartThree: string = getTranslation("errors.error3");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartThree}${minChar}${messagePartFour}`,
        };
      } else if (controlValue && controlValue.length > maxChar) {
        let messagePartEight: string = getTranslation("errors.error8");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartEight}${maxChar}${messagePartFour}`,
        };
      } else if (!nameRegex.test(controlValue) || regex.test(controlValue)) {
        let messagePartFive: string = getTranslation("errors.error5");

        validationList = {
          message: messagePartFive,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      None
  */
  validateMinMaxCharacters(minChar: number, maxChar: number): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const name: string = control.value;

      let validationList: ValidateMessage = {};

      if (!name) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (name && name.length < minChar) {
        let messagePartThree: string = getTranslation("errors.error3");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartThree}${minChar}${messagePartFour}`,
        };
      } else if (name && name.length > maxChar) {
        let messagePartEight: string = getTranslation("errors.error8");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartEight}${maxChar}${messagePartFour}`,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      None
  */
  validateAlphaNumeric(minChar: number, maxChar: number): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const controlValue: string = control.value;

      const regex: RegExp = /^[a-z0-9]+$/i;

      let validationList: ValidateMessage = {};

      if (!controlValue) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (!regex.test(controlValue)) {
        let messagePartSix: string = getTranslation("errors.error6");

        validationList = {
          message: messagePartSix,
        };
      } else if (controlValue && controlValue.length < minChar) {
        let messagePartThree: string = getTranslation("errors.error3");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartThree}${minChar}${messagePartFour}`,
        };
      } else if (controlValue && maxChar && controlValue.length > maxChar) {
        let messagePartSeven: string = getTranslation("errors.error7");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartSeven}${maxChar}${messagePartFour}`,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      cashier.component
      quick-deposit.component
  */
  validateMinNumericValue(
    minValue: string,
    maxValue: string,
    fieldName: string
  ): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      let controlValue: string = control.value;

      let computedValue: number = +controlValue;

      let minValueNumeric: number = Number(minValue);

      let maxValueNumeric: number = Number(maxValue);

      const regex: RegExp = /^[0-9]*(\.\d{0,2})?/;

      let validationList: ValidateMessage = {};

      if (!computedValue) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (controlValue && minValue && computedValue < minValueNumeric) {
        let messagePartTen: string = getTranslation("errors.error10");

        validationList = {
          message: `${fieldName}${messagePartTen}${minValue}`,
        };
      } else if (controlValue && maxValue && computedValue > maxValueNumeric) {
        let messagePartEleven: string = getTranslation("errors.error11");

        validationList = {
          message: `${fieldName}${messagePartEleven}${maxValue}`,
        };
      } else if (controlValue) {
        computedValue = Number(controlValue);

        if (!regex.test(controlValue)) {
          let messagePartTwelve: string = getTranslation("errors.error12");

          validationList = {
            message: messagePartTwelve,
          };
        } else if (!controlValue) {
          let messagePartTwelve: string = getTranslation("errors.error12");

          validationList = {
            message: messagePartTwelve,
          };
        }
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      form-control-validators.utilities.ts
  */
  validateUniqueFields(
    fieldToValidate: string,
    commonService: CommonService,
    isUniqueValidation: boolean
  ): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const controlValue: string = control.value;

      const regex: RegExp =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

      let validationList: ValidateMessage = {};

      if (!controlValue) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (
        controlValue &&
        fieldToValidate == "txtEmail" &&
        !regex.test(controlValue)
      ) {
        let messagePartTwentyTwo: string = getTranslation("errors.error22");

        validationList = {
          message: messagePartTwentyTwo,
        };
      } else if (
        controlValue &&
        fieldToValidate == "txtEmail" &&
        (controlValue.length < 5 || controlValue.length > 50)
      ) {
        let messagePartSixtyOne: string = getTranslation("errors.error61");

        validationList = {
          message: messagePartSixtyOne,
        };
      } else if (
        controlValue &&
        fieldToValidate == "txtNickname" &&
        controlValue.length < 5 &&
        !regex.test(controlValue)
      ) {
        let messagePartTwentyThree: string = getTranslation("errors.error23");

        validationList = {
          message: messagePartTwentyThree,
        };
      } else if (isUniqueValidation) {
        const validationRequest: ValidationRequest = {
          [fieldToValidate]: controlValue,
        };

        commonService
          .onGetValidateUniqueness(validationRequest)
          .subscribe((validationResponse: ValidationResponse) => {
            if (validationResponse && validationResponse.response == 1) {
              validationList = null;

              control.setErrors(null);
            } else if (
              validationResponse &&
              validationResponse.response == -1
            ) {
              let messagePartTwentyFive: string =
                getTranslation("errors.error25");

              let messagePartTwentySix: string =
                getTranslation("errors.error26");

              let messagePartTwentyEight: string =
                getTranslation("errors.error28");

              validationList = {
                message:
                  messagePartTwentySix +
                  (fieldToValidate === "txtEmail"
                    ? messagePartTwentyEight
                    : messagePartTwentyFive),
              };

              control.setErrors({
                message:
                  messagePartTwentySix +
                  (fieldToValidate === "txtEmail"
                    ? messagePartTwentyEight
                    : messagePartTwentyFive),
              });
            } else {
              let messagePartTwentyNine: string =
                getTranslation("errors.error29");

              let messagePartThirty: string = getTranslation("errors.error30");

              validationList = {
                message:
                  fieldToValidate == "txtEmail"
                    ? messagePartTwentyNine
                    : messagePartThirty,
              };

              control.setErrors(validationList);
            }
          });
      }

      return validationList;
    };
  }

  /*
    Remaining:
      None
  */
  validatePassword(minChar: number, maxChar: number): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const password: string = control.value;

      let validationList: ValidateMessage = {};

      if (!password) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (password && password.length < minChar) {
        let messagePartTwentyFour: string = getTranslation("errors.error24");

        validationList = {
          message: messagePartTwentyFour,
        };
      } else if (password && password.length > maxChar) {
        let messagePartFiftySeven: string = getTranslation("errors.error57");

        let messagePartFiftyEight: string = getTranslation("errors.error58");

        validationList = {
          message: `${messagePartFiftySeven}${maxChar}${messagePartFiftyEight}`,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      quick-deposit.component
      form-control-validators.utilities
  */
  validateRequired(): ValidatorFn {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidationErrors | null => {
      const value: string = control.value;

      let validationList: ValidateMessage = {};

      if (!value || value.length <= 0) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      cashier.component
      quick-deposit.component
      form-control-validators.utilities
  */
  validateMin(minNum: number): ValidationErrors {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const value: string = control.value;

      let validationList: ValidateMessage = {};

      if (!value) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (value.length < minNum) {
        let messagePartThree: string = getTranslation("errors.error3");

        let messagePartFour: string = getTranslation("errors.error4");

        validationList = {
          message: `${messagePartThree}${minNum}${messagePartFour}`,
        };

        control.setErrors(validationList);
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      form-control-validators.utilities
  */
  validateExpiryDateCard(): ValidationErrors {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const value: string = control.value;

      const date: Date = new Date();

      const regex: RegExp = /^(((0)[0-9])|((1)[0-2]))(\/)\d{2}$/i;

      let validationList: ValidateMessage = {};

      if (!value) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (!regex.test(value)) {
        let messagePartSixtyFour: string = getTranslation("errors.error64");

        validationList = {
          message: messagePartSixtyFour,
        };
      } else {
        const month: number = date.getMonth() + 1;

        const year: number = date.getFullYear();

        const expiryMonth: number = parseInt(value.split("/")[0]);

        const expiryYear: number = parseInt("20" + value.split("/")[1]);

        if (expiryYear < year) {
          let messagePartSixtyFour: string = getTranslation("errors.error64");

          validationList = {
            message: messagePartSixtyFour,
          };
        } else if (expiryYear === year && expiryMonth < month) {
          let messagePartSixtyFour: string = getTranslation("errors.error64");

          validationList = {
            message: messagePartSixtyFour,
          };
        }
      }

      return validationList;
    };
  }

  /*
    Remaining:
      None
  */
  validateNumericValue(): ValidationErrors {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      const controlValue: string = control.value;

      const regex: RegExp = /^[0-9]+$/;

      let validationList: ValidateMessage = {};

      if (!controlValue) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (!regex.test(controlValue)) {
        let messagePartNine: string = getTranslation("errors.error9");

        validationList = {
          message: messagePartNine,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      quick-deposit.component
      form-control-validators.utilities
  */
  validateExactNumber(
    value: number,
    isWhiteSpacesExcluded?: boolean
  ): ValidateResult {
    let getTranslation = this.getTranslation;

    return (control: FormControl): ValidateMessage => {
      let name: string =
        typeof control.value == "string" && control.value
          ? control.value.trim()
          : control.value;

      if (name && isWhiteSpacesExcluded) {
        name = name.replace(/ /g, "");
      }

      const length: number = name && name.toString().length;

      let computedValue: number = Number(value);

      let regex: RegExp = /^[0-9]*$/;

      let validationList: ValidateMessage = {};

      if (!name) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };
      } else if (name && !regex.test(name)) {
        let messagePartNine: string = getTranslation("errors.error9");

        validationList = {
          message: messagePartNine,
        };
      } else if (name && computedValue && length !== computedValue) {
        let messagePartSixtyFive: string = getTranslation("errors.error65");

        let messagePartTwentyOne: string = getTranslation("errors.error21");

        validationList = {
          message: `${messagePartSixtyFive}${computedValue}${messagePartTwentyOne}`,
        };
      } else {
        validationList = null;
      }

      return validationList;
    };
  }

  /*
    Remaining:
      None
  */
  validateResponsibleGamingLimits(
    limits: LimitIntervals | LimitsResponse | NetDepositLimitsResponse
  ): ValidateResult {
    let getTranslation = this.getTranslation;

    let userCurrency: string = this.userDetailsService.getCurrencySymbol();

    let userMinimumLimit: number =
      userMinimumRGLimitCheckConfigurations[userCurrency];

    return (control: FormControl): ValidateMessage => {
      const period: string = control.get("period").value;

      const value: number = control.get("limit").value;

      const regex: RegExp = /^[0-9]*$/;

      let validationList: ValidateMessage = {};

      if (!value) {
        let messagePartOne: string = getTranslation("errors.error1");

        validationList = {
          message: messagePartOne,
        };

        control.get("limit").setErrors(validationList);

        return validationList;
      } else if (!regex.test(value.toString())) {
        let messagePartNine: string = getTranslation("errors.error9");

        validationList = {
          message: messagePartNine,
        };

        control.get("limit").setErrors(validationList);

        return validationList;
      } else if (value < userMinimumLimit) {
        let messagePartThirtyEight: string = getTranslation("errors.error38");

        let formattedUserMinimum: string = this.currencyFormatPipe.transform(
          userMinimumLimit,
          this.currencySymbol
        );

        validationList = {
          message: `${messagePartThirtyEight}${formattedUserMinimum}`,
        };

        control.get("limit").setErrors(validationList);

        return validationList;
      }

      let currentLimits: LimitIntervals = limits as LimitIntervals;

      if (currentLimits) {
        switch (period) {
          case "dailyLimit": {
            // tslint:disable-next-line:max-line-length
            if (
              (currentLimits.weeklyLimit &&
                value > currentLimits.weeklyLimit / 100) ||
              (currentLimits["monthlyLimit"] &&
                value > currentLimits.monthlyLimit / 100)
            ) {
              let messagePartThirtyNine: string =
                getTranslation("errors.error39");

              validationList = {
                message: messagePartThirtyNine,
              };

              control.get("limit").setErrors(validationList);
            } else {
              validationList = null;
            }

            return validationList;
          }
          case "weeklyLimit": {
            if (
              currentLimits.monthlyLimit &&
              value > currentLimits.monthlyLimit / 100
            ) {
              let messagePartFortyOne: string =
                getTranslation("errors.error41");

              validationList = {
                message: messagePartFortyOne,
              };

              control.get("limit").setErrors(validationList);
            } else if (
              currentLimits.dailyLimit &&
              currentLimits.pendingDailyLimit / 100 &&
              value < currentLimits.pendingDailyLimit / 100
            ) {
              let messagePartFiftyNine: string =
                getTranslation("errors.error59");

              validationList = {
                message: messagePartFiftyNine,
              };

              control.get("limit").setErrors(validationList);
            } else {
              validationList = null;
            }

            return validationList;
          }
          case "monthlyLimit": {
            if (
              currentLimits.weeklyLimit &&
              value < currentLimits.weeklyLimit / 100
            ) {
              let messagePartFortyTwo: string =
                getTranslation("errors.error42");

              validationList = {
                message: messagePartFortyTwo,
              };

              control.get("limit").setErrors(validationList);
            }

            if (
              (currentLimits.dailyLimit &&
                currentLimits.pendingDailyLimit &&
                value < currentLimits.pendingDailyLimit / 100) ||
              (currentLimits.weeklyLimit &&
                currentLimits.pendingWeeklyLimit &&
                value < currentLimits.pendingWeeklyLimit / 100)
            ) {
              let messagePartSixty: string = getTranslation("errors.error60");

              validationList = {
                message: messagePartSixty,
              };

              control.get("limit").setErrors(validationList);
            } else {
              validationList = null;
            }

            return validationList;
          }
        }
      }
    };
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
