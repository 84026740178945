import { Component, Input, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Libraries
import { LiveStream } from "livespins/@livespins";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

@Component({
  selector: "app-csn-livespins-room",
  templateUrl: "./csn-livespins-room.component.html",
  styleUrls: ["./csn-livespins-room.component.scss"],
})
export class CsnLivespinsRoomComponent {
  // Inputs
  @Input() liveStream: LiveStream;

  // Strings
  languageCode: string = "";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["liveStream"].currentValue &&
      changes["liveStream"].previousValue != changes["liveStream"].currentValue
    ) {
      this.liveStream = changes["liveStream"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
