import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ActivatedRoute,
  NavigationEnd,
  RouterEvent,
  Router,
} from "@angular/router";
import {
  ViewEncapsulation,
  HostListener,
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Models
import { LobbyByPageNameConfigurations } from "src/app/models/configurations/menu/lobby-by-page-name-configuration.model";
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";
import { NavigationData } from "src/app/modules/shared/models/navigation/navigation.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";

@Component({
  selector: "app-game-lobby",
  templateUrl: "./game-lobby.component.html",
  styleUrls: ["./game-lobby.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GameLobbyComponent implements OnChanges {
  // Outputs
  @Output() callOnSelectedLobby: EventEmitter<string> =
    new EventEmitter<string>();

  // Inputs
  @Input() callingFrom: string = "";

  // Strings
  userCountry: string = "";
  activeLobby: string = "";

  // Booleans
  isLastPlayedLiveGamesExist: boolean = false;
  isbetRangeDropDownOpen: boolean = false;
  isShowSearchResult: boolean = false;
  isSearchRequired: boolean = true;
  isLoggedIn: boolean = false;

  // Objects
  menuConfigurationType: MenuConfigurationType;
  userProfileData: UserData;

  // Swiper
  swiperGameLobby: SwiperConfigInterface = {
    slidesPerView: "auto",
    slidesPerGroup: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: true,
    resistanceRatio: 0,
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private gameGroupsService: GameGroupsService,
    private mixPanelService: MixPanelService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.activeLobby = this.commonService.getActiveLobby();

    this.subscriptions = [
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.userProfileData = userData;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          const lobbyName: string = this.commonService.getActiveLobby();

          if (lobbyName) {
            this.onNavigateToLobby(lobbyName);
          }
        }
      }),
      this.commonService.navigationDataSubject$.subscribe(
        (navigationData: NavigationData) => {
          if (navigationData && navigationData.lobby) {
            this.activeLobby = navigationData.lobby;

            this.onNavigateToLobby(this.activeLobby);
          }
        }
      ),
      this.gameGroupsService.isLiveGamesLastPlayedAvailableBehaviorSubject$.subscribe(
        (isExist: boolean) => {
          this.isLastPlayedLiveGamesExist = isExist;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      let lobby: string = "";

      this.callingFrom = changes["callingFrom"].currentValue;

      if (getQueryParams() && getQueryParams().lobby && this.isLoggedIn) {
        lobby = getQueryParams().lobby;
      } else if (this.commonService.getActiveLobby()) {
        lobby = this.commonService.getActiveLobby();
      } else {
        lobby = this.callingFrom === "casinoLobby" ? "casino" : "all-live";
      }

      this.menuConfigurationType = this.getLobbyConfigurationData(
        this.callingFrom
      );

      this.onNavigateToLobby(lobby);
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("document:touchend", ["$event"])
  @HostListener("document:click", ["$event"])
  onClick(event): void {
    if (event.target.classList.contains("betRange-dropdown--backdrop")) {
      if (this.isbetRangeDropDownOpen) {
        event.preventDefault();
      }

      this.isbetRangeDropDownOpen = false;
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getLobbyConfigurationData(callingFrom: string): MenuConfigurationType {
    let menuConfigurationType: MenuConfigurationType;

    if (callingFrom === "casinoLobby") {
      menuConfigurationType =
        this.utilityService.getBrandMenuConfigurationType("casinoLobbyConfig");
    } else if (callingFrom === "liveCasinoLobby") {
      menuConfigurationType = this.utilityService.getBrandMenuConfigurationType(
        "liveCasinolobbyConfig"
      );
    }

    return menuConfigurationType;
  }

  getAssignProperLobby(name: string): string {
    const menuConfigurationType: MenuConfigurationType =
      this.utilityService.getBrandMenuConfigurationType("lobbyByPageName");

    let lobbies: LobbyByPageNameConfigurations =
      menuConfigurationType as LobbyByPageNameConfigurations;

    if (
      this.router.url.includes("/casino") &&
      lobbies &&
      lobbies.casinolobbies &&
      lobbies.casinolobbies.indexOf(name) > -1
    ) {
      return name;
    } else if (
      this.router.url.includes("/live-casino") &&
      lobbies &&
      lobbies.livecasinolobbies &&
      lobbies.livecasinolobbies.indexOf(name) > -1
    ) {
      return name;
    } else if (this.router.url.includes("/casino")) {
      return "casino";
    } else if (this.router.url.includes("/live-casino")) {
      return "all-live";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onOpenBetRangeFilter(): void {
    this.isbetRangeDropDownOpen = !this.isbetRangeDropDownOpen;
  }

  onNavigateToLobby(lobbyName: string, callingFrom?: string): void {
    if (
      callingFrom === "list" &&
      Object.keys(this.activatedRoute.snapshot.queryParams).length > 0
    ) {
      this.onClearQueryParams();
    }

    this.activeLobby = this.getAssignProperLobby(lobbyName);

    if (callingFrom === "list") {
      this.onSendMixPanelEvent();
    }

    this.commonService.onSetActiveLobby(this.activeLobby);

    this.callOnSelectedLobby.emit(this.activeLobby);
  }

  onClearQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
  }

  onOpenSearchResultsAtDesktop(): void {
    const searchLobbyNavElem: HTMLCollectionOf<Element> =
      document.getElementsByClassName("searchAtlobbynav");

    if (searchLobbyNavElem && searchLobbyNavElem.length > 0) {
      const rect: DOMRect | ClientRect =
        searchLobbyNavElem[0].getBoundingClientRect();

      document.querySelector("html, body").scrollBy({
        top: rect.top ? rect.top - 20 : 0,
        behavior: "smooth",
      });
    }
  }

  onOpenSearchResultsAtMobile(): void {
    this.isShowSearchResult = true;

    this.utilityService.onAddClassToAppBody("overflow-hidden");
  }

  onCloseSearchResultsAtMobile(): void {
    this.isShowSearchResult = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onSendMixPanelEvent(): void {
    this.mixPanelService.onTrackMixPanelEvents(
      mixPanelEventsConfigurations.clicked_Game_Category,
      {
        name: this.activeLobby,
        vertical: this.mixPanelService.getVerticalByUrl(),
      }
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
