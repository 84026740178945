import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// API Interactors
import { PaymentInteractors } from "src/app/models/interactors/payment.interactor";

// Libraries
import * as _ from "underscore";

// Models
import { DeletePaymentMethodResponse } from "src/app/modules/transactions/models/delete-payment-method/delete-payment-method-response.model";
import { PiqTransactionStatusResponse } from "src/app/modules/account/models/piq-transaction-status/piq-transaction-status-response.model";
import { DeletePaymentMethodRequest } from "src/app/modules/transactions/models/delete-payment-method/delete-payment-method-request.model";
import { UserPaymentMethodsResponse } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { PiqTransactionStatusRequest } from "src/app/modules/account/models/piq-transaction-status/piq-transaction-status-request.model";
import { PendingWithdrawalsResponse } from "src/app/modules/transactions/models/pending-withdrawals/pending-withdrawals-response.model";
import { UserPaymentMethodsRequest } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-request.model";
import { PendingWithdrawalsRequest } from "src/app/modules/transactions/models/pending-withdrawals/pending-withdrawals-request.model";
import { CancelTransactionResponse } from "src/app/modules/transactions/models/cancel-transaction/cancel-transaction-response.model";
import { CancelTransactionRequest } from "src/app/modules/transactions/models/cancel-transaction/cancel-transaction-request.model";
import { UserPIQAccountResponse } from "src/app/modules/transactions/models/user-piq-account/user-piq-account-response.model";
import { UserPIQAccountRequest } from "src/app/modules/transactions/models/user-piq-account/user-piq-account-request.model";
import { ProcessPaymentResponse } from "src/app/modules/account/models/process-payments/process-payment-response.model";
import { ProcessPaymentRequest } from "src/app/modules/account/models/process-payments/process-payment-request.model";
import { PiqDetails } from "src/app/modules/account/models/piq-details.model";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  // API Interactions
  paymentInteractor: PaymentInteractors;

  constructor(private httpClient: HttpClient) {
    this.paymentInteractor = new PaymentInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Pending Withdrawals
  onPendingWithdrawals(
    pendingWithdrawalsRequest: PendingWithdrawalsRequest
  ): Observable<PendingWithdrawalsResponse> {
    let merchantId: string = pendingWithdrawalsRequest.merchantId;
    let userId: string = pendingWithdrawalsRequest.userId;

    delete pendingWithdrawalsRequest.merchantId;
    delete pendingWithdrawalsRequest.userId;

    return this.paymentInteractor
      .get<PendingWithdrawalsRequest, PendingWithdrawalsResponse>(
        `/api/user/transaction/${merchantId}/${userId}`,
        pendingWithdrawalsRequest
      )
      .pipe(
        map((pendingWithdrawalsResponse: PendingWithdrawalsResponse) => {
          return pendingWithdrawalsResponse;
        }),
        catchError(() => {
          return throwError(`Unable to get pending withdrawals`);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables User used PIQ Accounts
  onGetUserUsedPIQAccounts(
    userPIQAccountRequest: UserPIQAccountRequest
  ): Observable<UserPIQAccountResponse> {
    if (
      !userPIQAccountRequest ||
      _.isEmpty(userPIQAccountRequest) ||
      !userPIQAccountRequest.merchantId ||
      !userPIQAccountRequest.userId ||
      !userPIQAccountRequest.sessionId
    ) {
      return throwError("RequiredDetails Not available");
    }

    let merchantId: string = userPIQAccountRequest.merchantId;

    let userId: string = userPIQAccountRequest.userId;

    delete userPIQAccountRequest.merchantId;
    delete userPIQAccountRequest.userId;

    return this.paymentInteractor
      .get<UserPIQAccountRequest, UserPIQAccountResponse>(
        `/api/user/account/${merchantId}/${userId}`,
        userPIQAccountRequest
      )
      .pipe(
        map((userPIQAccountResponse: UserPIQAccountResponse) => {
          return userPIQAccountResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables User PIQ Methods
  onGetUserPIQMethods(
    userPaymentMethodsRequest: UserPaymentMethodsRequest
  ): Observable<UserPaymentMethodsResponse> {
    if (
      !userPaymentMethodsRequest ||
      _.isEmpty(userPaymentMethodsRequest) ||
      !userPaymentMethodsRequest.merchantId ||
      !userPaymentMethodsRequest.userId ||
      !userPaymentMethodsRequest.sessionId
    ) {
      return throwError("RequiredDetails Not available");
    }

    let merchantId: string = userPaymentMethodsRequest.merchantId;

    let userId: string = userPaymentMethodsRequest.userId;

    delete userPaymentMethodsRequest.merchantId;
    delete userPaymentMethodsRequest.userId;

    return this.paymentInteractor
      .get<UserPaymentMethodsRequest, UserPaymentMethodsResponse>(
        `/api/user/payment/method/${merchantId}/${userId}`,
        userPaymentMethodsRequest
      )
      .pipe(
        map((userPaymentMethodsResponse: UserPaymentMethodsResponse) => {
          return userPaymentMethodsResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables PIQ Transaction Status
  onGetPIQTransactionStatus(
    piqTransactionStatusRequest: PiqTransactionStatusRequest
  ): Observable<PiqTransactionStatusResponse> {
    let merchantId: string = piqTransactionStatusRequest.merchantId;

    let userId: string = piqTransactionStatusRequest.userId;

    let transactionId: string = piqTransactionStatusRequest.transactionId;

    delete piqTransactionStatusRequest.merchantId;
    delete piqTransactionStatusRequest.userId;
    delete piqTransactionStatusRequest.transactionId;

    return this.paymentInteractor
      .get<PiqTransactionStatusRequest, PiqTransactionStatusResponse>(
        `/api/user/transaction/${merchantId}/${userId}/${transactionId}/status`,
        piqTransactionStatusRequest
      )
      .pipe(
        map((piqTransactionStatusResponse: PiqTransactionStatusResponse) => {
          return piqTransactionStatusResponse;
        }),
        catchError(() => {
          return throwError(
            "something went wrong in fetch piq transaction status"
          );
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Transaction History
  onCancelTransaction(
    cancelTransactionRequest: CancelTransactionRequest
  ): Observable<CancelTransactionResponse> {
    let merchantId: string = cancelTransactionRequest.merchantId;

    let userId: string = cancelTransactionRequest.userId;

    let transactionId: string = cancelTransactionRequest.transactionId;

    delete cancelTransactionRequest.merchantId;
    delete cancelTransactionRequest.userId;
    delete cancelTransactionRequest.transactionId;

    return this.paymentInteractor
      .delete<CancelTransactionRequest, CancelTransactionResponse>(
        `/api/user/transaction/${merchantId}/${userId}/${transactionId}`,
        cancelTransactionRequest
      )
      .pipe(
        map((cancelTransactionResponse: CancelTransactionResponse) => {
          return cancelTransactionResponse;
        }),
        catchError(() => {
          return throwError(`Unable to cancel transaction`);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Make Payment
  onMakePayment(
    processPaymentRequest: ProcessPaymentRequest
  ): Observable<ProcessPaymentResponse> {
    let provider: string = processPaymentRequest.provider;

    let paymentMethod: string = processPaymentRequest.paymentMethod;

    let methodType: string = processPaymentRequest.methodType;

    delete processPaymentRequest.provider;
    delete processPaymentRequest.paymentMethod;
    delete processPaymentRequest.methodType;

    return this.paymentInteractor
      .post<ProcessPaymentRequest, ProcessPaymentResponse>(
        `/api/${provider}/${paymentMethod}/${methodType}`,
        processPaymentRequest
      )
      .pipe(
        map((processPaymentResponse: ProcessPaymentResponse) => {
          return processPaymentResponse;
        }),
        catchError(() => {
          return throwError(
            `payment Reqiest failed for provider-{{paymentRequestObj.providerType}} & method-{{ paymentRequestObject.paymentMethod}}`
          );
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Delete Payment Method
  onDeletePaymentMethod(
    deletePaymentMethodRequest: DeletePaymentMethodRequest
  ): Observable<DeletePaymentMethodResponse> {
    let merchantId: string = deletePaymentMethodRequest.merchantId;

    let userId: string = deletePaymentMethodRequest.userId;

    let accountId: string = deletePaymentMethodRequest.accountId;

    delete deletePaymentMethodRequest.merchantId;
    delete deletePaymentMethodRequest.userId;
    delete deletePaymentMethodRequest.accountId;

    return this.paymentInteractor
      .delete<DeletePaymentMethodRequest, DeletePaymentMethodResponse>(
        `/api/user/account/${merchantId}/${userId}/${accountId}`,
        deletePaymentMethodRequest
      )
      .pipe(
        map((deletePaymentMethodResponse: DeletePaymentMethodResponse) => {
          return deletePaymentMethodResponse;
        }),
        catchError(() => {
          return throwError(`Unable to delete the payment Method`);
        })
      );
  }
}
