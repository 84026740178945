import { Injectable } from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Firebase Auth
import { AngularFireAuth } from "@angular/fire/auth";

// Firestore
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class FireLoggerStoreService<T> {
  // Strings
  databasePath: string = "firelogger";

  angularFirestoreCollection: AngularFirestoreCollection<unknown>;

  constructor(
    private angularFirestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth
  ) {
    this.angularFirestoreCollection = this.angularFirestore.collection(
      this.databasePath
    );
  }

  onSetPath(path: string): void {
    this.databasePath = `${path}`;

    this.angularFirestoreCollection = this.angularFirestore.collection(
      this.databasePath
    );
  }

  addFirelogger(log: T, groupKey: string, key: string): void {
    const { email, token } = environment.features.firebaseOption;

    this.angularFireAuth
      .signInWithEmailAndPassword(email, token)
      .then((result) => {
        if (result.user) {
          this.angularFirestoreCollection = this.angularFirestore.collection(
            this.databasePath
          );

          this.angularFirestoreCollection
           // .doc(log[key])
            .doc(this.databasePath)
            .set({
              log: log,
            })
            .then();
        }
      })
      .catch((err) => {
        console.log("Firebase: auth/wrong credentials", err);
      });
  }

  addFireloggerOption<T>(log: T, groupKey: string = "firelogger"): void {
    this.databasePath = groupKey;

    const { email, token } = environment.features.firebaseOption;

    this.angularFireAuth
      .signInWithEmailAndPassword(email, token)
      .then((result) => {
        if (result.user) {
          this.angularFirestoreCollection =
            this.angularFirestore.collection(this.databasePath);

          this.angularFirestoreCollection.add(log).then();
        }
      })
      .catch((err) => {
        console.log("Firebase: auth/wrong credentials", err);
      });
  }
}
