import { Pipe, PipeTransform } from "@angular/core";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { UserPIQAccount } from "src/app/modules/transactions/models/user-piq-account/user-piq-account-response.model";
import { PaymentOptions } from "src/app/modules/shared/models/payment-options.model";

@Pipe({
  name: "paymentMethodName",
})
export class PaymentMethodNamePipe implements PipeTransform {
  transform(
    paymentObject: PaymentOptions | UserPaymentMethod | UserPIQAccount,
    isDepositInput?
  ): string {
    let providerName: string = "";

    const serviceName: string = paymentObject.service;

    if (paymentObject.type) {
      providerName = paymentObject.type;
    } else if (paymentObject.providerType) {
      providerName = paymentObject.providerType;
    } else {
      providerName = "";
    }

    if (serviceName && !isDepositInput) {
      providerName = `${providerName}-${serviceName}`;
    } else if (serviceName && isDepositInput) {
      providerName = `${providerName}_${serviceName}`;
    }

    if (isDepositInput) {
      providerName = `${providerName}_PIQ`.toUpperCase();
    } else {
      providerName = providerName.toLowerCase();
    }

    return providerName;
  }
}
