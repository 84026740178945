import { HostListener, Component, OnDestroy, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  NavigationStart,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  ActivatedRoute,
  NavigationEnd,
  RouterEvent,
  UrlSegment,
  UrlTree,
  Router,
  Params,
} from "@angular/router";

// Actions
import { countryBlockRequested } from "src/app/modules/country-block/store/actions/country-block.actions";
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";
import { userRequested } from "src/app/modules/user/store/actions/user.actions";
import {
  lastPlayedGamesRequested,
  gamesRequested,
  lobbyRequested,
} from "src/app/modules/game-groups/store/actions/games.actions";
import {
  logoutRequested,
  loggedInStatus,
} from "src/app/modules/auth/store/actions/auth.actions";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import { firestoreMarketsList } from "src/app/modules/firelogger/configurations/firebase.configurations";
import {
  cmsToLocalLanguageConfigurations,
  localToCmsLanguageConfigurations,
  logRocketCountriesConfigurations,
  countryLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { WorldCountryCode } from "src/app/models/configurations/enums/localization/world-countries.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as LogRocket from "logrocket";
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { PromoConfigurationsDetailsResponse } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-details-response.model";
import { PromoConfigurationsDetailsRequest } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-details-request.model";
import { PromoConfigurations } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations.model";
import {
  PromoConfigurationsSlabGroupInfo,
  PromoConfigurationsSlabItem,
} from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-slab-award.model";
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";
import { GeoLocationResponse } from "src/app/modules/multi-languages/models/geo-location-response.model";
import { GtmRouterPayload } from "src/app/modules/shared/models/gtm/gtm-router-payload.model";
import { NavigationRoute } from "src/app/modules/shared/models/navigation/navigation.model";
import { GamePlay } from "src/app/modules/game-groups/models/game-play/game-play.model";
import { FirestoreGame } from "src/app/modules/firelogger/models/pregmatic-game.model";
import { Firelogger } from "src/app/modules/firelogger/models/firelogger.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { QueryParams } from "src/app/modules/shared/models/parameters.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectCountryBlock } from "src/app/modules/country-block/store/selectors/country-block.selectors";
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
  selectAuthLoginStatus,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { FireLoggerStoreService } from "src/app/modules/firelogger/services/firelogger-store.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

// Utilities
import { fireLoggerTrackGamesLog } from "src/app/modules/firelogger/utilities/track-games-log.utilities";
import { fireLoggerTrackGames } from "src/app/modules/firelogger/utilities/track-games.utilities";
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";
import {
  supportedLanguagesList,
  supportedMarketsList,
} from "src/app/modules/multi-languages/utilities/languages.utilities";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // Strings
  ipBasedLanguageCode: string = "";
  title: string = "casinofriday";
  sessionTimeText: string = "";
  promoStartDate: string = "";
  languageCode: string = "";
  promoEndDate: string = "";
  activePath: string = "";
  activePage: string = "";
  startTime: string = "";
  endTime: string = "";
  theme: string = "";

  // Booleans
  isMaintenanceOrCountryBlockEnabled: boolean = false;
  isCashbackDepositOfferPopup: boolean = false;
  isEnableTermsAndConditions: boolean = false;
  isPromotionalVideoEnabled: boolean = false;
  isCashbackStartedPopup: boolean = false;
  isGameWindowMinimized: boolean = false;
  isSessionExpiryPopup: boolean = false;
  isQuickDepositOpen: boolean = false;
  isOpenRewardPopup: boolean = false;
  isShowBanner: boolean = false;
  is404Enabled: boolean = false;
  isLoggedIn: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";
  currencyCode: WorldCurrencyCode;

  // Arrays
  cashbackUserSlabInfoList: PromoConfigurationsSlabItem[] = [];

  // Objects
  selectedCashbackUserSlab: PromoConfigurationsSlabItem;
  countryBlockResponse: CountryBlockResponse;
  gameLaunched: GamePregmatic | GamePlay;
  promoConfigInfo: PromoConfigurations;
  userProfileDetails: UserData;

  // To Discover
  cashBackReward: any;

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  cashbackPromoConfigurationsSubscription: Subscription;
  countryBlockInitialSubscription: Subscription;
  getGeoLocationSubscription: Subscription;
  countryBlockSubscription: Subscription;
  userDetailsSubscription: Subscription;
  locationSubscription: Subscription;
  allow404Subscription: Subscription;
  gamesSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private fireLoggerStringService: FireLoggerStoreService<Firelogger>,
    private fireLoggerService: FireLoggerStoreService<FirestoreGame>,
    private cashBackPromoService: CashbackPromoService,
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private customBannerService: CustomBannerService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private gameGroupsService: GameGroupsService,
    private gamePlayService: GamePlayService,
    private liveChatService: LiveChatService,
    private emitterService: EmitterService,
    private cashierService: CashierService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private socketService: SocketService,
    private gtmService: GtmService,
    private store: Store<AppState>,
    private kycService: KYCService,
    private router: Router,
    private meta: Meta
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {

    this.translationService.onAddLanguages(supportedLanguagesList());

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.onGetCountryBlocked();

    this.onCloseGameIFrameRedirection();

    this.getWindowType();

    this.onSetLocaleTheme();

    this.subscriptions = [
      this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
        if (queryParams && queryParams.session) {
          if (queryParams.session == "expired") {
            this.isSessionExpiryPopup = true;
          }
        }

        if (queryParams && queryParams.txRefId) {
          this.customBannerService.onSetIsProfileRefreshRequired(true);

          this.emitterService.onBroadcastSuccessfulDeposit(true);

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
          });
        }
      }),
      this.gamePlayService.gameLaunchedSubject$.subscribe(
        (gameLaunched: GamePregmatic | GamePlay) => {
          this.gameLaunched =
            Object.keys(gameLaunched).length > 0 ? gameLaunched : undefined;
        }
      ),
      this.gamePlayService.isGameWindowMinimizedSubject$.subscribe(
        (isGameWindowMinimized: boolean) => {
          this.isGameWindowMinimized = isGameWindowMinimized;
        }
      ),
      this.store
        .select(selectAuthLoginStatus)
        .subscribe(
          (result: {
            isLoading: boolean;
            isLoaded: boolean;
            loginStatus: LoginStatus;
          }) => {
            if (result) {
              if (!result.isLoading && result.isLoaded && result.loginStatus) {
                this.socketService.onConnectToSockets(
                  result.loginStatus.pragmaticUrl,
                  result.loginStatus.pragmaticSessionId,
                  true
                );
              }
            }
          }
        ),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          this.onSetSessionTimeText();

          if (environment.production) {
            this.onGetUserProfileData();
          }

          if (this.isLoggedIn) {
            this.store.dispatch(lastPlayedGamesRequested());
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = !isLoggedOut;

            if (!_.isEmpty(this.gamePlayService.getCurrentGamePregmatic())) {
              this.gamePlayService.onClearGameWindowData();
            }

            this.onClearServiceDataOnLogOut();

            this.liveChatService.onUpdateSessionData();
          }
        }),
      this.sessionService.sessionTimeUpdatedSubject$.subscribe(() => {
        this.onSetSessionTimeText();
      }),
      this.commonService.quickDepositToggle$.subscribe(
        (isQuickDepositOpen: boolean) => {
          this.isQuickDepositOpen = isQuickDepositOpen;
        }
      ),
      this.emitterService.isOpenRewardTermsConditionsSubject$.subscribe(
        (isOpenRewardPopup: boolean) => {
          this.isOpenRewardPopup = isOpenRewardPopup;
        }
      ),
      this.commonService.activeAccountViewSubject$.subscribe(() => {
        if (this.isQuickDepositOpen && this.windowType === "mobile") {
          this.onCloseQuickDepositBackdrop();
        }
      }),
      this.userDetailsService.currencyCodeBehaviourSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }),
      this.cashBackPromoService.cashbackOfferAmountSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((cashBackAmount: string) => {
          if (cashBackAmount) {
            this.cashBackReward = cashBackAmount;

            this.isCashbackDepositOfferPopup = true;

            this.utilityService.onAddClassToAppBody("overflow-hidden");
          }
        }),
      this.cashBackPromoService.cashbackStartedPromoPopupSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (
            promoConfigurationsDetailsRequest: PromoConfigurationsDetailsRequest
          ) => {
            if (promoConfigurationsDetailsRequest) {
              this.onGetCashbackPromoConfigurationsDetails(
                promoConfigurationsDetailsRequest
              );
            }
          }
        ),
      this.emitterService.isPromotionalVideoEnabledSubject$.subscribe(
        (isPromotionalVideoEnabled: boolean) => {
          if (isPromotionalVideoEnabled) {
            this.utilityService.onAddClassToAppBody("overflow-hidden");
          }

          this.isPromotionalVideoEnabled = isPromotionalVideoEnabled;
        }
      ),
      this.router.events.subscribe((event: RouterEvent) => {
        /*
          We clear game play data here when user navigate out from gamePlay window
          to some other route path with click on minimize options
        */
        if (event instanceof NavigationStart) {
          if (
            event.url &&
            !event.url.includes(
              `/${this.translationService.get("url.game")}`
            ) &&
            !this.gamePlayService.isGameMinimized
          ) {
            if (!_.isEmpty(this.gamePlayService.getCurrentGamePregmatic())) {
              this.gamePlayService.onBroadCastGameLaunch({});

              this.gamePlayService.onSetGameCalledFrom(null);
            }
          }

          if (
            event.url &&
            (event.url.includes("/maintenance") ||
              event.url.includes("/countryblock") ||
              (window.top !== window.self &&
                (event.url.includes(
                  `/${this.translationService.get("url.terms_conditions")}`
                ) ||
                  event.url.includes(
                    `/${this.translationService.get("url.privacy-policy")}`
                  ) ||
                  event.url.includes(
                    `/${this.translationService.get("url.cookies-policy")}`
                  ))))
          ) {
            this.isMaintenanceOrCountryBlockEnabled = true;
          } else {
            this.isMaintenanceOrCountryBlockEnabled = false;
          }

          if (
            event.url == `/${this.languageCode}/unsubscribe` &&
            this.isLoggedIn
          ) {
            this.router.navigate([`${this.languageCode}/casino`]);
          }
        }

        /*
          Re-initialize all the required properties
          after every navigation...
        */
        if (event instanceof NavigationEnd) {
          const currentUrlPath: string =
            this.utilityService.getDecodedCurrentPath();

          const urlTree: UrlTree = this.router.parseUrl(event.url);

          const urlSegmentGroup: UrlSegmentGroup =
            urlTree.root.children[PRIMARY_OUTLET];

          const urlSegment: UrlSegment[] = urlSegmentGroup
            ? urlSegmentGroup.segments
            : [];

          if (urlSegment && urlSegment[1] && urlSegment[1].path) {
            this.activePage = this.multiLanguageService.getActiveLobby(
              urlSegment[1].path
            );
          } else {
            this.activePage = "";
          }

          if (
            currentUrlPath === "" ||
            currentUrlPath === "/" ||
            currentUrlPath.endsWith(this.languageCode)
          ) {
            this.isShowBanner = true;
          } else {
            this.isShowBanner = false;
          }

          this.utilityService.updateActiveLeftMenu();

          this.onRouteChange(event);
        }
      }),
    ];

    if (environment.features.enableFirebase) {
      if (firestoreMarketsList.includes(this.languageCode)) {
        if (localStorage.getItem("geoLocation")) {
          const geoLocation: GeoLocationResponse = JSON.parse(
            localStorage.getItem("geoLocation")
          );

          this.onLoadGamesFirebase(geoLocation);
        } else {
          this.getGeoLocationSubscription = this.multiLanguageService
            .onGetGeoLocation()
            .subscribe((geoLocation: GeoLocationResponse) => {
              localStorage.setItem("geoLocation", JSON.stringify(geoLocation));

              this.onLoadGamesFirebase(geoLocation);
            });
        }
      }
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.onIsQuickDepositOpened();

      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.isEnableTermsAndConditions = environment.features.enableTermsAndConditions;

    this.allow404Subscription =
      this.commonService.current404PageSubject$.subscribe(
        (current404Page: string) => {
          if (current404Page == "404" || current404Page == "maintenance-mode") {
            this.is404Enabled = true;
          }
        }
      );

    this.locationSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;

        if (this.languageCode == "ja-jp") {
          this.onAddTagsForJapan();
        }

        if (this.languageCode == "de-de") {
          this.onAddNoIndexTagForGerman();
        }

        this.onSetLocaleTheme();
      });

    this.countryBlockInitialSubscription = this.store
      .select(selectCountryBlock)
      .subscribe((countryBlockResponse: CountryBlockResponse) => {
        if (countryBlockResponse) {
          this.countryBlockResponse = countryBlockResponse;

          this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

          this.store.dispatch(
            loggedInStatus({
              isLoggedIn: this.isLoggedIn,
            })
          );

          this.store.dispatch(gamesRequested());

          this.store.dispatch(lobbyRequested());
        } else {
          this.store.dispatch(countryBlockRequested());
        }
      });
  }

  onFavicon(): void {
    let appleTouchIcon: HTMLLinkElement =
      document.querySelector("#appleTouchIcon");

    let favicon32Icon: HTMLLinkElement =
      document.querySelector("#favicon32Icon");

    let favicon16Icon: HTMLLinkElement =
      document.querySelector("#favicon16Icon");

    let faviconManifest: HTMLLinkElement =
      document.querySelector("#faviconManifest");

    if (this.theme == "brazilTheme") {
      appleTouchIcon.href = `assets/favicon/brazil/brazil-apple-touch-icon.png`;

      favicon32Icon.href = `assets/favicon/brazil/brazil-favicon-32x32.png`;

      favicon16Icon.href = `assets/favicon/brazil/brazil-favicon-16x16.png`;

      faviconManifest.href = `assets/favicon/brazil/site.webmanifest`;
    } else {
      appleTouchIcon.href = `assets/favicon/apple-touch-icon.png`;

      favicon32Icon.href = `assets/favicon/favicon-32x32.png`;

      favicon16Icon.href = `assets/favicon/favicon-16x16.png`;

      faviconManifest.href = `assets/favicon/site.webmanifest`;
    }
  }

  onLoadGamesFirebase(geoLocation: GeoLocationResponse): void {
    this.gamesSubscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          if (environment.features.firebaseOption.use == "string") {
            fireLoggerTrackGamesLog(
              gamesList,
              this.fireLoggerStringService,
              geoLocation.ip,
              this.languageCode,
              geoLocation.country_code2,
              geoLocation.country_name
            );
          }

          if (environment.features.firebaseOption.use == "object") {
            fireLoggerTrackGames(gamesList, this.fireLoggerService);
          }
        }
      });
  }

  onCloseGameIFrameRedirection(): void {
    const windowParentOrigin: string = window.parent.location.origin;

    const document: Document = window.parent.document;

    const iFrameId: string = document.getElementById("netentgame")
      ? "netentgame"
      : "gamePlayIframe";

    const element: HTMLIFrameElement = document.getElementById(
      iFrameId
    ) as HTMLIFrameElement;

    if (element) {
      const iFrame: Document =
        element.contentDocument || element.contentWindow.document;

      const iFrameOrigin: string = iFrame.location.origin;

      if (iFrameOrigin === windowParentOrigin) {
        const htmlElement: HTMLElement =
          window.parent.document.getElementById("closeGame");

        $(htmlElement).trigger("click");
      }
    }
  }

  onGetCashbackPromoConfigurationsDetails(
    promoConfigurationsDetailsRequest: PromoConfigurationsDetailsRequest
  ): void {
    this.cashbackPromoConfigurationsSubscription = this.cashBackPromoService
      .onGetPromoConfigurationsDetails(promoConfigurationsDetailsRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (
          promoConfigurationsDetailsResponse: PromoConfigurationsDetailsResponse
        ) => {
          if (
            promoConfigurationsDetailsResponse &&
            promoConfigurationsDetailsResponse.status ===
              StatusResponse.SUCCESS &&
            promoConfigurationsDetailsResponse.promoConfig
          ) {
            this.promoConfigInfo =
              promoConfigurationsDetailsResponse.promoConfig;

            if (
              this.promoConfigInfo.campaignStartDate &&
              this.promoConfigInfo.campaignEndDate
            ) {
              this.promoStartDate = new Date(
                this.promoConfigInfo.campaignStartDate
              ).toDateString();

              this.promoEndDate = new Date(
                this.promoConfigInfo.campaignEndDate
              ).toDateString();

              this.startTime = new Date(
                this.promoConfigInfo.campaignStartDate
              ).toLocaleTimeString();

              this.endTime = new Date(
                this.promoConfigInfo.campaignEndDate
              ).toLocaleTimeString();
            }

            const slabGroupInfo: PromoConfigurationsSlabGroupInfo =
              this.promoConfigInfo.slabGroupInfo[
                Object.keys(this.promoConfigInfo.slabGroupInfo)[0]
              ];

            this.cashbackUserSlabInfoList = slabGroupInfo.slabInfo.filter(
              (promoConfigurationsSlabItem: PromoConfigurationsSlabItem) =>
                promoConfigurationsSlabItem.slabCurrency == this.currencyCode
            );

            this.selectedCashbackUserSlab = this.cashbackUserSlabInfoList[0];

            this.isCashbackStartedPopup = true;

            this.utilityService.onAddClassToAppBody("overflow-hidden");
          }
        }
      );
  }

  onSetLocaleTheme(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    const latamTheme: NodeListOf<Element> =
      document.querySelectorAll(".latamTheme");

    const brazilTheme: NodeListOf<Element> =
      document.querySelectorAll(".brazilTheme");

    if (this.theme) {
      document.querySelector("body").classList.remove("latamTheme");
      document.querySelector("body").classList.remove("brazilTheme");
      
      document.querySelector("body").classList.add(this.theme);
    } else if (latamTheme && latamTheme.length > 0) {
      document.querySelector("body").classList.remove("latamTheme");
    } else if (brazilTheme && brazilTheme.length > 0) {
      document.querySelector("body").classList.remove("brazilTheme");
    }

    this.onFavicon();
  }

  onGetUserProfileData(): void {
    this.userDetailsSubscription = this.store
      .select(selectAuthUserDataLoaded)
      .subscribe(({ userData, isLoaded }) => {
        if (isLoaded) {
          this.userProfileDetails = userData;

          this.onLogRocketUserData();
        } else {
          if (this.isLoggedIn) {
            this.store.dispatch(userRequested());
          }
        }
      });
  }

  /*
    Initialize logRocket only logged in for users registered in specified countries
    identify logRocket user name and email
  */
  onLogRocketUserData(): void {
    if (
      this.isLoggedIn &&
      this.userProfileDetails &&
      logRocketCountriesConfigurations.includes(this.userProfileDetails.country)
    ) {
      LogRocket.init("azfhey/casinofriday");

      LogRocket.identify(this.userProfileDetails.playerID, {
        name: this.userProfileDetails.firstName,
        email: this.userProfileDetails.email,
      });
    }
  }

  onCloseQuickDepositBackdrop(): void {
    this.commonService.broadcastQuickDepositToggle(false);
  }

  onGetCountryBlocked(): void {
    this.countryBlockSubscription = this.store
      .select(selectCountryBlock)
      .subscribe((countryBlockResponse: CountryBlockResponse) => {
        if (countryBlockResponse && countryBlockResponse.countryCode) {
          this.commonService.broadcastUpdateCountryCode(
            countryBlockResponse.countryCode
          );

          if (!this.isLoggedIn) {
            this.commonService.setCurrencyByLocality();
          }

          /*
            Here 'IN' language code is already occupied by indonesia country
            in our CMS system..so to avoid conflict we are using ei for indain english
            As configured in CMS.

            So we are overriding India(IN code) to ei,To manage language translations.
            & we also overriding Canada(CA code) to cd,To manage language translations.
          */
          if (
            countryBlockResponse.countryCode === WorldCountryCode.NL &&
            !this.isLoggedIn
          ) {
            this.ipBasedLanguageCode = environment.defaultLanguage;
          } else {
            if (countryBlockResponse.countryCode == WorldCountryCode.ES) {
              this.ipBasedLanguageCode = environment.defaultLanguage;
            } else {
              this.ipBasedLanguageCode =
                cmsToLocalLanguageConfigurations[
                  countryLanguageConfigurations[
                    countryBlockResponse.countryCode
                  ]
                ];
            }
          }

          this.onSetLanguage(this.ipBasedLanguageCode);
        }
      });
  }

  onLogout(): void {
    this.store.dispatch(logoutRequested({}));
  }

  onSetLanguage(ipBasedLanguage: string): void {
    const regexFirst: RegExp = /^[a-z]{2}-[a-z]{2}$/;

    const regexSecond: RegExp = /^[a-z]{2}-[a-z]{3}$/;

    const locationPath: string = this.utilityService.getDecodedCurrentPath();

    const acceptedLanguages: string[] = supportedMarketsList();

    const localLanguageCode: string = localStorage.getItem(
      localStorageKeys.languageCode
    );

    if (locationPath && locationPath.length > 1) {
      const languageCodeFromURL: string = this.utilityService
        .getDecodedCurrentPath()
        .split("/")[1];

      if (
        languageCodeFromURL &&
        (regexFirst.test(languageCodeFromURL) ||
          regexSecond.test(languageCodeFromURL))
      ) {
        if (
          languageCodeFromURL &&
          _.contains(acceptedLanguages, languageCodeFromURL)
        ) {
          if (this.isLoggedIn && localLanguageCode) {
            this.onUserProfileLanguageNavigation(
              regexFirst,
              languageCodeFromURL
            );
          } else {
            this.onLanguageCodeUpdate(languageCodeFromURL);

            localStorage.setItem(
              localStorageKeys.languageCode,
              languageCodeFromURL
            );
          }
        } else {
          let pathWithoutlanguageCode = "";

          if (regexFirst.test(languageCodeFromURL)) {
            pathWithoutlanguageCode = this.utilityService
              .getDecodedCurrentPath()
              .substring(6);
          } else {
            pathWithoutlanguageCode = this.utilityService
              .getDecodedCurrentPath()
              .substring(7);
          }

          if (
            localLanguageCode &&
            _.contains(acceptedLanguages, localLanguageCode)
          ) {
            this.onNavigateAfterSetLanguage(
              `${localLanguageCode}/${pathWithoutlanguageCode}`
            );

            this.languageCode = localLanguageCode;
          } else if (
            ipBasedLanguage &&
            _.contains(acceptedLanguages, ipBasedLanguage)
          ) {
            this.onLanguageCodeUpdate(ipBasedLanguage);

            this.onNavigateAfterSetLanguage(
              `${ipBasedLanguage}/${pathWithoutlanguageCode}`
            );
          } else {
            this.onLanguageCodeUpdate(environment.defaultLanguage);

            this.onNavigateAfterSetLanguage(
              `${environment.defaultLanguage}/${pathWithoutlanguageCode}`
            );
          }
        }
      } else {
        if (
          localLanguageCode &&
          _.contains(acceptedLanguages, localLanguageCode)
        ) {
          this.onNavigateAfterSetLanguage(
            `${localLanguageCode}/${locationPath}`
          );

          this.languageCode = localLanguageCode;
        } else if (
          ipBasedLanguage &&
          _.contains(acceptedLanguages, ipBasedLanguage)
        ) {
          this.onLanguageCodeUpdate(ipBasedLanguage);

          this.onNavigateAfterSetLanguage(`${ipBasedLanguage}/${locationPath}`);
        } else {
          this.onLanguageCodeUpdate(environment.defaultLanguage);

          this.onNavigateAfterSetLanguage(
            `${environment.defaultLanguage}/${locationPath}`
          );
        }
      }
    } else {
      if (
        localLanguageCode &&
        _.contains(acceptedLanguages, localLanguageCode)
      ) {
        this.onNavigateAfterSetLanguage(localLanguageCode);

        this.languageCode = localLanguageCode;
      } else if (
        ipBasedLanguage &&
        _.contains(acceptedLanguages, ipBasedLanguage)
      ) {
        this.onLanguageCodeUpdate(ipBasedLanguage);

        this.onNavigateAfterSetLanguage(ipBasedLanguage);
      } else {
        this.onLanguageCodeUpdate(environment.defaultLanguage);

        this.onNavigateAfterSetLanguage(environment.defaultLanguage);
      }
    }

    const queryParams: QueryParams = getQueryParams();

    if (!_.isEmpty(queryParams)) {
      if (
        queryParams.redirect &&
        (queryParams.redirect.includes("deposit") ||
          queryParams.redirect.includes("withdraw"))
      ) {
        const activeTab: ActiveTab = {
          tabName: queryParams.redirect,
          showBackButton: true,
        };

        this.utilityService.openAccountComponent(activeTab);
      }

      this.onHandleQueryParams(queryParams);
    }
  }

  onLanguageCodeUpdate(languageCode: string): void {
    this.store.dispatch(
      languageCodeLoaded({
        language: localToCmsLanguageConfigurations[languageCode],
        languageCode,
      })
    );

    this.translationService.onUseLanguage(
      localToCmsLanguageConfigurations[languageCode]
    );
  }

  onNavigateAfterSetLanguage(path: string): void {
    if (window.location.search.length > 0) {
      const params: QueryParams = getQueryParams();

      this.router.navigate([path], { queryParams: params });
    } else {
      this.router.navigate([path]);
    }
  }

  onUserProfileLanguageNavigation(
    regFirst: RegExp,
    languageCodeFromURL: string
  ): void {
    let pathWithoutLanguageCode = "";

    if (regFirst.test(languageCodeFromURL)) {
      pathWithoutLanguageCode = this.utilityService
        .getDecodedCurrentPath()
        .substring(6);
    } else {
      pathWithoutLanguageCode = this.utilityService
        .getDecodedCurrentPath()
        .substring(7);
    }

    this.onNavigateAfterSetLanguage(
      `${this.languageCode}/${pathWithoutLanguageCode}`
    );
  }

  /*
    This check is needed because we have two set's of UI on mobile(portrait) & device(landscape)
    which is hiding quick deposit popup when open & rotated from portrait to landscape & landscape to portrait
    very edge case but handled it.
  */
  onIsQuickDepositOpened(): void {
    const htmlElement: HTMLElement =
      document.getElementById("qd-3ddeposit-popup");
    if (
      htmlElement &&
      htmlElement.style.getPropertyValue("display") === "block"
    ) {
      this.isQuickDepositOpen = true;
    }
  }

  onSetSessionTimeText(): void {
    if (sessionStorage.getItem("session")) {
      const minutes: number = JSON.parse(
        sessionStorage.getItem("session")
      ).sessionLimit;

      this.sessionTimeText = this.utilityService.getSessionTimeText(minutes);
    }
  }

  onSetSessionExpiryLogin(): void {
    this.onSetSessionExpiryBackdrop();

    const activeTab: ActiveTab = {
      tabName: "login",
      showBackButton: true,
    };

    this.commonService.onBroadcastActiveAcountView(activeTab);
  }

  onSetSessionExpiryBackdrop(): void {
    this.isSessionExpiryPopup = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onSetRewardTermsAndConditionsBackdrop(): void {
    this.utilityService.toggleRewardTCPopUp(false);
  }

  onRouteChange(event: GtmRouterPayload): void {
    this.gtmService.onRouteChangeGtmEvent(event);
  }

  /*
    DeepLinks Navigation URL:
    1.  Deposit Deeplink URL
          link: {{SiteURL}}/en-row/casino?tab=deposit
    2.  Deposit Deeplink with active bonus URL:
          link: {{SiteURL}}/en-row/casino?tab=deposit&bonusCode=test3reload
    3.  Rewards Deeplink URL:
          link: {{SiteURL}}/en-row/casino?tab=rewards
    4.  KYC Verification Deeplink URL:
          link: {{SiteURL}}/en-row/casino?tab=confirmidentity
    5.  Live Chat Deeplink URL:
          link: {{SiteURL}}/en-row/casino?tab=live_chat
    6.  Preferences Deeplink URL:
          link: {{SiteURL}}/en-row/casino?tab=preferences
    7.  login Deeplink URL:
          link: {{SiteURL}}/en-row/casino?login=true
    8.  Registration Deeplink URL:
          link: {{SiteURL}}/en-row/casino?register=true
    9.  Game window Deeplink URL:
          link: {{SiteURL}}/en-eu/game/7-piggies?login=true
    10. Lobby Deeplink URL:
          link: {{SiteURL}}/en-row/casino?lobby=slots
    11. Game Group Deeplink URL:
          link: {{SiteURL}}/en-row/casino?lobby=slots&group=new-games
    12. Promotions Deeplink URL:
          a) For all login promotions
              link: {{SiteURL}}/en-row/promotions?login=true
          b) For all logout Promotions URL:
              link: {{SiteURL}}/en-row/promotions
    13. Promotion Content Deeplink URL:
          a) Promotion Content for loggedIn
              link: {{SiteURL}}/en-row/promotions/search-anything-anywhere?login=true
          b) Promotion Content for logged out ()
              link: {{SiteURL}}/en-row/promotions/search-anything-anywhere
  */
  onHandleQueryParams(queryParams: QueryParams): void {
    if (!this.isLoggedIn && queryParams.crid) {
      this.registrationService.onOpenRegistration();
    } else if (!this.isLoggedIn && queryParams.register === "true") {
      this.registrationService.onOpenRegistration();
    } else if (!this.isLoggedIn && queryParams.login === "true") {
      const path: string = this.utilityService.getDecodedCurrentPath();

      if (
        path &&
        path.includes(this.translationService.get("url.promotions")) &&
        path.split("/").length > 3
      ) {
        const navigationData: NavigationRoute = {
          type: "url",
          data: {
            navigateTo: "promotions",
            path,
          },
        };

        this.commonService.onSetNavigateAfterLogin(navigationData);

        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.promotions"
          )}`,
        ]);
      }

      if (path && path.includes(this.translationService.get("url.game"))) {
        const navigationData: NavigationRoute = {
          type: "url",
          data: {
            navigateTo: "gameWindow",
            path: path.split("/")[3],
          },
        };

        this.commonService.onSetNavigateAfterLogin(navigationData);

        this.router.navigate([`${this.languageCode}/casino`]);
      }

      const activeTab: ActiveTab = {
        tabName: "login",
        showBackButton: true,
      };

      this.commonService.onBroadcastActiveAcountView(activeTab);
    } else if (queryParams.tab === "live_chat") {
      this.liveChatService.onInitializeChat();
    } else if (queryParams.tab === "confirmidentity" && this.isLoggedIn) {
      this.kycService.onGetUserKycLevelDetails().subscribe((data) => {
        if (!data.enableKyc) {
          const activeTab: ActiveTab = {
            tabName: "menuOptions",
            showBackButton: true,
          };

          this.commonService.onBroadcastActiveAcountView(activeTab);
        } else {
          const activeTab: ActiveTab = {
            tabName: queryParams.tab,
            showBackButton: true,
          };

          this.utilityService.openAccountComponent(activeTab);
        }
      });
    } else if (this.isLoggedIn && queryParams.hasOwnProperty("tab")) {
      if (
        queryParams.tab === "deposit" &&
        queryParams.hasOwnProperty("bonusCode")
      ) {
        this.cashierService.onSetActiveDepositBonus({
          bonusCode: queryParams.bonusCode,
        });
      }

      const activeTab: ActiveTab = {
        tabName: queryParams.tab,
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);
    } else if (!this.isLoggedIn && queryParams.hasOwnProperty("tab")) {
      const activeTab: ActiveTab = {
        tabName: "login",
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);

      const navigationData: NavigationRoute = {
        type: "view",
        path: queryParams.tab,
      };

      if (
        queryParams.tab === "deposit" &&
        queryParams.hasOwnProperty("bonusCode")
      ) {
        navigationData.data = {
          bonusCode: queryParams.bonusCode,
        };
      }

      this.commonService.onSetNavigateAfterLogin(navigationData);
    } else if (queryParams && queryParams.hasOwnProperty("lobby")) {
      const navigationData: NavigationRoute = {
        type: "url",
        data: {
          navigateTo: "lobby",
        },
      };

      navigationData.data.lobby = queryParams.lobby;

      if (queryParams.hasOwnProperty("group")) {
        navigationData.data.group = queryParams.group;
      }

      if (!this.isLoggedIn) {
        const activeTab: ActiveTab = {
          tabName: "login",
          showBackButton: true,
        };

        this.utilityService.openAccountComponent(activeTab);

        this.commonService.onSetNavigateAfterLogin(navigationData);
      } else {
        this.commonService.onBroadcastNavigationData(navigationData.data);

        if (navigationData.data.group) {
          this.router.navigate([
            `${this.languageCode}/casino/${queryParams.group}`,
          ]);
        }
      }
    }

    setTimeout(() => {
      if (
        this.utilityService.getDecodedCurrentPath().split("/")[2] !==
        "reset-password"
      ) {
        this.utilityService.removeUrlQueryParams();
      }
    }, 3000);
  }

  onAddTagsForJapan(): void {
    document.querySelector("html").setAttribute("translate", "no");

    this.meta.addTag({ name: "google", content: "notranslate" });

    this.meta.addTag({
      "http-Equiv": "Content-Language",
      content: "ja",
    });
  }

  onAddNoIndexTagForGerman(): void {
    this.meta.addTag({ name: "robots", content: "noindex" });
  }

  onClearServiceDataOnLogOut(): void {
    if (!_.isEmpty(this.gamePlayService.getCurrentGamePregmatic())) {
      this.gamePlayService.onClearGameWindowData();
    }

    this.commonService.onBroadcastNavigationData(null);

    this.gameGroupsService.onLastPlayedLiveCasinoGameAvailable(false);
  }

  onClosePromotionalVideo(): void {
    this.emitterService.onBroadcastIsPromotionalVideoEnabled(false);
  }

  onCloseConfirmDialogue(): void {
    this.isCashbackDepositOfferPopup = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onClosePopup(): void {
    this.isCashbackStartedPopup = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.cashbackPromoConfigurationsSubscription) {
      this.cashbackPromoConfigurationsSubscription.unsubscribe();
    }

    if (this.countryBlockInitialSubscription) {
      this.countryBlockInitialSubscription.unsubscribe();
    }

    if (this.getGeoLocationSubscription) {
      this.getGeoLocationSubscription.unsubscribe();
    }

    if (this.countryBlockSubscription) {
      this.countryBlockSubscription.unsubscribe();
    }

    if (this.userDetailsSubscription) {
      this.userDetailsSubscription.unsubscribe();
    }

    if (this.allow404Subscription) {
      this.allow404Subscription.unsubscribe();
    }

    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }

    if (this.gamesSubscription) {
      this.gamesSubscription.unsubscribe();
    }

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
