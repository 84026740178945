// Environments
import { environment } from "src/environments/environment";

// Models
import { LandingMobiles } from "src/app/modules/shared/models/landing-pages/landing-mobiles.model";
import { MobilePage } from "src/app/modules/shared/models/landing-pages/mobile-page.model";

export const mobileConfigurations = (languageCode: string): MobilePage => {
  let landing: LandingMobiles = {
    "en-row": {
      originalMobile: "feel-games_en-row.png",
      enablePromotion: false,
      promotionMobile: "feel-games_en-row.png",
    },
    "en-ca": {
      originalMobile: "feel-games_en-ca.png",
      enablePromotion: false,
      promotionMobile: "feel-games_en-ca.png",
    },
    "es-sa": {
      originalMobile: "feel-games_es-sa.png",
      enablePromotion: false,
      promotionMobile: "feel-games_es-sa.png",
    },
    "fi-fi": {
      originalMobile: "feel-games_fi-fi.png",
      enablePromotion: false,
      promotionMobile: "feel-games_fi-fi.png",
    },
    "hu-hu": {
      originalMobile: "feel-games_hu-hu.png",
      enablePromotion: false,
      promotionMobile: "feel-games_hu-hu.png",
    },
    "ja-jp": {
      originalMobile: "feel-games_ja-jp.png",
      enablePromotion: true,
      promotionMobile: "purple-feel-games_ja-jp.png",
    },
    "nb-no": {
      originalMobile: "feel-games_nb-no.png",
      enablePromotion: false,
      promotionMobile: "feel-games_nb-no.png",
    },
    "en-nz": {
      originalMobile: "feel-games_en-nz.png",
      enablePromotion: false,
      promotionMobile: "feel-games_en-nz.png",
    },
    "pt-br": {
      originalMobile: "feel-games_pt-br.png",
      enablePromotion: true,
      promotionMobile: "purple-feel-games_pt-br.png",
    },
    "de-de": {
      originalMobile: "feel-games_de-de.png",
      enablePromotion: false,
      promotionMobile: "feel-games_de-de.png",
    },
    "en-eu": {
      originalMobile: "feel-games_de-de.png",
      enablePromotion: false,
      promotionMobile: "feel-games_de-de.png",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    mobileDisplay: environment.mobileMarket
      ? landing[languageCode].enablePromotion
        ? landing[languageCode].promotionMobile
        : landing[languageCode].originalMobile
      : landing[languageCode].originalMobile,
  };
};
