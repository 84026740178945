import { combineLatest, Observable, of, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
} from "@angular/core";

// Configurations
import { localToCmsLanguageConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Models
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { GameLaunch } from "src/app/modules/game-groups/models/game-launch/game-launch.model";
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GameGroup } from "src/app/modules/game-groups/models/game-group.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAllLobbyPregmatic,
  selectLastPlayedState,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-view-all-game",
  templateUrl: "./view-all-games.component.html",
  styleUrls: ["./view-all-games.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ViewAllGamesComponent implements OnInit, OnDestroy {
  // View Childs
  @ViewChild("pageHeader", { static: false }) pageHeader: ElementRef;
  @ViewChild("popupOver", { static: false }) popupOver: ElementRef;

  // Numbers
  userTotalBalance: number = 0;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  websiteCode: string = environment.websiteCode;
  mediaUrl: string = environment.mediaUrl;
  currentPageGGroupUrl: string = "";
  activeProviderName: string = "";
  activeGroupName: string = "";
  currencySymbol: string = "";
  viewType: string = "games";
  languageCode: string = "";
  providerName: string = "";
  activeLobby: string = "";
  imgixParams: string = "";
  pageTitle: string = "";
  sortedBy: string = "";

  // Booleans
  isGameAvailable: boolean = false;
  isPageHeaderBg: boolean = false;
  isNameLoading: boolean = false;
  isShowBanner: boolean = true;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  beforeSortFilteredGameList: GamePregmatic[] = [];
  providerBeforeSortList: GameProviders[] = [];
  allFavoriteGamesList: GamePregmatic[] = [];
  filteredGamesList: GamePregmatic[] = [];
  providerList: GameProviders[] = [];
  lastPlayedGamesList: number[] = [];

  // Enums
  windowType: "desktop" | "mobile" = "desktop";
  deviceType: "desktop" | "mobile" = "desktop";

  // Objects
  gameGroupDetails: ProviderPregmatic | LobbyGameGroup;

  // Subscription
  languageSubscription: Subscription;
  gameSubscription: Subscription;
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private gamePlayService: GamePlayService,
    private gamesFilterPipe: GamesFilterPipe,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.languageSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });

    this.onActiveLobbyOrProviderName();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onLobbyGroupsAndGames();

    this.getWindowType();

    this.imgixParams = this.utilityService.getImgixParams();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
    }

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(
        (balanceUpdateMessage: string) => {
          if (balanceUpdateMessage == "UserBalanceUpdated") {
            this.userTotalBalance =
              this.userDetailsService.getUserTotalBalance();

            this.utilityService.toggleBannerDepositBtn(this.userTotalBalance);
          }
        }
      ),
    ];
  }

  ngAfterViewInit(): void {
    this.getWindowType();

    this.onSetOverflowHiddenClass();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:scroll")
  onScroll(): void {
    this.isPageHeaderBg = this.utilityService.scrollAddClass();
  }

  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();

    this.onSetOverflowHiddenClass();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "desktop";

      this.deviceType = "desktop";
    } else {
      this.windowType = "mobile";

      this.deviceType = "mobile";
    }

    if (
      (clientWidth >= 500 &&
        window.matchMedia("(orientation: landscape)").matches) ||
      (clientWidth >= 768 &&
        window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.windowType = "desktop";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getGamesbyGroupId(
    games: GamePregmatic[],
    gameGroupDetails: ProviderPregmatic
  ): GamePregmatic[] {
    let filteredGames: GamePregmatic[] = [];

    if (gameGroupDetails.group_type === "manual") {
      _.each(games, (game: GamePregmatic) => {
        if (game.gameGroupList) {
          _.each(
            game.gameGroupList,
            (list: GameGroup, indexGameGroup: number) => {
              if (list.id === gameGroupDetails.id) {
                if (game) {
                  let clonedGame: GamePregmatic = _.clone(game);

                  clonedGame.gameGroupList = game.gameGroupList[indexGameGroup];

                  filteredGames.push(clonedGame);
                }
                return;
              }
            }
          );
        }
      });
    } else if (
      gameGroupDetails.group_type === "automatic" &&
      gameGroupDetails.group_sub_type === "continue_playing"
    ) {
      if (
        this.isLoggedIn &&
        this.lastPlayedGamesList &&
        this.lastPlayedGamesList.length > 0
      ) {
        _.each(this.lastPlayedGamesList, (typeId: number) => {
          let game: GamePregmatic = _.findWhere(games, {
            beGameTypeId: typeId,
          });

          if (game) {
            filteredGames.push(game);
          }
        });

        filteredGames = this.gamesFilterPipe.transform(filteredGames, {
          typeOfGames: "non-live-game",
        });
      } else {
        this.filteredGamesList = [];
      }
    } else if (
      gameGroupDetails.group_type === "automatic" &&
      gameGroupDetails.group_sub_type === "favourite"
    ) {
      if (
        this.isLoggedIn &&
        this.allFavoriteGamesList &&
        this.allFavoriteGamesList.length > 0
      ) {
        _.each(this.allFavoriteGamesList, (favoriteGame: GamePregmatic) => {
          let game = _.findWhere(games, {
            beGameTypeId: favoriteGame.gameTypeId,
          });

          if (game) {
            filteredGames.push(game);
          }
        });
      } else {
        this.filteredGamesList = [];
      }
    }

    return filteredGames;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onActiveLobbyOrProviderName(): void {
    const segmentsList: string[] = this.utilityService
      .getDecodedCurrentPath()
      .split("/");

    if (this.activeLobby == "" && segmentsList[2])
      this.activeLobby = segmentsList[2];

    if (segmentsList[3]) {
      this.currentPageGGroupUrl = `${segmentsList[2]}/${segmentsList[3]}`;
    }

    let lobbyName: string = this.commonService.getActiveLobby();

    if (lobbyName) {
      this.activeLobby = this.multiLanguageService.getActiveLobby(lobbyName);
    } else {
      this.onLobbyNamebyGGroupMutilingualURL(this.currentPageGGroupUrl);
    }
  }

  onGoToFullScreen(targetEle: Element): void {
    this.utilityService.fullScreenInit(targetEle);
  }

  onGoOutFullScreen(): void {
    this.utilityService.exitFullScreen();

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onSetOverflowHiddenClass(): void {
    setTimeout(() => {
      if (
        document.querySelector(".page-body") &&
        this.deviceType === "mobile"
      ) {
        this.onGoToFullScreen(document.documentElement);
        this.utilityService.onAddClassToAppBody("overflow-hidden");
      } else {
        this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
      }
    });
  }

  onLobbyGroupsAndGames(isForce?: boolean): void {
    this.isLoading = true;

    let lastPlayedGamesApi: Observable<number[]>;

    if (this.isLoggedIn) {
      lastPlayedGamesApi = this.store.select(selectLastPlayedState);
    }

    this.gameSubscription = combineLatest([
      this.store.select(selectAllLobbyPregmatic),
      this.store.select(selectAllGames),
      lastPlayedGamesApi ? lastPlayedGamesApi : of(null),
    ]).subscribe(
      ([lobbyGameGroupsResponse, gamesResponse, lastPlayedResponse]) => {
        let lobbyGameGroupsList: LobbyPregmatic[] = lobbyGameGroupsResponse;

        let games: GamePregmatic[] = gamesResponse;

        window["prerenderReady"] = true;

        if (lastPlayedResponse) {
          let lastPlayedList: number[] = lastPlayedResponse;

          this.lastPlayedGamesList = _.clone(lastPlayedList);
        }

        /*
          Funationality Get Active lobby Group details based on
          the active group name
        */
        lobbyGameGroupsList = JSON.parse(JSON.stringify(lobbyGameGroupsList));

        if (lobbyGameGroupsList && lobbyGameGroupsList.length > 0) {
          this.onGroupDetailsByGGMultilingualURL(
            _.clone(lobbyGameGroupsList),
            this.currentPageGGroupUrl
          );
        }

        /*
          Funationality Get all the games mapped with this
          active group name
        */
        if (this.gameGroupDetails && games) {
          games = JSON.parse(JSON.stringify(games));

          this.gameGroupDetails.games = this.getGamesbyGroupId(
            games,
            this.gameGroupDetails as ProviderPregmatic
          );

          if (this.gameGroupDetails.content) {
            this.gameGroupDetails.content = replaceAllUtility(
              this.gameGroupDetails.content,
              {
                "{{site_url}}": environment.siteUrl,
                "{{mediaUrl}}": environment.mediaUrl,
                "{{websiteCode}}": environment.websiteCode,
              }
            );

            setTimeout(() => {
              this.utilityService.toggleBannerDepositBtn(this.userTotalBalance);

              let gameGroupBanner: NodeListOf<Element> =
                document.querySelectorAll(".gameGroupBanner");

              if (gameGroupBanner && gameGroupBanner.length <= 0) {
                this.isShowBanner = false;
              }
            });
          }
        }

        /*
          Functionality to check whether games are available ot not...
          To show Message as "No games configured under {{}}"
        */
        if (this.gameGroupDetails && this.gameGroupDetails.games) {
          if (this.gameGroupDetails.game_asset_style === "quicklink") {
            if (this.providerName) {
              this.onShowGamesInProviders(this.providerName);
            } else {
              this.viewType = "providers";

              this.providerList =
                this.utilityService.getSortedUniqueProviderList(
                  this.gameGroupDetails.games
                );

              this.isGameAvailable = true;
            }
          } else if (
            (this.gameGroupDetails.group_type === "automatic" &&
              this.gameGroupDetails.group_sub_type === "continue_playing") ||
            (this.gameGroupDetails.group_type === "automatic" &&
              this.gameGroupDetails.group_sub_type === "favourite")
          ) {
            this.filteredGamesList = this.gameGroupDetails.games;

            this.isGameAvailable = true;
          } else {
            this.filteredGamesList =
              this.utilityService.getSortedGamesListByCountry(
                this.gameGroupDetails.games
              );

            this.isGameAvailable = true;
          }
        } else {
          this.filteredGamesList = [];

          this.isGameAvailable = false;
        }

        this.isLoading = false;
      }
    );
  }

  /*
    Functioanlity to get Game Group details based on active current page
  */
  onGroupDetailsByGGMultilingualURL(
    lobbyPregmaticList: LobbyPregmatic[],
    currentPageGGroupUrl: string
  ): void {
    lobbyPregmaticList.map((lobbyPregmatic: LobbyPregmatic) => {
      if (
        lobbyPregmatic.name.toLowerCase() === this.activeLobby &&
        lobbyPregmatic.gameGroupList.length
      ) {
        lobbyPregmatic.gameGroupList.map((group: LobbyGameGroup) => {
          if (
            group.multilingualUrlGameGroup &&
            group.multilingualUrlGameGroup[
              localToCmsLanguageConfigurations[this.languageCode]
            ] &&
            currentPageGGroupUrl
          ) {
            let multilingualUrlGameGroupName: string =
              group.multilingualUrlGameGroup[
                localToCmsLanguageConfigurations[this.languageCode]
              ];

            if (
              multilingualUrlGameGroupName.toLowerCase().trim() ===
              currentPageGGroupUrl.toLowerCase().trim()
            ) {
              this.gameGroupDetails = group;

              this.utilityService.setSEO(this.gameGroupDetails, true);

              this.multiLanguageService.onSetCanonicalURL(
                this.gameGroupDetails.multilingualUrlGameGroup
              );

              this.pageTitle = group.name;

              this.isNameLoading = false;

              return;
            }
          }
        });
      }
    });
  }

  /*
    Sort game name by alphabet(A-Z)
  */
  onSortByGameName(calledFrom: string): void {
    if (calledFrom === "games") {
      if (this.sortedBy !== "a-z") {
        this.sortedBy = "a-z";

        this.filteredGamesList = this.utilityService.getSortedGamesByGameName(
          this.gameGroupDetails.games,
          "name"
        );
      } else {
        this.sortedBy = "";

        this.filteredGamesList =
          this.utilityService.getSortedGamesListByCountry(
            this.gameGroupDetails.games
          );
      }
    } else if (calledFrom === "providers") {
      if (this.sortedBy !== "a-z") {
        this.sortedBy = "a-z";

        this.providerBeforeSortList = this.providerList;

        this.providerList = _.sortBy(this.providerList, "vendorDisplayName");
      } else {
        this.sortedBy = "";

        this.providerList = this.providerBeforeSortList;
      }
    } else {
      if (this.sortedBy !== "a-z") {
        this.sortedBy = "a-z";

        this.filteredGamesList = this.utilityService.getSortedGamesByGameName(
          this.filteredGamesList,
          "name"
        );
      } else {
        this.sortedBy = "";

        this.filteredGamesList = this.beforeSortFilteredGameList;
      }
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /*
    Sort game by Release Date
  */
  onOrderByReleaseDate(): void {
    if (this.sortedBy !== "release-date") {
      this.sortedBy = "release-date";

      this.filteredGamesList = this.utilityService.getSortedGamesByReleaseDate(
        this.gameGroupDetails.games,
        "date"
      );
    } else {
      this.sortedBy = "";

      this.filteredGamesList = this.gameGroupDetails.games;
    }
  }

  onShowGamesInProviders(providerName: string): void {
    providerName = this.utilityService.convertGameNameToUrl(providerName);

    this.router.navigate([`${this.languageCode}/studio/${providerName}`]);
  }

  /*
    Functaionality Go back to the respective lobby...
  */
  onGoBack(): void {
    this.router.navigate([
      `${this.languageCode}/${this.translationService.get("url.casino")}`,
    ]);

    this.onGoOutFullScreen();
  }

  onGameLaunch(gameName: string, hasDemo: boolean): void {
    gameName = this.utilityService.convertGameNameToUrl(gameName);

    this.gamePlayService.onClearGameWindowData();

    setTimeout(() => {
      if (gameName && (this.isLoggedIn || (!this.isLoggedIn && hasDemo))) {
        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.game"
          )}/${gameName}`,
        ]);
      } else {
        const activeTab: ActiveTab = {
          tabName: "login",
          showBackButton: true,
        };

        this.commonService.onBroadcastActiveAcountView(activeTab);
      }
    });
  }

  onLobbyNamebyGGroupMutilingualURL(currentPageGGroupUrl: string): void {
    this.subscription = this.store
      .select(selectAllLobbyPregmatic)
      .subscribe((response: LobbyPregmatic[]) => {
        if (response && response.length > 0) {
          let lobbyWithGameGroupList: LobbyPregmatic[] = response;

          _.filter(lobbyWithGameGroupList, (lobby: LobbyPregmatic) => {
            if (lobby.gameGroupList) {
              lobby.gameGroupList.filter((lobbyGameGroup: LobbyGameGroup) => {
                if (
                  lobbyGameGroup &&
                  this.languageCode &&
                  lobbyGameGroup.multilingualUrlGameGroup &&
                  lobbyGameGroup.multilingualUrlGameGroup[
                    localToCmsLanguageConfigurations[this.languageCode]
                  ]
                ) {
                  let multilingualUrlGameGroupName: string =
                    lobbyGameGroup.multilingualUrlGameGroup[
                      localToCmsLanguageConfigurations[this.languageCode]
                    ];

                  if (
                    multilingualUrlGameGroupName.toLowerCase().trim() ===
                    currentPageGGroupUrl.toLowerCase().trim()
                  ) {
                    this.activeLobby = lobby.name;

                    if (this.activeLobby) {
                      this.activeLobby =
                        this.multiLanguageService.getActiveLobby(
                          this.activeLobby
                        );
                    }
                    return;
                  }
                }
              });
            }

            if (this.activeLobby) {
              return;
            }
          });
        }

        if (!this.activeLobby) {
          this.router.navigate([`${this.languageCode}/casino`]);
        }
      });
  }

  onOpenRegistrationPopup(): void {
    this.registrationService.onOpenRegistration();
  }

  onJoinBanners(event: Event): void {
    let target: HTMLInputElement = <HTMLInputElement>event.target;

    let routerLink: string = target.getAttribute("routerLink");

    let data: {
      bonusCode: string;
    };

    if (routerLink === "deposit" && target.getAttribute("bonusCode")) {
      data = { bonusCode: target.getAttribute("bonusCode") };
    }

    /*
      We are using this registration routing here because of circular
      dependency
    */
    if (
      routerLink &&
      (routerLink === "registration" || routerLink === "register")
    ) {
      if (!this.isLoggedIn) {
        this.registrationService.onOpenRegistration();
      }
    } else if (routerLink) {
      if (
        routerLink &&
        this.multiLanguageService.getActiveLobby(routerLink.split("/")[1]) ===
          "game"
      ) {
        this.gamePlayService.onClearGameWindowData();
      }
      /*
        Below Utility Handler decide routerLink event action on banner
        like registration button , login btn, game play btn deposit etc...
      */
      this.utilityService.onCmsRoutingHandler(routerLink, data);
    }
  }

  onTriggerGameLaunch(gameLaunch: GameLaunch): void {
    this.gamePlayService.onSetGameCalledFrom(gameLaunch.callingFrom);

    this.onGameLaunch(gameLaunch.name, gameLaunch.hasDemo);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");

    if (this.languageSubscription) this.languageSubscription.unsubscribe();

    if (this.gameSubscription) this.gameSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
