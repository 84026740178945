import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { ResetPassword } from "src/app/modules/auth/models/reset-password.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { StatusResponse } from "src/app/models/api/status.response";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { ForgotPasswordAPIService } from "src/app/modules/auth/store/services/forgot-password-api.service";
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

// Validators
import { UniqueFieldsValidator } from "src/app/modules/validators/validators/unique.validators";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent extends FormValidationComponent {
  // Strings
  languageCode: string = "";
  serverError: string = "";
  emailId: string = "";
  theme: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isEmailSent: boolean = false;

  // Form Groups
  forgotPasswordForm: FormGroup;

  // Subscriptions
  sendResetPasswordSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private forgotPasswordService: ForgotPasswordAPIService,
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    super();

    this.forgotPasswordForm = this.formBuilder.group({
      txtEmail: new FormControl(
        "",
        [],
        [
          UniqueFieldsValidator.createValidator(
            "txtEmail",
            this.commonService,
            false
          ),
        ]
      ),
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.languageSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    Here in reset password api response For error code 100164 we are displaying error msg as 
    "User does not exist please check the email" & for remaining below error codes
    we don't get any information when they occur 
    so we are displaying "something went wrong message"
    For all the below errorCodes
    - 100157:TECHNICAL_ERROR
    - 100190:AUTHENTICATION_ERROR
    - 100191:UNAUTHORIZED_CLIENT
    - 100192:INVALID_GRANT
    - 100193:INVALID_CLIENT
    - 100194:INVALID_TOKEN 
    - 100100:SOME_THING_WENT_WRONG
  */
  onSendResetPasswordLink(): void {
    this.isButtonLoader = true;

    this.emailId = this.forgotPasswordForm.controls["txtEmail"].value;

    this.sendResetPasswordSubscription = this.forgotPasswordService
      .onSendResetPasswordLink({
        email: this.emailId,
      })
      .subscribe((response: ResetPassword) => {
        this.isButtonLoader = false;

        if (
          response &&
          response.success &&
          response.success.status === StatusResponse.SUCCESS
        ) {
          this.isEmailSent = true;

          this.formService.onResetFormFields(this.forgotPasswordForm, true);
        } else if (response.errorCode === 100164) {
          this.serverError = this.translationService.get("errors.error66");
        } else if (
          response &&
          response.errors &&
          response.errors.hasOwnProperty("email")
        ) {
          this.serverError = this.translationService.get(
            "forgotpwdComponent.email_field_required"
          );
        } else {
          this.serverError = this.translationService.get("common.error44");
        }

        setTimeout(() => {
          this.isEmailSent = false;

          this.serverError = undefined;
        }, 3000);
      });
  }

  onGoToLoginPage(): void {
    const activeTab: ActiveTab = {
      tabName: "login",
      showBackButton: true,
    };

    this.commonService.onBroadcastActiveAcountView(activeTab);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.sendResetPasswordSubscription)
      this.sendResetPasswordSubscription.unsubscribe();

    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }
}
