// Models
import { DepositMethodConfigurations } from "src/app/models/configurations/deposit-method-configuration.model";
import { InputType } from "src/app/models/configurations/enums/input-type.enum";

export let withdrawalMethodsConfigurations: {
  [key: string]: DepositMethodConfigurations;
} = {
  CREDITCARD_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  NETELLER_PIQ: {
    txType: "NetellerWithdrawal",
    displayName: "Neteller",
    providerName: "NETELLER",
    inputs: [
      {
        id: "accountId",
        name: "AccountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "account",
        placeholderText: "cashierFormFields.netellerAccountIdPH",
        key: "account",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.netellerAccountIdLabel",
        logoDisplayOnInput: true,
      },
      // {
      //   id: 'secureId',
      //   placeholderText: 'cashierFormFields.netellerSecureIdPH',
      //   key: 'secureId',
      //   requiredRepeatPayment: false,
      //   visibleOnRepeatPayment: false,
      //   requiredNewPayment: true,
      //   visibleOnNewPayment: true,
      //   type: InputType.text,
      //   class: 'col-100',
      //   lableDisplay: true,
      //   lableDisplayText: 'cashierFormFields.netellerSecureIdLabel',
      // }
    ],
  },
  SKRILL_PIQ: {
    txType: "SkrillWithdrawal",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.skrillWithdrawAccountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "email",
        name: "cashierFormFields.skrillWithdrawEmail",
        key: "email",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.text,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  ECOPAYZ_PIQ: {
    txType: "EcopayzWithdrawal",
    displayName: "Ecopayz",
    providerName: "ECOPAYZ",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  SOFORT_PIQ: {
    txType: "SofortWithdrawal",
    displayName: "Sofort",
    providerName: "SOFORT",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  PAYSAFECARD_PIQ: {
    txType: "PaysafecardWithdrawal",
    displayName: "Paysafecard",
    providerName: "PAYSAFECARD",
    inputs: [],
  },
  TRUSTLY_PIQ: {
    txType: "TrustlyWithdrawal",
    displayName: "Trustly",
    providerName: "TRUSTLY",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  GIROPAY_PIQ: {
    txType: "GiropayWithdrawal",
    displayName: "Giropay",
    providerName: "GIROPAY",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  PPRO_SOFORT_PIQ: {
    txType: "PProWithdrawal",
    displayName: "PPRO",
    providerName: "PPRO",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  ZIMPLER_PIQ: {
    txType: "ZimplerWithdrawal",
    displayName: "Zimpler",
    providerName: "ZIMPLER",
    inputs: [
      {
        id: "accountId",
        placeholderText: "cashierFormFields.zimplerAccountIdPH",
        key: "accountId",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.zimplerAccountIdLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "phoneNumber",
        placeholderText: "cashierFormFields.zimplerPhoneNumberPH",
        key: "phoneNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.zimplerPhoneNumber",
      },
    ],
  },
  MUCHBETTER_PIQ: {
    txType: "MuchBetterWithdrawal",
    displayName: "MuchBetter",
    providerName: "MUCHBETTER",
    inputs: [
      {
        id: "phoneNumber",
        placeholderText: "cashierFormFields.muchbetterPhoneNumberPH",
        key: "phoneNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.muchbetterPhoneNumberLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  EUTELLER_PIQ: {
    txType: "EutellerWithdrawal",
    displayName: "Euteller",
    providerName: "EUTELLER",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  SIRU_PIQ: {
    txType: "SiruWithdrawal",
    displayName: "Siru",
    providerName: "SIRU",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  IDEBIT_PIQ: {
    txType: "IDebitWithdrawal",
    displayName: "iDebit",
    providerName: "IDEBIT",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  INSTADEBIT_PIQ: {
    txType: "InstadebitWithdrawal",
    displayName: "Instadebit",
    providerName: "INSTADEBIT",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  BANK_INTERAC_PIQ: {
    txType: "BankWithdrawal",
    displayName: "Interac",
    providerName: "BANK",
    service: "INTERAC",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  SKRILLQCO_RAPIDTRANSFER_PIQ: {
    txType: "SkrillQCOWithdrawal",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  JETON_JETGO_PIQ: {
    txType: "JetonWithdrawal",
    displayName: "Jeton",
    providerName: "JETON",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  JETON__PIQ: {
    txType: "JetonWithdrawal",
    displayName: "Jeton",
    providerName: "JETON",
    inputs: [
      {
        id: "customerNumber",
        placeholderText: "cashierFormFields.jetoncustomerNumberPH",
        key: "customerNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.jetoncustomerNumberlabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  JETON_CHECKOUT_PIQ: {
    txType: "JetonWithdrawal",
    displayName: "Jeton",
    providerName: "JETON",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  ENVOY_SOFORT_PIQ: {
    txType: "EnvoyWithdrawal",
    displayName: "Envoy",
    providerName: "ENVOY",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  ENVOY_GIROPAY_PIQ: {
    txType: "EnvoyWithdrawal",
    displayName: "Envoy",
    providerName: "ENVOY",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  SKRILLQCO_SOFORT_PIQ: {
    txType: "SkrillQCOWithdrawal",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  SKRILLQCO_GIROPAY_PIQ: {
    txType: "SkrillQCOWithdrawal",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  VENUSPOINT_PIQ: {
    txType: "VenusPointWithdrawal",
    displayName: "VenusPoint",
    providerName: "VENUSPOINT",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "col-100",
      },
    ],
  },
  IWALLET_PIQ: {
    txType: "IWalletWithdrawal",
    displayName: "IWallet",
    providerName: "IWALLET",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  APCO_APG_PIQ: {
    txType: "ApcoWithdrawal",
    displayName: "Apco",
    providerName: "APCO",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  BANK_VOLT_PIQ: {
    txType: "BankWithdrawal",
    displayName: "Bank",
    providerName: "BANK",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  CREDITCARD_KWICKGO_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_PASSNGO_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_CRYPTOGO_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_PURPLEPAY_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_REVOLUT_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_CLEARHAUS_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_KLUWP_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_APCO_PIQ: {
    txType: "CreditcardWithdrawal",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        placeholderText: "cashierFormFields.ccNumberPH",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cardHolder",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  ECOBANQ_PIQ: {
    txType: "EcoBanqWithdrawal",
    displayName: "EcoBanq",
    providerName: "ECOBANQ",
    inputs: [
      {
        id: "ecoBanqUserId",
        placeholderText: "cashierFormFields.ecoBanqUserIdPH",
        key: "ecoBanqUserId",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ecoBanqUserIdLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "ecoBanqPassword",
        placeholderText: "cashierFormFields.ecoBanqPasswordPH",
        key: "ecoBanqPassword",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.password,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ecoBanqPasswordLabel",
      },
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "col-100",
      },
    ],
  },
  BANKIBAN_PIQ: {
    txType: "BankIBANWithdrawal",
    displayName: "BankIBAN",
    providerName: "BANKIBAN",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "iban",
        placeholderText: "cashierFormFields.bankIbanIbanPH",
        key: "iban",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIbanIbanLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "bic",
        placeholderText: "cashierFormFields.bankIbanBicPH",
        key: "bic",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIbanBicLabel",
      },
      {
        id: "beneficiaryName",
        placeholderText: "cashierFormFields.bankIbanBeneficiaryNamePH",
        key: "beneficiaryName",
        requiredNewPayment: true,
        visibleOnNewPayment: false,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIbanBeneficiaryNameLabel",
      },
    ],
  },
  BANKLOCAL_PIQ: {
    txType: "BankLocalWithdrawal",
    displayName: "BankLocal",
    providerName: "BANKLOCAL",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "accountNumber",
        placeholderText: "cashierFormFields.bankLocalAccountNumberPH",
        key: "accountNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankLocalAccountNumberLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "clearingNumber",
        placeholderText: "cashierFormFields.bankLocalclearingNumberPH",
        key: "clearingNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankLocalclearingNumberLabel",
      },
      {
        id: "beneficiaryName",
        placeholderText: "cashierFormFields.bankLocalBeneficiaryNamePH",
        key: "beneficiaryName",
        requiredNewPayment: true,
        visibleOnNewPayment: false,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIbanBeneficiaryNameLabel",
      },
    ],
  },
  BANKINTL_PIQ: {
    txType: "BankIntlWithdrawal",
    displayName: "BankIntl",
    providerName: "BANKINTL",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "accountNumber",
        placeholderText: "cashierFormFields.bankIntlAccountNumberPH",
        key: "accountNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIntlAccountNumberLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "bic",
        placeholderText: "cashierFormFields.bankIntlBicPH",
        key: "bic",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIntlBicLabel",
      },
      {
        id: "bankName",
        placeholderText: "cashierFormFields.bankIntlBankNamePH",
        key: "bankName",
        requiredNewPayment: "basedOnCountry",
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIntlBankNameLabel",
        countries: ["IN"],
      },
      {
        id: "branchName",
        placeholderText: "cashierFormFields.bankIntlBranchNamePH",
        key: "branchName",
        requiredNewPayment: "basedOnCountry",
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.bankIntlBranchNameLabel",
        countries: ["IN"],
      },
    ],
  },
  NEOSURFVOUCHER_PIQ: {
    txType: "NeosurfVoucherWithdrawal",
    displayName: "NEOSURFVOUCHER",
    providerName: "NEOSURFVOUCHER",
    inputs: [
      {
        id: "email",
        placeholderText: "cashierFormFields..NeosurfEmailAddressPH",
        key: "email",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.NeosurfVoucheremailAddressLablel",
        logoDisplayOnInput: true,
      },
    ],
  },
  CRYPTOCURRENCY_PIQ: {
    txType: "CryptoCurrencyWithdrawal",
    displayName: "Cryptocurrency",
    providerName: "CRYPTOCURRENCY",
    inputs: [
      {
        id: "walletAddress",
        key: "walletAddress",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "100",
        placeholderText:
          "cashierFormFields.cryptoCurrencyWithdrawalWalletAddressPH",
        lableDisplay: true,
        lableDisplayText:
          "cashierFormFields.cryptoCurrencyWithdrawalWalletAddressLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "cryptocurrency",
        name: "paymentFieldsComp.cryptoCurrency",
        key: "cryptoCurrency",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        requestParam: true,
        type: InputType.select,
        class: "form-group",
        placeholderText: "paymentFieldsComp.select_currency",
        lableDisplay: true,
        lableDisplayText: "paymentFieldsComp.select_currency",
      },
    ],
  },
};
