import { ValidationErrors } from "@angular/forms";

// Services
import { CustomValidatorService } from "src/app/modules/validators/services/custom-validator.service";

export const getFormControlValidators = (
  fieldName: string,
  customValidatorService: CustomValidatorService
): ValidationErrors => {
  let validator: ValidationErrors;

  switch (fieldName) {
    case "expiryMonth":
    case "expiryYear":
    case "accountId":
    case "account":
    case "secureID":
    case "countryCode":
    case "voucherNumber":
    case "clearingNumber":
    case "beneficiaryName":
    case "bankName":
    case "bic":
    case "iban":
    case "beneficiaryStreet":
    case "beneficiaryZip":
    case "beneficiaryCity":
    case "beneficiaryState":
    case "beneficiaryCountry":
    case "branchAddress":
    case "branchCode":
    case "bankName":
    case "bankState":
    case "instrumentId":
    case "password":
    case "accountNumber":
    case "ecoBanqUserId":
    case "ecoBanqPassword":
    case "secureId":
    case "walletAddress":
    case "cryptocurrency": {
      validator = customValidatorService.validateRequired();

      break;
    }
    case "expiryMonthYear": {
      validator = customValidatorService.validateExpiryDateCard();

      break;
    }
    case "cvv": {
      validator = customValidatorService.validateExactNumber(3);

      break;
    }
    case "cardHolder": {
      validator = customValidatorService.validateName(2, 50);

      break;
    }
    case "emailAddress": {
      validator = customValidatorService.validateUniqueFields(
        "txtEmail",
        null,
        false
      );

      break;
    }
    case "userID": {
      validator = customValidatorService.validateMin(7);

      break;
    }
    case "creditcardNumber": {
      validator = customValidatorService.validateExactNumber(16, true);

      break;
    }
  }

  return validator;
};
