import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: ".number-only",
})
export class NumberDirective {
  @Input() numbersOnly: number = 0;

  constructor() {}

  @HostListener("keypress", ["$event"])
  keyPress(event: KeyboardEvent): boolean | void {
    let key: number = Number(event.key);

    if (isNaN(key)) {
      return false;
    }
  }
}
