export enum LanguageCodes {
  // NORWEGIAN
  no = "no",
  // NORWEGIAN
  nb = "nb",
  // FINNISH
  fi = "fi",
  // GERMAN
  de = "de",
  // NEW_ZEALAND
  nz = "nz",
  // CANADA
  cd = "cd",
  // ENGLISH
  en = "en",
  // JAPANESE
  ja = "ja",
  // NETHERLANDS
  nl = "nl",
  // PORTUGUESE
  pt = "pt",
  // SPANISH
  es = "es",
  // HUNGARIAN
  hu = "hu",
  // de-de
  "de-de" = "de-de",
  // pt-BR
  "pt-BR" = "pt-BR",
  // en-row
  "en-row" = "en-row",
  // en-ca
  "en-ca" = "en-ca",
  // en-in
  "en-in" = "en-in",
  // fi-fi
  "fi-fi" = "fi-fi",
  // nb-no
  "nb-no" = "nb-no",
  // nb-no
  "no-no" = "no-no",
  // en-nz
  "en-nz" = "en-nz",
  // en-eu
  "en-eu" = "en-eu",
  // ja-jp
  "ja-jp" = "ja-jp",
  // pt-br
  "pt-br" = "pt-br",
  // es-sa
  "es-sa" = "es-sa",
  // hu-hu
  "hu-hu" = "hu-hu",
}
