// Environments
import { environment } from "src/environments/environment";

// Models
import { LandingPromotions } from "src/app/modules/shared/models/landing-pages/landing-promotions.model";
import { LandingPage } from "src/app/modules/shared/models/landing-pages/landing-page.model";

export const landingConfigurations = (languageCode: string): LandingPage => {
  let landing: LandingPromotions = {
    "en-row": {
      originalDesktop: "welcome-bonus_en-row.png",
      originalMobile: "mobile-character_en-row.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
    "en-ca": {
      originalDesktop: "welcome-bonus_en-ca.png",
      originalMobile: "mobile-character_en-ca.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
    "es-sa": {
      originalDesktop: "welcome-bonus-desktop--latam.png",
      originalMobile: "welcome-bonus-mobile--latam.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-summer-desktop.png",
      promotionMobile: "franky-homepage-summer-mobile.png",
    },
    "fi-fi": {
      originalDesktop: "welcome-bonus_fi-fi.png",
      originalMobile: "mobile-character_fi-fi.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
    "hu-hu": {
      originalDesktop: "welcome-bonus_hu-hu.png",
      originalMobile: "mobile-character_hu-hu.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
    "ja-jp": {
      originalDesktop: "welcome-cambo_upgrade-secondary-ja-jp.png",
      originalMobile: "welcome-cambo_upgrade-secondary-ja-jp.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-japan-desktop.png",
      promotionMobile: "franky-homepage-japan-mobile.png",
    },
    "nb-no": {
      originalDesktop: "welcome-bonus_nb-no.png",
      originalMobile: "mobile-character_nb-no.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
    "en-nz": {
      originalDesktop: "welcome-bonus_en-nz.png",
      originalMobile: "mobile-character_en-nz.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-summer-nz-desktop.png",
      promotionMobile: "franky-homepage-summer-nz-mobile.png",
    },
    "pt-br": {
      originalDesktop: "welcome-bonus_new_pt-br.png",
      originalMobile: "mobile-character_new_pt-br.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-summer-desktop.png",
      promotionMobile: "franky-homepage-summer-mobile.png",
    },
    "de-de": {
      originalDesktop: "welcome-bonus_de-de.png",
      originalMobile: "mobile-character_de-de.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
    "en-eu": {
      originalDesktop: "welcome-bonus_de-de.png",
      originalMobile: "mobile-character_de-de.png",
      enablePromotion: true,
      promotionDesktop: "franky-homepage-winter-desktop.png",
      promotionMobile: "franky-homepage-winter-mobile.png",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    frankyDesktop: environment.frankyMarket
      ? landing[languageCode].enablePromotion
        ? landing[languageCode].promotionDesktop
        : landing[languageCode].originalDesktop
      : landing[languageCode].originalDesktop,
    frankyMobile: environment.frankyMarket
      ? landing[languageCode].enablePromotion
        ? landing[languageCode].promotionMobile
        : landing[languageCode].originalMobile
      : landing[languageCode].originalMobile,
  };
};
