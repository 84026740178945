import { Observable, of } from "rxjs";
import {
  ValidationErrors,
  AsyncValidatorFn,
  AbstractControl,
  ValidatorFn,
  FormGroup,
} from "@angular/forms";

// Models
import { NetDepositLimitsResponse } from "src/app/modules/limits/models/net-deposit-limits/net-deposit-limits-response.model";
import { LimitIntervals } from "src/app/modules/limits/models/limits-intervals.model";
import { LimitsResponse } from "src/app/modules/limits/models/limits-response.model";

export class LimitsValidator {
  static validateResponsibleGamingLimits(
    limits: LimitIntervals | LimitsResponse | NetDepositLimitsResponse,
    userMinimumLimit: number
  ): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const { limit, period } = formGroup.controls;

      const regex: RegExp = /^[0-9]*$/;

      if (!limit.value) {
        // let messagePartOne: string = getTranslation("errors.error1");

        limit.setErrors({ required: true });

        return;
      } else if (!regex.test(limit.value.toString())) {
        // This field can contain only numbers
        // let messagePartNine: string = getTranslation("errors.error9");

        limit.setErrors({ onlyNumbers: true });

        return;
      } else if (limit.value < userMinimumLimit) {
        // Minimum amount must be greater than
        // let messagePartThirtyEight: string = getTranslation("errors.error38");

        // let formattedUserMinimum: string = currencyFormatPipe.transform(
        //   userMinimumLimit,
        //   currencySymbol
        // );

        // `${messagePartThirtyEight}${formattedUserMinimum}`

        limit.setErrors({ minimumAmount: true });

        return;
      }

      let currentLimits: LimitIntervals = limits as LimitIntervals;

      if (currentLimits) {
        switch (period.value) {
          case "dailyLimit": {
            // tslint:disable-next-line:max-line-length
            if (
              (currentLimits.weeklyLimit &&
                limit.value > currentLimits.weeklyLimit / 100) ||
              (currentLimits.monthlyLimit &&
                limit.value > currentLimits.monthlyLimit / 100)
            ) {
              // Daily limits should be less than weekly and monthly limits
              /*
                let messagePartThirtyNine: string = getTranslation(
                    "errors.error39"
                );
              */

              limit.setErrors({
                dailyLimitsShouldBeLessThanWeeklyAndMonthlyLimits: true,
              });
            } else {
              limit.setErrors(null);
            }
          }
          case "weeklyLimit": {
            if (
              currentLimits.monthlyLimit &&
              limit.value > currentLimits.monthlyLimit / 100
            ) {
              // Your weekly limit must be lower than your monthly limits
              /*
                let messagePartFortyOne: string = getTranslation(
                    "errors.error41"
                );
              */

              limit.setErrors({
                weeklyLimitsMustBeLowerThanMonthlyLimits: true,
              });
            } else if (
              currentLimits.dailyLimit &&
              currentLimits.pendingDailyLimit / 100 &&
              limit.value < currentLimits.pendingDailyLimit / 100
            ) {
              // Your weekly limit must be higher than your daily limits
              /*
                      let messagePartFiftyNine: string = getTranslation(
                        "errors.error59"
                      );
                     */

              limit.setErrors({
                dailyLimitsShouldBeHigherThanDailyLimits: true,
              });
            } else {
              limit.setErrors(null);
            }
          }
          case "monthlyLimit": {
            if (
              currentLimits.weeklyLimit &&
              limit.value < currentLimits.weeklyLimit / 100
            ) {
              // Your monthly limit must be higher than your weekly limits.
              /*
                let messagePartFortyTwo: string = getTranslation(
                    "errors.error42"
                );
              */

              limit.setErrors({
                monthlyLimitsMustBeHigherThanWeeklyLimits: true,
              });
            }

            if (
              (currentLimits.dailyLimit &&
                currentLimits.pendingDailyLimit &&
                limit.value < currentLimits.pendingDailyLimit / 100) ||
              (currentLimits.weeklyLimit &&
                currentLimits.pendingWeeklyLimit &&
                limit.value < currentLimits.pendingWeeklyLimit / 100)
            ) {
              // Your monthly limit must be higher than your daily and weekly limits
              // let messagePartSixty: string = getTranslation("errors.error60");

              limit.setErrors({
                monthlyLimitsMustBeHigherThanDailyAndWeeklyLimits: true,
              });
            } else {
              limit.setErrors(null);
            }
          }
        }
      }

      return;
    };
  }

  static validateNumericValue(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      const regex: RegExp = /^[0-9]+$/;

      if (!controlValue) {
        // let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (!regex.test(controlValue)) {
        // This field must contain only numbers
        // let messagePartNine: string = getTranslation("errors.error9");

        return of({ mustContainOnlyNumbers: true });
      } else {
        return of(null);
      }
    };
  }
}
