import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import {
  PolicyConfigs,
  PolicyResponse,
  PolicyConfigsUserDetails,
  PolicyUserDetailsResponse,
  PolicyUserDetailsUpdateResponse,
} from "../models/policy/policy.model";

@Injectable({
  providedIn: "root",
})
export class PolicyService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetPolicies(policyType: string): Observable<PolicyConfigs> {
    return this.apiInteractor
      .get<null, PolicyResponse>(`/ajax/policy/getPolicies`)
      .pipe(
        map((response: PolicyResponse) => {
          if (
            response &&
            response.policyConfigs &&
            response.policyConfigs.length > 0
          ) {
            let policies = response.policyConfigs.filter(
              (policy) => policy.policyType === policyType
            );

            if (policies.length > 0) {
              return policies[0];
            }

            return null;
          }

          return null;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetUserPolicyDetails(
    policyType: string
  ): Observable<PolicyConfigsUserDetails> {
    return this.apiInteractor
      .get<null, PolicyUserDetailsResponse>(`/ajax/policy/getUserPolicyDetails`)
      .pipe(
        map((response: PolicyUserDetailsResponse) => {
          if (response && response.policies && response.policies.length > 0) {
            let policies = response.policies.filter(
              (policy) => policy.policyType === policyType
            );

            if (policies.length > 0) {
              return policies[0];
            }

            return null;
          }

          return null;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*OnGetUserPolicyDetailsHistory(
    policyType: string
  ): Observable<PolicyConfigsUserDetails[]> {
    return this.apiInteractor
      .get<null, PolicyUserDetailsResponse>(
        `/ajax/policy/getUserPolicyDetailsHistory`
      )
      .pipe(
        map((response: PolicyUserDetailsResponse) => {
          return response.policyConfigs.filter(
            (policy) => policy.policyType === policyType
          );
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }*/

  updateUserPolicyDetails(
    updatedPolicy: PolicyConfigsUserDetails
  ): Observable<PolicyUserDetailsUpdateResponse> {
    const payload = { policies: JSON.stringify([updatedPolicy]) };

    return this.apiInteractor.post<any, any>(
      `/ajax/policy/updateUserPolicyDetails`,
      payload
    );
  }
}
