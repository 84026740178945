// Environments
import { environment } from "src/environments/environment";

// Models
import { LandingVips } from "src/app/modules/shared/models/landing-pages/landing-vips.model";
import { VipPage } from "src/app/modules/shared/models/landing-pages/vip-page.model";

export const vipConfigurations = (languageCode: string): VipPage => {
  let landing: LandingVips = {
    "en-row": {
      enableLeft: true,
      leftDesktop: "feelVIP_en-row.png",
      enableRight: false,
      rightDesktop: "",
    },
    "en-ca": {
      enableLeft: true,
      leftDesktop: "feelVIP_en-ca.png",
      enableRight: false,
      rightDesktop: "",
    },
    "es-sa": {
      enableLeft: true,
      leftDesktop: "feelVIP_es-sa.png",
      enableRight: false,
      rightDesktop: "",
    },
    "fi-fi": {
      enableLeft: true,
      leftDesktop: "feelVIP_fi-fi.png",
      enableRight: false,
      rightDesktop: "",
    },
    "hu-hu": {
      enableLeft: true,
      leftDesktop: "feelVIP_hu-hu.png",
      enableRight: false,
      rightDesktop: "",
    },
    "ja-jp": {
      enableLeft: true,
      leftDesktop: "vip-japan-left-up-grade.png",
      enableRight: true,
      rightDesktop: "vip-japan-right-upgrade.png",
    },
    "nb-no": {
      enableLeft: true,
      leftDesktop: "feelVIP_nb-no.png",
      enableRight: false,
      rightDesktop: "",
    },
    "en-nz": {
      enableLeft: true,
      leftDesktop: "feelVIP_en-nz.png",
      enableRight: false,
      rightDesktop: "",
    },
    "pt-br": {
      enableLeft: true,
      leftDesktop: "thatfeel_new_pt-br.png",
      enableRight: false,
      rightDesktop: "",
    },
    "de-de": {
      enableLeft: true,
      leftDesktop: "feelVIP_de-de.png",
      enableRight: false,
      rightDesktop: "",
    },
    "en-eu": {
      enableLeft: true,
      leftDesktop: "feelVIP_de-de.png",
      enableRight: false,
      rightDesktop: "",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    leftDisplay: environment.vipMarket
      ? landing[languageCode].enableLeft
        ? landing[languageCode].leftDesktop
        : ""
      : "",
    enableRight: environment.vipMarket
      ? landing[languageCode].enableRight
        ? landing[languageCode].enableRight
        : false
      : false,
    rightDisplay: environment.vipMarket
      ? landing[languageCode].enableRight
        ? landing[languageCode].rightDesktop
        : ""
      : "",
  };
};
