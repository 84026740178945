import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnDestroy,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { PromoConfigurationsDetailsResponse } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-details-response.model";
import { PromoConfigurationsDetailsRequest } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-details-request.model";
import { PromoConfigurations } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations.model";
import {
  PromoConfigurationsSlabGroupInfo,
  PromoConfigurationsSlabItem,
} from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-slab-award.model";
import { OptInResponse } from "src/app/modules/rewards/models/opt-in/opt-in-response.model";
import { OptInRequest } from "src/app/modules/rewards/models/opt-in/opt-in-request.model";
import { CampaignDetails } from "src/app/modules/rewards/models/user-campaign.model";
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-cashback-promo-reward",
  templateUrl: "./cashback-promo-reward.component.html",
  styleUrls: ["./cashback-promo-reward.component.scss"],
})
export class CashbackPromoRewardComponent implements OnInit, OnDestroy {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  // Inputs
  @Input() bonusDetails: CampaignItem;
  @Input() callingFrom: string = "";

  // Numbers
  promoParentId: number = 0;
  promoRaceId: number = 0;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  optinValidityDuration: string = "";
  currencySymbol: string = "";
  imgixParams: string = "";

  // Booleans
  isDepositCashbackPromo: boolean = false;
  isSuccessResponse: boolean = false;
  isCashbackPopup: boolean = false;
  isAbsolute: boolean = false;
  isLoading: boolean = false;

  // Enums
  currencyCode: WorldCurrencyCode;

  // Objects
  userSlabInfo: PromoConfigurationsSlabItem;
  promoConfigInfo: PromoConfigurations;

  // Subject
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  cashbackSubscription: Subscription;
  optInSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private cashbackPromoService: CashbackPromoService,
    private userDetailsService: UserDetailsService,
    private utilityService: UtilityService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();

    this.subscriptions = [
      this.userDetailsService.currencyCodeBehaviourSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;

      this.promoRaceId = (this.bonusDetails as CampaignDetails).promoRaceId;

      this.promoParentId = (this.bonusDetails as CampaignDetails).promoParentId;

      if (
        this.promoRaceId &&
        this.promoParentId &&
        !this.bonusDetails.depositRequired
      ) {
        this.onGetPromoConfigurationDetails();
      }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetPromoConfigurationDetails(): void {
    this.isLoading = true;

    let promoConfigurationsDetailsRequest: PromoConfigurationsDetailsRequest = {
      promoRaceId: this.promoRaceId,
      promoParentId: this.promoParentId,
    };

    this.cashbackSubscription = this.cashbackPromoService
      .onGetPromoConfigurationsDetails(promoConfigurationsDetailsRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((promoConfigInfo: PromoConfigurationsDetailsResponse) => {
        if (
          promoConfigInfo &&
          promoConfigInfo.status === StatusResponse.SUCCESS
        ) {
          this.promoConfigInfo = promoConfigInfo.promoConfig;

          let slabGroupInfo: PromoConfigurationsSlabGroupInfo =
            this.promoConfigInfo.slabGroupInfo[
              Object.keys(this.promoConfigInfo.slabGroupInfo)[0]
            ];

          this.userSlabInfo = _.filter(slabGroupInfo.slabInfo, {
            slabCurrency: this.currencyCode,
          })[0];

          this.isAbsolute =
            this.userSlabInfo.slabAward[0].slabAwardValue[0].absolute;
        }

        this.isLoading = false;
      });
  }

  onNavigateToNextStep(): void {
    /*
      Here we checking whether depositRequired for user or not
      based on that we are navigating to next step
    */
    this.isLoading = true;

    let optInRequest: OptInRequest = {
      promoId: this.promoParentId,
      promoRaceId: this.promoRaceId,
    };

    this.optInSubscription = this.cashbackPromoService
      .onGetOptIn(optInRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((optInResponse: OptInResponse) => {
        this.isLoading = false;

        if (optInResponse && optInResponse.status === StatusResponse.SUCCESS) {
          this.isCashbackPopup = true;

          this.isSuccessResponse = true;

          this.optinValidityDuration = this.utilityService.getTimeFormat(
            this.promoConfigInfo.campaignEndDate
          );

          this.cashbackPromoService.onBroadcastPromoCashbackOptnSuccess();
        } else {
          this.isCashbackPopup = true;
          this.isSuccessResponse = false;
        }
      });
  }

  onCloseConfirmDialogue(): void {
    this.isCashbackPopup = false;

    this.utilityService.closeAccountComponent();
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.cashbackSubscription) this.cashbackSubscription.unsubscribe();

    if (this.optInSubscription) this.optInSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
