// Models
import { FirestoreGame } from "src/app/modules/firelogger/models/pregmatic-game.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { FireLoggerStoreService } from "src/app/modules/firelogger/services/firelogger-store.service";

export const fireLoggerTrackGames = (
  gamesList: GamePregmatic[],
  fireLoggerStoreService: FireLoggerStoreService<FirestoreGame>
): void => {
  gamesList.map((game: GamePregmatic) => {
    const firestoreGame: FirestoreGame = {
      requesteDate: new Date().toLocaleString("en-CA"),
      gameCode: game.gameCode,
      vendorDisplayName: game.vendorDisplayName,
      gameName: game.name,
    } as FirestoreGame;

    fireLoggerStoreService.addFirelogger(firestoreGame, "requesteDate", "gameCode");
  });
};
