import { CommonModule, DecimalPipe, TitleCasePipe } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Components
import { TermsAndConditionsComponent } from "src/app/modules/shared/components/terms-and-conditions/terms-and-conditions.component";
import { SideMenuModalComponent } from "src/app/modules/navigation/components/side-menu-modal/side-menu-modal.component";
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";
import { PageNotFoundComponent } from "src/app/modules/shared/components/page-not-found/page-not-found.component";
import { ButtonLoaderComponent } from "src/app/modules/shared/components/button-loader/button-loader.component";
import { LoaderComponent } from "src/app/modules/shared/components/loader/loader.component";

// Directives
import { SwiperResizeDirective } from "src/app/modules/shared/directives/swiper-resize.directive";
import { NumberDirective } from "src/app/modules/shared/directives/number.directive";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperModule } from "ngx-swiper-wrapper";
import { AccordionModule } from "ngx-accordion";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";
import { SafeHtmlPipe } from "src/app/modules/shared/pipes/safe-html.pipe";

// Reducers
import * as fromLanguagesReducer from "src/app/modules/multi-languages/store/reducers/languages.reducer";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.languagePath,
    `.json?cb=${new Date().getTime()}`
  );
}

@NgModule({
  declarations: [
    TermsAndConditionsComponent,
    FormValidationComponent,
    SideMenuModalComponent,
    PageNotFoundComponent,
    SwiperResizeDirective,
    ButtonLoaderComponent,
    CurrencyFormatPipe,
    LoaderComponent,
    GamesFilterPipe,
    NumberDirective,
    SafeHtmlPipe,
  ],
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    AccordionModule,
    CommonModule,
    SwiperModule,
    FormsModule,
    MbscModule,
    StoreModule.forFeature(
      fromLanguagesReducer.LanguageStateFeatureKey,
      fromLanguagesReducer.reducer
    ),
  ],
  providers: [
    CustomBannerService,
    CurrencyFormatPipe,
    GamesFilterPipe,
    TitleCasePipe,
    DecimalPipe,
  ],
  exports: [
    TermsAndConditionsComponent,
    FormValidationComponent,
    SideMenuModalComponent,
    PageNotFoundComponent,
    SwiperResizeDirective,
    ButtonLoaderComponent,
    CurrencyFormatPipe,
    HttpClientModule,
    LoaderComponent,
    TranslateModule,
    GamesFilterPipe,
    AccordionModule,
    NumberDirective,
    CommonModule,
    SwiperModule,
    SafeHtmlPipe,
    DecimalPipe,
    FormsModule,
  ],
})
export class SharedModule {}
