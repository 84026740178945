export const feeEnabling = (
  languageCode: string
): {
  showFee: boolean;
  showTransactionOverview: boolean;
  hideTxOverview: boolean;
} => {
  let languageList: string[] = ["ja-jp"];

  if (languageList.includes(languageCode)) {
    return {
      showFee: true,
      showTransactionOverview: true,
      hideTxOverview: false,
    };
  } else {
    return {
      showFee: false,
      showTransactionOverview: false,
      hideTxOverview: true,
    };
  }
};
