import {
  addressCharactersAllowed,
  combinedLetters,
} from "src/app/modules/validators/configurations/characters.configurations";

export const nameRegex: RegExp = new RegExp(`^([${combinedLetters}\\s-])*$`);

export const addressRegex: RegExp = new RegExp(
  `^([${addressCharactersAllowed}#,'\\s-])*$`
);

export const alphaNumericRegex: RegExp = new RegExp(
  `^([${addressCharactersAllowed}#,'\\s-])*$`
);
