import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Actions
import { userProfileBalanceRequested } from "src/app/modules/user/store/actions/profile.actions";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Models
import { ActiveBonusDetails } from "src/app/modules/rewards/models/active-bonus-details.model";
import { DropBonusResponse } from "src/app/modules/rewards/models/drop-bonus-response.model";
import { DropBonusRequest } from "src/app/modules/rewards/models/drop-bonus-request.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-bonus",
  templateUrl: "./bonus.component.html",
  styleUrls: ["./bonus.component.scss"],
})
export class BonusComponent implements OnDestroy {
  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  selectedBonusId: string = "";
  currencySymbol: string = "";
  imgixParams: string = "";
  serverError: string = "";
  theme: string = "";

  // Booleans
  isBonusForfeit: boolean = false;
  isLoading: boolean = false;
  isOpen: boolean = false;

  // Arrays
  activeBonusDetailsList: ActiveBonusDetails[] = [];

  // Subscriptions
  activeBonusDetailsSubscription: Subscription;
  dropBonusSubscription: Subscription;
  currencySubscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private accountService: AccountService,
    private utilityService: UtilityService,
    private rewardsService: RewardsService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetActiveBonusDetails();

    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.currencySubscription =
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currenySymbol: string) => {
          this.currencySymbol = currenySymbol;
        }
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetActiveBonusDetails(): void {
    this.isLoading = true;

    this.activeBonusDetailsSubscription = this.rewardsService
      .onGetActiveBonusDetails()
      .subscribe((activeBonusDetailsList: ActiveBonusDetails[]) => {
        if (activeBonusDetailsList) {
          this.activeBonusDetailsList = activeBonusDetailsList;
          /*
            We filtering the bonus & display to user based on
            - Case 1: Bonus shouldn't be freeSpin Bonus & if it's a freeSpin bonus
                      It's completed status should be true.
            - case 2: If bonus wagering percentage reaches 100% also,we shouldn't
                      show this bonus to user(Basically case-2 should be handed from BE..For time been
                      we are handling from front-end).
                      (!bonus.isFreeSpinBonus || (bonus.isFreeSpinBonus && bonus.isFreeSpinsCompleted))
          */
          this.activeBonusDetailsList = this.activeBonusDetailsList.filter(
            (activeBonusDetails: ActiveBonusDetails) => {
              if (
                (!activeBonusDetails.isFreeSpinBonus ||
                  (activeBonusDetails.isFreeSpinBonus &&
                    activeBonusDetails.isFreeSpinsCompleted)) &&
                activeBonusDetails.wageredPercent < 100
              ) {
                return activeBonusDetails;
              }
            }
          );
        } else {
          this.activeBonusDetailsList = [];
        }

        this.isLoading = false;
      });
  }

  onDropBonus(): void {
    if (this.selectedBonusId) {
      let dropBonusRequest: DropBonusRequest = {
        ecrBonusId: this.selectedBonusId,
      };

      this.onCloseConfirmDialogue();

      this.dropBonusSubscription = this.accountService
        .onDropBonus(dropBonusRequest)
        .subscribe((response: DropBonusResponse) => {
          if (
            response.status === StatusResponse.SUCCESS &&
            response.bonusDropped === StatusResponse.SUCCESS
          ) {
            this.selectedBonusId = undefined;

            this.store.dispatch(userProfileBalanceRequested());

            this.onGetActiveBonusDetails();
          } else {
            if (this.selectedBonusId) {
              this.selectedBonusId = undefined;
            }

            this.serverError = this.translationService.get(
              "errors.dropbonusfail"
            );
          }
        });
    }
  }

  onOpenConfirmDialogue(bonusId: string): void {
    this.selectedBonusId = bonusId;

    this.isBonusForfeit = true;
  }

  onCloseConfirmDialogue(): void {
    if (this.selectedBonusId) {
      this.selectedBonusId = undefined;
    }

    this.isBonusForfeit = false;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.activeBonusDetailsSubscription)
      this.activeBonusDetailsSubscription.unsubscribe();

    if (this.dropBonusSubscription) this.dropBonusSubscription.unsubscribe();

    if (this.currencySubscription) this.currencySubscription.unsubscribe();
  }
}
