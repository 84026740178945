// Enums
import { Launch_MGS_Realistic_Games } from "src/app/models/configurations/enums/market/launch-mgs-realistic-games.enum";
import { Launch_Relax_No_Limit_City } from "src/app/models/configurations/enums/market/launch-relax-no-limit-city.enum";
import { Launch_MGS_Genesis_Gaming } from "src/app/models/configurations/enums/market/launch-mgs-genesis-gaming.enum";
import { Launch_MGS_Skillzz_Gaming } from "src/app/models/configurations/enums/market/launch-mgs-skillzz-gaming.enum";
import { Launch_Relax_Stake_Logic } from "src/app/models/configurations/enums/market/launch-relax-stake-logic.enum";
import { Launch_Relax_Fantasma } from "src/app/models/configurations/enums/market/launch-relax-fantasma.enum";
import { Launch_Relax_High_5 } from "src/app/models/configurations/enums/market/launch-relax-high-five.enum";
import { Launch_Relax_Skywind } from "src/app/models/configurations/enums/market/launch-relax-skywind.enum";
import { Launch_Relax_Hacksaw } from "src/app/models/configurations/enums/market/launch-relax-hacksaw.enum";
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Launch_Micro_Gaming } from "src/app/models/configurations/enums/market/launch-micro-gaming.enum";
import { WorldCountryCode } from "src/app/models/configurations/enums/localization/world-countries.enum";
import { Launch_Thunderkick } from "src/app/models/configurations/enums/market/launch-thunder-kick.enum";
import { Launch_Push_Gaming } from "src/app/models/configurations/enums/market/launch-push-gaming.enum";
import { Launch_Live_Casino } from "src/app/models/configurations/enums/market/launch-live-casino.enum";
import { Launch_Quick_Spin } from "src/app/models/configurations/enums/market/launch-quick-spin.enum";
import { Launch_Ygg_Drasil } from "src/app/models/configurations/enums/market/launch-ygg-drasil.enum";
import { Launch_MGS_Gamevy } from "src/app/models/configurations/enums/market/launch-mgs-gamevy.enum";
import { Launch_Is_of_tbet } from "src/app/models/configurations/enums/market/launch-is-of-tbet.enum";
import { Launch_Play_N_Go } from "src/app/models/configurations/enums/market/launch-play-n-go.enum";
import { Launch_Red_Tiger } from "src/app/models/configurations/enums/market/launch-red-tiger.enum";
import { Launch_Pragmatic } from "src/app/models/configurations/enums/market/launch-pragmatic.enum";
import { Launch_MGS_2BY2 } from "src/app/models/configurations/enums/market/launch-mgs-2by2.enum";
import { Launch_Betsoft } from "src/app/models/configurations/enums/market/launch-betsoft.enum";
import { Launch_Booming } from "src/app/models/configurations/enums/market/launch-booming.enum";
import { Launch_Playson } from "src/app/models/configurations/enums/market/launch-playson.enum";
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { Launch_Hub_88 } from "src/app/models/configurations/enums/market/launch-hub-88.enum";
import { Launch_Netent } from "src/app/models/configurations/enums/market/launch-netent.enum";
import { Launch_Slots } from "src/app/models/configurations/enums/market/launch-slots.enum";
import { CryptoCurrency } from "src/app/models/configurations/enums/crypto-currency.enum";
import { LaunchMessages } from "src/app/models/configurations/enums/launch-messages.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";
import { Launch_Vivo } from "src/app/models/configurations/enums/market/launch-vivo.enum";
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";
import { CountryCodes } from "src/app/models/configurations/enums/country-codes.enum";
import { LaunchHub88 } from "src/app/models/configurations/enums/launch-hub-88.enum";
import { LaunchRelax } from "src/app/models/configurations/enums/launch-relax.enum";
import { AccountView } from "src/app/models/configurations/enums/account-view.enum";

// Models
import { LaunchGameUrlBasedOnSubProvidersPrefixConfigurations } from "src/app/models/configurations/general-configurations/launch-game-url-based-on-provider-configuration.model";
import { UserProfileRegisteredLanguageConfigurations } from "src/app/models/configurations/general-configurations/user-profile-registered-language-configuration.model";
import { LivefeedRouletteNumberColorsConfigurations } from "src/app/models/configurations/general-configurations/livefeed-roulette-number-colors-configuration.model";
import { SeoLanguageCodeUpdateConfigurations } from "src/app/models/configurations/general-configurations/seo-language-code-update-configuration.model";
import { LanguageToGameLaunchConfigurations } from "src/app/models/configurations/general-configurations/language-to-game-launch-configuration.model";
import { LaunchGameBasedOnMarketConfigurations } from "src/app/models/configurations/general-configurations/launch-game-market.model-configuration";
import { LocalRegilyLanguageConfigurations } from "src/app/models/configurations/general-configurations/local-regily-language-configuration.model";
import { LanguageToRegisterConfigurations } from "src/app/models/configurations/general-configurations/language-to-register-configuration.model";
import { CmsLocalLanguageConfigurations } from "src/app/models/configurations/general-configurations/cms-local-language-configuration.model";
import { LocalCMSLanguageConfigurations } from "src/app/models/configurations/general-configurations/local-cms-language-configuration.model";
import { Alpha3ToAlpha2Configurations } from "src/app/models/configurations/general-configurations/alpha-3-to-alpha-2-configuration.model";
import { CountryLanguageConfigurations } from "src/app/models/configurations/general-configurations/country-language-configuration.model";
import { LiveChatGroupsConfigurations } from "src/app/models/configurations/general-configurations/live-chat-groups-configuration.model";
import { LanguageToPIQConfigurations } from "src/app/models/configurations/general-configurations/language-to-piq-configuration.model";
import { HrefLanguageConfigurations } from "src/app/models/configurations/general-configurations/href-language-configuration.model";
import { IncomeSourceConfigurations } from "src/app/models/configurations/general-configurations/income-source-configuration.model";
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";
import { LoginConfigurations } from "src/app/models/configurations/general-configurations/login-configuration.model";

/*
  Here we are using different code for indian english
  & canada as per our configuration in CMS iirespective of their 
  respective code
  For Indian English is "ei"
  For Canada English is 'cd'
  rw stands for rest of world English 

  Regily Language to CMS
*/
export const cmsToLocalLanguageConfigurations: CmsLocalLanguageConfigurations =
  {
    en: LanguageCodes["en-row"],
    de: LanguageCodes["de-de"],
    cd: LanguageCodes["en-ca"],
    nz: LanguageCodes["en-nz"],
    // ei: "en-in",
    fi: LanguageCodes["fi-fi"],
    no: LanguageCodes["nb-no"],
    ja: LanguageCodes["ja-jp"],
    nl: LanguageCodes["en-eu"],
    pt: LanguageCodes["pt-br"],
    es: LanguageCodes["es-sa"],
    hu: LanguageCodes["hu-hu"],
  };

/*
  CMS to Language Codes for Regily
*/
export const localToCmsLanguageConfigurations: LocalCMSLanguageConfigurations =
  {
    "en-row": LanguageCodes.en,
    "de-de": LanguageCodes.de,
    "en-ca": LanguageCodes.cd,
    "en-nz": LanguageCodes.nz,
    // "en-in": LanguageCodes.en,
    "fi-fi": LanguageCodes.fi,
    "nb-no": LanguageCodes.no,
    "ja-jp": LanguageCodes.ja,
    "en-eu": LanguageCodes.nl,
    "pt-br": LanguageCodes.pt,
    "es-sa": LanguageCodes.es,
    "hu-hu": LanguageCodes.hu,
  };

/*
  Cofiguration for live chat groups.
*/
export const liveChatGroupsConfigurations: LiveChatGroupsConfigurations[] = [
  { country: "en-row", groupId: 2 },
  { country: "en-ca", groupId: 2 },
  { country: "en-nz", groupId: 2 },
  { country: "en-eu", groupId: 2 },
  { country: "nb-no", groupId: 3 },
  { country: "fi-fi", groupId: 4 },
  { country: "de-de", groupId: 5 },
  { country: "ja-jp", groupId: 6 },
  { country: "pt-br", groupId: 15 },
  { country: "hu-hu", groupId: 16 },
  { country: "es-sa", groupId: 17 },

  { country: "fi-fi-vip", groupId: 22 },
  { country: "nb-no-vip", groupId: 23 },
  { country: "pt-br-vip", groupId: 26 },
];

/*
  Special cofiguration for regily registration flow.
*/
export const localToRegilyLanguageConfigurations: LocalRegilyLanguageConfigurations =
  {
    "en-row": LanguageCodes.en,
    "en-eu": LanguageCodes.en,
    "en-ca": LanguageCodes.en,
    "en-nz": LanguageCodes.nz,
    // "en-in": LanguageCodes.en,
    "fi-fi": LanguageCodes.fi,
    "nb-no": LanguageCodes.no,
    "ja-jp": LanguageCodes.ja,
    "de-de": LanguageCodes.de,
    "pt-br": LanguageCodes["pt-BR"],
    "es-sa": LanguageCodes.es,
    "hu-hu": LanguageCodes.hu,
  };

export const time24FormatConfigurations: string[] = [
  "en-row",
  "nb-no",
  "fi-fi",
  "ja-jp",
  "de-de",
  "pt-br",
  "es-sa",
  "hu-hu",
];

/*
  Special configuration for country ip based to our CMS support.

  Reference: https://www.science.co.il/language/Codes.php
  https://docs.oracle.com/cd/E13214_01/wli/docs92/xref/xqisocodes.html
*/
export const countryLanguageConfigurations: CountryLanguageConfigurations = {
  NO: LanguageCodes.no,
  FI: LanguageCodes.fi,
  DE: LanguageCodes.de,
  NZ: LanguageCodes.nz,
  CA: LanguageCodes.cd,
  IN: LanguageCodes.en,
  JP: LanguageCodes.ja,
  AT: LanguageCodes.de,
  NL: LanguageCodes.nl,
  BR: LanguageCodes.pt,
  PE: LanguageCodes.es,
  CL: LanguageCodes.es,
  PY: LanguageCodes.es,
  UY: LanguageCodes.es,
  BO: LanguageCodes.es,
  ES: LanguageCodes.es,
  SA: LanguageCodes.es,
  HU: LanguageCodes.hu,
};

/*
  Special Configuartion hreflang based on regions
*/
export const hrefLanguageConfigurations: HrefLanguageConfigurations = {
  "en-row": LanguageCodes.en,
  "de-de": LanguageCodes.de,
  "en-ca": LanguageCodes["en-ca"],
  // "en-in": LanguageCodes["en-in"],
  "fi-fi": LanguageCodes["fi-fi"],
  "nb-no": LanguageCodes["no-no"],
  "en-nz": LanguageCodes["en-nz"],
  "en-eu": LanguageCodes.en,
  "ja-jp": LanguageCodes["ja-jp"],
  "pt-br": LanguageCodes["pt-br"],
  "es-sa": LanguageCodes.es,
  "hu-hu": LanguageCodes["hu-hu"],
};

export const languageToRegisterConfigurations: LanguageToRegisterConfigurations =
  {
    CA: "en_CA",
    DE: "de_DE",
    AT: "de_DE",
    NZ: "en_NZ",
    FI: "fi_FI",
    NO: "no_NO",
    JP: "ja_JP",
    NL: "nl_NL",
    BR: "pt_BR",
    PE: "es_PE",
    CL: "es_PE",
    PY: "es_PE",
    UY: "es_PE",
    BO: "es_PE",
    HU: "hu_HU",
    others: "en_US",
  };

/*
  Language Codes for User Profile Registered
*/
export const userProfileRegisteredLanguageConfigurations: UserProfileRegisteredLanguageConfigurations =
  {
    en_CA: LanguageCodes["en-ca"],
    de_DE: LanguageCodes["de-de"],
    en_NZ: LanguageCodes["en-nz"],
    fi_FI: LanguageCodes["fi-fi"],
    no_NO: LanguageCodes["nb-no"],
    ja_JP: LanguageCodes["ja-jp"],
    nl_NL: LanguageCodes["en-eu"],
    en_US: LanguageCodes["en-row"],
    pt_BR: LanguageCodes["pt-br"],
    es_PE: LanguageCodes["es-sa"],
    hu_HU: LanguageCodes["hu-hu"], //this may also need to be updated from the CMS
  };

/*
  Language To PIQ -> Payment IQ
*/
export const languageToPIQConfigurations: LanguageToPIQConfigurations = {
  "en-row": "en_US",
  "de-de": "de_DE",
  "en-ca": "en_CA",
  "en-nz": "en_NZ",
  // "en-in": "en_IN",
  "fi-fi": "fi_FI",
  "nb-no": "no_NO",
  "ja-jp": "ja_JP",
  "en-eu": "en_ZW",
  "pt-br": "pt_BR",
  "es-sa": "es",
  "hu-hu": "hu_HU",
};

/*
  Login
*/
export const loginConfigurations: LoginConfigurations = {
  en_US: {
    language: "en",
    languageCode: "en-row",
  },
  de_DE: {
    language: "de",
    languageCode: "de-de",
  },
  en_CA: {
    language: "cd",
    languageCode: "en-ca",
  },
  en_NZ: {
    language: "nz",
    languageCode: "en-nz",
  },
  // "en-in": "en_IN",
  fi_FI: {
    language: "fi",
    languageCode: "fi-fi",
  },
  no_NO: {
    language: "no",
    languageCode: "nb-no",
  },
  ja_JP: {
    language: "ja",
    languageCode: "ja-jp",
  },
  en_ZW: {
    language: "eu",
    languageCode: "en-eu",
  },
  pt_BR: {
    language: "pt",
    languageCode: "pt-br",
  },
  "es-sa": {
    language: "es",
    languageCode: "es-es",
  },
  hu_HU: {
    language: "hu",
    languageCode: "hu-hu",
  },
};

/*
  Language To Game Launch
*/
export const languageToGameLaunchConfigurations: LanguageToGameLaunchConfigurations =
  {
    "en-row": LanguageCodes.en,
    "en-ca": LanguageCodes.cd,
    "en-nz": LanguageCodes.nz,
    "fi-fi": LanguageCodes.fi,
    "nb-no": LanguageCodes.no,
    "ja-jp": LanguageCodes.ja,
    "en-eu": LanguageCodes.en,
    "de-de": LanguageCodes.de,
    "pt-br": LanguageCodes.pt,
    "es-sa": LanguageCodes.es,
    "hu-hu": LanguageCodes.hu,
  };

/*
  Alpha 3 Language Code (3 Character Code for the Language)
  To
  Alpha 2 Language Code (2 Character Code for the Language)
*/
export const alpha3ToAlpha2Configurations: Alpha3ToAlpha2Configurations = {
  eng: LanguageCodes.en,
  fin: LanguageCodes.fi,
  nno: LanguageCodes.no,
  nob: LanguageCodes.no,
  nor: LanguageCodes.no,
  jpn: LanguageCodes.ja,
  deu: LanguageCodes.de,
  hun: LanguageCodes.hu,
};

/*
  Account View List
*/
export const accountViewConfigurations: string[] = [
  AccountView.my_transacts_history,
  AccountView.confirm_identity,
  AccountView.change_password,
  AccountView.update_profile,
  AccountView.bet_history,
  AccountView.preferences,
  AccountView.withdrawal,
  AccountView.time_out,
  AccountView.deposit,
  AccountView.rewards,
  AccountView.limits,
  AccountView.bonus,
];

/*
  Max Supported File Size
*/
export const maxSupportedFileSizeInBytesConfigurations: number = 3145728;

/*
  Income Source
*/
export const incomeSourceListConfigurations: IncomeSourceConfigurations[] = [
  {
    value: "savingsFromSalary",
    value_text: "sourceoffundDropDownText.salary_wage",
    description: "sourceoffundDropDownText.salary_wage_des",
  },
  {
    value: "companyProfits",
    value_text: "sourceoffundDropDownText.business_profits",
    description: "sourceoffundDropDownText.business_profits_des",
  },
  {
    value: "salesOfSharesOrOtherInvestments",
    value_text: "sourceoffundDropDownText.sale_of_shares",
    description: "sourceoffundDropDownText.sale_of_shares_des",
  },
  {
    value: "saleOfProperty",
    value_text: "sourceoffundDropDownText.sale_of_property",
    description: "sourceoffundDropDownText.sale_of_property_des",
  },
  {
    value: "companySale",
    value_text: "sourceoffundDropDownText.sale_assets_company_sale",
    description: "sourceoffundDropDownText.sale_assets_company_sale_des",
  },
  {
    value: "dividentPayment",
    value_text: "sourceoffundDropDownText.dividentPayment",
    description: "sourceoffundDropDownText.dividentPayment_des",
  },
  {
    value: "fixedDepositSavings",
    value_text: "sourceoffundDropDownText.fixedDepositSavings",
    description: "sourceoffundDropDownText.fixedDepositSavings_des",
  },
  {
    value: "inheritance",
    value_text: "sourceoffundDropDownText.Inheritance_Gift",
    description: "sourceoffundDropDownText.Inheritance_Gift_des",
  },
  {
    value: "loan",
    value_text: "sourceoffundDropDownText.loan",
    description: "sourceoffundDropDownText.loan_des",
  },
  {
    value: "gift",
    value_text: "sourceoffundDropDownText.gift",
    description: "sourceoffundDropDownTextgift",
  },
  {
    value: "other",
    value_text: "sourceoffundDropDownText.other",
    description: "sourceoffundDropDownText.other_des",
  },
];

/*
  Crypto Currencies that are currently Supported

  https://coinmarketcap.com/all/views/all/

  https://finance.yahoo.com/cryptocurrencies/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAANkjVq8ZFLyEltZ72_UW2pr9xW75GFH4x29iDvDozbrbFz4lIQHMeglwyoRA7SRHlwi4Xe3af9-uGgUoG3WSETfzaHabBzARhDeGOSj70am2vIf6ptaJtsgywf7CKgwR-TnqzL-FTyS_WuAO3LOXHOejKE7uP7ofLV1C5yOjbeza
*/
export const cryptoCurrencyListConfigurations: string[] = [
  CryptoCurrency.BCH,
  CryptoCurrency.BTC,
  CryptoCurrency.ETH,
  CryptoCurrency.LTC,
];

/*
  Live Feed Roulette Number Colours
*/
export const liveFeedRouletteNumberColorsConfigurations: LivefeedRouletteNumberColorsConfigurations =
  {
    black: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
    red: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
    green: [0],
  };

/*
  Quick Deposit Default Values
*/
export const quickDepositDefaultValuesConfigurations: {
  [key: string]: number;
} = {
  default_kr: 1000,
  "default_€": 100,
  default_$: 100,
  default_R$: 500,
};

/*
  This mapping is required for logout user's to show currency as per
  there country Locality

  NOTE: Used only for logout Users.
*/
export const currencyMappingConfigurations: {
  [key: string]: CurrencyConfigurations;
} = {
  CA: {
    currencyCode: WorldCurrencyCode.CAD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  FI: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  NO: {
    currencyCode: WorldCurrencyCode.NOK,
    currencySymbol: CurrencySymbol.kr,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  DE: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  AT: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  /*
  IN: {
    currencyCode: CurrencyCode.INR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  */
  JP: {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  NZ: {
    currencyCode: WorldCurrencyCode.NZD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  NL: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  BR: {
    currencyCode: WorldCurrencyCode.BRL,
    currencySymbol: CurrencySymbol.R$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  PE: {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  CL: {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  PY: {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  UY: {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  HU: {
    currencyCode: WorldCurrencyCode.HUF,
    currencySymbol: CurrencySymbol.Ft,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
};

/*
  Available Countries
*/
export const avialableCountriesConfigurations: string[] = [
  CountryCodes.CA,
  CountryCodes.FI,
  CountryCodes.NO,
  CountryCodes.IN,
  CountryCodes.NZ,
  CountryCodes.NL,
  CountryCodes.JP,
  CountryCodes.DE,
  CountryCodes.AT,
  CountryCodes.BR,
  CountryCodes.PE,
  CountryCodes.CL,
  CountryCodes.PY,
  CountryCodes.UY,
  CountryCodes.BO,
  CountryCodes.HU,
];

/*
 this config used to display deposit button on Banner if user has lessar than config balance else visible Play button
*/
export const bannerCTAConfigurations: {
  [key: string]: number;
} = {
  EUR: 5,
  CAD: 5,
  NZD: 5,
  USD: 5,
  NOK: 50,
  INR: 400,
  JPY: 500,
  BRL: 25,
};

export const userMinimumRGLimitCheckConfigurations: {
  [key: string]: number;
} = {
  kr: 100,
  $: 10,
  C$: 10,
  "€": 10,
  R$: 50,
  Ft: 100,
};

/*
  SEO Language Code Update
*/
export const seoLanguageCodeUpdateConfigurations: SeoLanguageCodeUpdateConfigurations =
  {
    "en-row": LanguageCodes.en,
    "de-de": LanguageCodes.de,
    "en-ca": LanguageCodes.en,
    "en-nz": LanguageCodes.en,
    // "en-in": LanguageCodes.en,
    "fi-fi": LanguageCodes.fi,
    "nb-no": LanguageCodes.nb,
    "ja-jp": LanguageCodes.ja,
    "en-eu": LanguageCodes.en,
    "pt-br": LanguageCodes.pt,
    "es-sa": LanguageCodes.es,
    "hu-hu": LanguageCodes.hu,
  };

export const liveProviderCheckListPreferredOrderConfigurations: string[] = [
  "hub88_evolutiongaming",
  "pragmaticplay",
  "hub88_ezugi",
  "netent",
  "vivo",
];

export const mixPanelEventsConfigurations: {
  [key: string]: string;
} = {
  clicked_Provider_Filter: "Clicked Live Casino Provider Filter",
  clicked_Provider_Lobby: "Clicked Provider Lobby",
  clicked_Game_Category: "Clicked Game Category",
  initiated_Search: "Initiated Search",
  launched_game: "Launched Game",
};

// Excluded languages from the footer list
export const excludeLanguageOptionsConfigurations: string[] = [
  "en-eu",
  "de-de",
];

export const marketLocaleCurrencyMappingConfigurations: {
  [key: string]: CurrencyConfigurations;
} = {
  "en-ca": {
    currencyCode: WorldCurrencyCode.CAD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "fi-fi": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "nb-no": {
    currencyCode: WorldCurrencyCode.NOK,
    currencySymbol: CurrencySymbol.kr,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "de-de": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "en-row": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "ja-jp": {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "en-nz": {
    currencyCode: WorldCurrencyCode.NZD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "en-eu": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "pt-br": {
    currencyCode: WorldCurrencyCode.BRL,
    currencySymbol: CurrencySymbol.R$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "es-sa": {
    currencyCode: WorldCurrencyCode.USD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "hu-hu": {
    currencyCode: WorldCurrencyCode.HUF,
    currencySymbol: CurrencySymbol.Ft,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
};

/*
  Log Rocket Countries Configurations
*/
export const logRocketCountriesConfigurations: string[] = [
  WorldCountryCode.SK,
  WorldCountryCode.NZ,
];

/*
  This mapping is required for pt-br, es-sa locale
  For only below providers game launch URL we are addding lang Codes
*/
export const launchGameURlBasedOnSubProvidersPrefixConfigurations: LaunchGameUrlBasedOnSubProvidersPrefixConfigurations =
  {
    "pt-br": {
      hub88: LaunchHub88.pt,
      relax: LaunchRelax.pt_BR,
      mgs: LaunchMessages["pt-br"],
    },
    "es-sa": {
      hub88: LaunchHub88.es,
      relax: LaunchRelax.es_ES,
      mgs: LaunchMessages.es,
    },
    "hu-hu": {
      hub88: LaunchHub88.hu,
      relax: LaunchRelax.hu_HU,
      mgs: LaunchMessages.hu,
    },
  };

export const launchGameURLBasedOnMarketConfigurations: {
  [key: string]: LaunchGameBasedOnMarketConfigurations;
} = {
  "pt-br": {
    quickspin: Launch_Quick_Spin.pt,
    yggdrasil: Launch_Ygg_Drasil["pt-BR"],
    thunderkick: Launch_Thunderkick["pt-br"],
    vivo: Launch_Vivo.pt,
    playngo: Launch_Play_N_Go.pt_BR,

    relax_fantasma: Launch_Relax_Fantasma.pt_BR,
    relax_hacksaw: Launch_Relax_Hacksaw["PT-BR"],
    relax_skywind: Launch_Relax_Skywind.PT,
    relax_high5: Launch_Relax_High_5["pt-BR"],
    relax_stakelogic: Launch_Relax_Stake_Logic["pt-br"],
    betsoft: Launch_Betsoft.pt,
    pushgaming: Launch_Push_Gaming.pt,

    // Optional
    mgs_2by2: Launch_MGS_2BY2.pt,
    mgs_gamevy: Launch_MGS_Gamevy.pt,
    mgs_genesisgaming: Launch_MGS_Genesis_Gaming.pt,
    mgs_realisticgames: Launch_MGS_Realistic_Games.pt,
    mgs_skillzzgaming: Launch_MGS_Skillzz_Gaming.pt,
  },
  "es-sa": {
    quickspin: Launch_Quick_Spin.es,
    yggdrasil: Launch_Ygg_Drasil.es,
    thunderkick: Launch_Thunderkick["es-LA"],
    vivo: Launch_Vivo.es,
    playngo: Launch_Play_N_Go.es_ES,

    relax_fantasma: Launch_Relax_Fantasma.es_ES,
    relax_hacksaw: Launch_Relax_Hacksaw.ES,
    relax_skywind: Launch_Relax_Skywind.ES,
    relax_high5: Launch_Relax_High_5.es,
    relax_stakelogic: Launch_Relax_Stake_Logic.es_ES,
    betsoft: Launch_Betsoft.es,
    pushgaming: Launch_Push_Gaming.es,

    // Optional
    relax_nolimitcity: Launch_Relax_No_Limit_City.ES,
    isoftbet: Launch_Is_of_tbet.ES,
    redtiger: Launch_Red_Tiger.es,
  },
  "hu-hu": {
    quickspin: Launch_Quick_Spin.hu,
    yggdrasil: Launch_Ygg_Drasil.hu,
    thunderkick: Launch_Thunderkick.hu,
    vivo: Launch_Vivo.hu,
    playngo: Launch_Play_N_Go.hu,

    relax_fantasma: Launch_Relax_Fantasma.hu,
    relax_hacksaw: Launch_Relax_Hacksaw.HU,
    relax_skywind: Launch_Relax_Skywind.HU,
    relax_high5: Launch_Relax_High_5.hu,
    relax_stakelogic: Launch_Relax_Stake_Logic.hu,
    betsoft: Launch_Betsoft.hu,
    pushgaming: Launch_Push_Gaming.hu,

    // Optional
    relax_nolimitcity: Launch_Relax_No_Limit_City.HU,
    isoftbet: Launch_Is_of_tbet.HU,
    redtiger: Launch_Red_Tiger.hu,

    pragmatic: Launch_Pragmatic.hu,
    livecasino: Launch_Live_Casino.hu,
    slots: Launch_Slots.hu,
    booming: Launch_Booming.hu,

    hub88: Launch_Hub_88.hu,
    playson: Launch_Playson.hu,
    netent: Launch_Netent.hu,
    microgaming: Launch_Micro_Gaming.hu,
  },
};
