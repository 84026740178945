import { RegilyRegistration } from "src/app/modules/registration/models/regily-registration.model";
import { Gender } from "src/app/models/configurations/enums/gender.enum";

/**
    Below class will create final request payload which we pass to
    BO when user register. You can see all the data along with user affiliate
    related data is also pushed.
*/
export class RegilyRegistrationRequest {
  // Strings
  pepAndSanctionDetails: string | null = "";
  areaCountryCode: string = "";
  currencyCode: string = "";
  referenceUrl: string = "";
  phoneNumber: string = "";
  affiliateId: string = "";
  screenName: string = "";
  middleName: string = "";
  nationalId: string = "";
  firstName: string = "";
  birthDate: string = "";
  trackerId: string = "";
  lastName: string = "";
  areaCode: string = "";
  password: string = "";
  language: string = "";
  address1: string = "";
  bannerId: string = "";
  emailId: string = "";
  country: string = "";
  gender: "M" | "F" = "M";
  state: string = "";
  city: string = "";
  zip: string = "";

  // Booleans
  emailAndMobileSubscribed: boolean = false;
  termsNcondition: boolean = false;

  constructor(userData: RegilyRegistration) {
    this.emailAndMobileSubscribed = userData.marketing.consent;
    this.areaCode = userData.phonePrefix.replace("+", "");
    this.currencyCode = userData.currency.toUpperCase();
    this.termsNcondition = userData.terms.consent;
    this.areaCountryCode = userData.country;
    this.firstName = userData.firstname;
    this.birthDate = userData.birthdate;
    this.lastName = userData.lastname;
    this.phoneNumber = userData.phone;
    this.password = userData.password;
    this.language = userData.userLang;
    this.screenName = userData.email;
    this.country = userData.country;
    this.zip = userData.postalCode;
    this.emailId = userData.email;
    this.city = userData.city;

    this.state = userData.state
      ? userData.state.toUpperCase()
      : userData.province
      ? userData.province.toUpperCase()
      : "-NA-";

    this.middleName = "-NA-";

    if (userData.gender === Gender.male) {
      this.gender = "M";
    } else {
      this.gender = "F";
    }

    this.address1 = userData.address;

    this.bannerId = userData.bannerId;
    this.affiliateId = userData.affiliateId;
    this.trackerId = userData.trackerId;
    this.referenceUrl = userData.referenceUrl;

    if (userData.pepAndSanctionDetails) {
      this.pepAndSanctionDetails = JSON.stringify(
        userData.pepAndSanctionDetails
      );
    }

    if (userData.cpf) {
      this.nationalId = userData.cpf;
    }
  }
}
