import { EnvironmentsDetails } from "src/app/models/environments/environments.model";

let isEnabledBunnyCDN: boolean = false;

const bunnyURL: string = "https://cdn.casinofriday.com";

const MEDIA_URL: string = isEnabledBunnyCDN
  ? bunnyURL
  : "https://hpyhr-ps.imgix.net";

export const environment: EnvironmentsDetails = {
  production: false,
  // Site URL
  siteUrl: "https://stage-casinofriday.uatsecure.com",
  apiUrl: "https://stage-casinofriday.uatsecure.com",
  // Media URL
  mediaUrl: `${MEDIA_URL}`,
  mediaUrlPath: `${MEDIA_URL}/uploads/media/CAF`,
  bunnyPath: `${bunnyURL}/uploads/media/CAF`,
  // Game URL
  gamesUrlPath: `${MEDIA_URL}/uploads/games/CAF`,
  // Vimeo
  vimeoUrl: "https://player.vimeo.com/video/",
  // Website Code
  websiteCode: "CAF",
  websiteName: "Casinofriday",
  // Languages
  languagePath: "/languages/CAF/",
  defaultLanguage: "en-row",
  // Payments
  paymentIqUrl: "https://test-api.paymentiq.io/paymentiq",
  paymentIqMID: "100264999",
  paymentIqUID: "casinofriday",
  // Micro Gaming
  microGamingJackpotsUrl:
    "https://api32.gameassists.co.uk/casino/progressive/public/v1",
  // Live Gaming
  liveGameUrl: "prelive-dga.pragmaticplaylive.net",
  liveGameCasioID: "ppcdk00000004555",
  // Netent
  netentBrand: "oneupent",
  netentGameLiveServerUrl: "https://oneupent-livegame-test.casinomodule.com",
  netentGameStaticUrl: "https://oneupent-static-test.casinomodule.com",
  // Mix Panel Project Token
  mixPanelProjectToken: "80fc93f621b1883c55734d5d188a447b",
  // Default Currency
  defaultCurrencySymbol: "€",
  defaultCurrencyCode: "EUR",
  // Livespins URL
  livespinsApiUrl: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
  livespinsUiUrl: "https://livespins-stage.a.hpyhr.cloud",
  // Livespins Configurations
  livespins: {
    configurations: {
      language: "en",
      tenant: "CAF",
      serverConfig: {
        api: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
        ui: "https://stream-livespins-stage.a.hpyhr.cloud",
      },
    },
    shareURL: "/livespins/:sessionId/play",
  },
  // Geo Location API Key
  geoLocationAPIKey: "3d1bae96de9e40ad99ca1726e3748682",
  // Franky LandingPage
  frankyMarket: false,
  mobileMarket: true,
  vipMarket: true,
  thatFeelingMarket: true,
  jackpotMarket: true,
  rewardsMarket: true,
  // Features
  features: {
    hungarian: true,
    // Live Chat VIP
    liveChatVip: true,
    showCoolOffWithoutTime: true,
    showGeoLocationAPI: true,
    disabledCountries: false,
    enableFirebase: true,
    enableTermsAndConditions: true,
    firebaseOption: {
      // use: string | object
      use: "string",
      email: "dev@thewknd.io",
      token: ";?UI]O6kWfMmTJfCG)N]dFC%p$2%[#2"
    },
    showConsoles: {
      regily: false,
      socket: false,
    },
    testing: {
      quickDeposits: false,
      kyc: false,
    },
    liveCasino: {
      livespins: true,
    },
  },
  firebase:{
    apiKey: "AIzaSyDbBDN_OD1cQYke1nuxrg6MgWGK5KGyXEA",
    authDomain: "firelogger-6da1e.firebaseapp.com",
    databaseURL: "https://firelogger-6da1e-default-rtdb.firebaseio.com",
    projectId: "firelogger-6da1e",
    storageBucket: "firelogger-6da1e.appspot.com",
    messagingSenderId: "385280494993",
    appId: "1:385280494993:web:69e765206a9a59c1099e30",
    measurementId: "G-WBQ91N9JCC"
  }
};
