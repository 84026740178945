import { FormControl, FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

// Models
import { UpdateSubscriptionPreferenceResponse } from "src/app/modules/shared/models/preferences/update-subscription-preference-response.model";
import { UpdateSubscriptionPreferenceRequest } from "src/app/modules/shared/models/preferences/update-subscription-preference-request.model";
import { SubscriptionPreferenceResponse } from "src/app/modules/shared/models/preferences/subscription-preference-response.model";
import { LoaderSubscriptions } from "src/app/modules/shared/models/preferences/loader-subscriptions.model";
import { StatusResponse } from "src/app/models/api/status.response";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-preferences",
  templateUrl: "./preferences.component.html",
  styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent implements OnInit {
  // Strings
  errorMessageText: string = "";
  theme: string = "";

  // Form Groups
  subscriptionPreferenceForm: FormGroup;

  // Objects
  loader: LoaderSubscriptions = {
    emailSubscription: { disable: false, sub: false },
    mobileSubcription: { disable: false, sub: false },
  };

  constructor(
    private translationService: TranslationService,
    private accountService: AccountService,
    private utilityService: UtilityService,
    private formService: FormService
  ) {
    this.subscriptionPreferenceForm = new FormGroup({
      emailSubscribed: new FormControl({ value: false, disabled: false }),
      mobileSubscribed: new FormControl({ value: false, disabled: false }),
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.onGetSubscriptionPreference("");
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUpdateSubscriptionPreference(callingId: string): void {
    this.onTogglePreferenceLoader(callingId, true);

    let updateSubscriptionPreferenceRequest: UpdateSubscriptionPreferenceRequest =
      this.formService.transformFormToData<UpdateSubscriptionPreferenceRequest>(
        this.subscriptionPreferenceForm,
        {}
      );

    this.accountService
      .onUpdateSubscriptionPreference(updateSubscriptionPreferenceRequest)
      .subscribe(
        (
          updateSubscriptionPreferenceResponse: UpdateSubscriptionPreferenceResponse
        ) => {
          if (
            updateSubscriptionPreferenceResponse &&
            updateSubscriptionPreferenceResponse.status ===
              StatusResponse.SUCCESS
          ) {
            this.onGetSubscriptionPreference(callingId);
          } else {
            if (callingId === "emailSubscription") {
              this.onUpdateSubscriptionForm(
                !updateSubscriptionPreferenceResponse.emailSubscribed,
                updateSubscriptionPreferenceResponse.mobileSubscribed,
                callingId
              );
            } else {
              this.onUpdateSubscriptionForm(
                updateSubscriptionPreferenceResponse.emailSubscribed,
                !updateSubscriptionPreferenceResponse.mobileSubscribed,
                callingId
              );
            }

            this.errorMessageText = this.translationService.get(
              "preference.error_handle_msg"
            );

            setTimeout(() => {
              this.errorMessageText = undefined;
            }, 5000);
          }
        }
      );
  }

  onGetSubscriptionPreference(callingId?: string): void {
    this.onTogglePreferenceLoader(callingId, true);

    this.accountService
      .onSubscriptionPreference()
      .subscribe(
        (subscriptionPreferenceResponse: SubscriptionPreferenceResponse) => {
          if (
            subscriptionPreferenceResponse &&
            subscriptionPreferenceResponse.status == "SUCCESS"
          ) {
            this.onUpdateSubscriptionForm(
              subscriptionPreferenceResponse.emailSubscribed,
              subscriptionPreferenceResponse.mobileSubscribed,
              callingId
            );
          } else {
            this.errorMessageText = this.translationService.get(
              "preference.error_handle_msg"
            );

            setTimeout(() => {
              this.errorMessageText = undefined;
            }, 5000);
          }
        }
      );
  }

  onUpdateSubscriptionForm(
    emailStatus: boolean,
    mobileStatus: boolean,
    callingId?: string
  ): void {
    this.subscriptionPreferenceForm.controls["emailSubscribed"].setValue(
      emailStatus
    );

    this.subscriptionPreferenceForm.controls["mobileSubscribed"].setValue(
      mobileStatus
    );

    if (callingId) {
      this.onTogglePreferenceLoader(callingId, false);
    }
  }

  onTogglePreferenceLoader(callingId: string, isEnable: boolean): void {
    let controlName: string = "";

    if (callingId == "emailSubscription") {
      controlName = "emailSubscribed";
    } else if (callingId == "mobileSubcription") {
      controlName = "mobileSubscribed";
    }

    if (isEnable && callingId) {
      this.loader[callingId].sub = true;

      this.loader[callingId].disable = true;

      this.subscriptionPreferenceForm.controls[controlName].disable();
    } else if (callingId) {
      this.loader[callingId].sub = false;

      this.loader[callingId].disable = false;

      this.subscriptionPreferenceForm.controls[controlName].enable();
    }
  }
}
