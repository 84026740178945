import { Observable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from "@angular/router";

// Models
import { QueryParams } from "src/app/modules/shared/models/parameters.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";

@Injectable()
export class AuthGaurd implements CanActivate {
  // Strings
  languageCode: string = "";

  // Subscriptions
  subscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Can Activate
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.getIsUserVisitedLandingPage();
  }

  // -----------------------------------------------------------------
  // Can Activate Child
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.getIsUserVisitedLandingPage();
  }

  // -----------------------------------------------------------------
  // Can Activate with Params
  canActivateWithParams(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let result: boolean = false;

    if (this.sessionService.getIsUserLoggedIn()) {
      result = true;
    } else {
      this.router.navigate([this.languageCode]);

      result = false;
    }

    return result;
  }

  // -----------------------------------------------------------------
  // Get Methods
  getIsUserVisitedLandingPage(): boolean {
    const path: string = this.utilityService.getDecodedCurrentPath();

    const params: QueryParams = getQueryParams();

    const regfirst: RegExp = /^[a-z]{2}-[a-z]{2}$/;

    const regSecond: RegExp = /^[a-z]{2}-[a-z]{3}$/;

    let languageCodeFromURL: string = this.utilityService
      .getDecodedCurrentPath()
      .split("/")[1];

    if (
      path &&
      (path.length === 1 ||
        regfirst.test(languageCodeFromURL) ||
        regSecond.test(languageCodeFromURL))
    ) {
      if (this.sessionService.getIsUserLoggedIn()) {
        const language: string = languageCodeFromURL
          ? languageCodeFromURL
          : this.languageCode;

        this.router.navigate([`${language}/casino`], { queryParams: params });

        return false;
      }

      return true;
    }

    return true;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
