import { createFeatureSelector, createSelector } from "@ngrx/store";

// Reducers
import {
  BannerStateFeatureKey,
  BannerReducerState,
} from "src/app/modules/banner/store/reducers/banners.reducer";

// Utilities
import {
  bannerZoneMappersLiveCasinoList,
  bannerZoneMappersCasinoList,
} from "src/app/modules/banner/utilities/banner-zone-mappers.utilities";

// Select the Main State
const selectBannerReducerState = createFeatureSelector<BannerReducerState>(
  BannerStateFeatureKey
);

// Get Banners List
export const getBannerList = createSelector(
  selectBannerReducerState,
  (state: BannerReducerState) => state.bannerList
);

// Get A Single Banner
export const getBanner = (props: { zoneId: string }) =>
  createSelector(selectBannerReducerState, (state: BannerReducerState) => {
    let zoneId: string = props.zoneId;

    if (bannerZoneMappersCasinoList.includes(props.zoneId)) {
      zoneId = "b_casino";
    }

    if (bannerZoneMappersLiveCasinoList.includes(props.zoneId)) {
      zoneId = "b_all-live";
    }

    if (state && state.bannerList.hasOwnProperty(zoneId)) {
      const { banners, isLoaded, isLoading } = state.bannerList[zoneId];

      return {
        isLoading,
        isLoaded,
        banners,
      };
    } else {
      return {
        isLoading: true,
        isLoaded: false,
        banners: [],
      };
    }
  });
