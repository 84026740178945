// Models
import { FeelingIconsItem } from "src/app/modules/shared/models/landing-pages/feeling-icons-item.model";
import { FeelingIconsPage } from "src/app/modules/shared/models/landing-pages/feeling-icons-page.model";

export const feelingIconsConfigurations = (
  languageCode: string
): FeelingIconsItem => {
  let landing: FeelingIconsPage = {
    "en-row": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "en-ca": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "es-sa": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "fi-fi": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "hu-hu": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "ja-jp": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "nb-no": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "en-nz": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "pt-br": {
      cashbackIcon: "cb-brazil.svg",
      accountIcon: "personal-am-brazil.svg",
      bonusIcon: "bonuses-brazil.svg",
    },
    "de-de": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
    "en-eu": {
      cashbackIcon: "cb.svg",
      accountIcon: "personal-am.svg",
      bonusIcon: "bonuses.svg",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    cashbackIcon: landing[languageCode].cashbackIcon,
    accountIcon: landing[languageCode].accountIcon,
    bonusIcon: landing[languageCode].bonusIcon,
  };
};
