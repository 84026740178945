import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  NavigationStart,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  NavigationEnd,
  RouterEvent,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";
import {
  ViewEncapsulation,
  HostListener,
  Component,
  OnInit,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Models
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-bottom-navigation",
  templateUrl: "./bottom-navigation.component.html",
  styleUrls: ["./bottom-navigation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BottomNavigationComponent implements OnInit {
  // Numbers
  userTotalBalance: number = 0;

  // Strings
  currencySymbol: string = "";
  languageCode: string = "";
  activePage: string = "";

  // Booleans
  isBalanceLoading: boolean = true;
  isFavouriteGame: boolean = false;
  isSideMenuOpen: boolean = false;
  isLoggedIn: boolean = false;
  isGamePlay: boolean = false;
  isQuickDeposit = false;

  // Enums
  windowType: "mobile" | "device" = "device";

  // Timeout
  timeout: NodeJS.Timer;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.onActivePageHandler(this.utilityService.getDecodedCurrentPath());

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.router.events.subscribe((event: RouterEvent) => {
        /*
          we clear game play data here when user navigate out from gamepaly window
          to some other route path with click on minimize options  
        */
        if (event instanceof NavigationStart) {
          if (
            event.url &&
            !event.url.includes(
              `/${this.translationService.get("url.game")}`
            ) &&
            !this.gamePlayService.isGameMinimized
          ) {
            if (!_.isEmpty(this.gamePlayService.getCurrentGamePregmatic())) {
              this.gamePlayService.onBroadCastGameLaunch({});
            }
          }
        }

        if (event instanceof NavigationEnd) {
          this.onActivePageHandler(event.url);
        }
      }),
      /*
        Because we need this active page check handler only when naivagtion changes
        but we have a case when user is in game play window switches from destop top to mobile & visa versa
        when this happens bottom navigation component getting toggles(hide & show)
        because of this activepage gets undefined & UI is not updating
        we have differnt UI based on this flag
      */
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;

            this.isBalanceLoading = true;
          }
        }),
      /*
        Here we are using BehaviorSubject on initialization also v'll get default value..
        we differentiate subject & BehaviorSubject with "Sb" at end of Event Name. 
      */
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(
        (balanceUpdateMessage: string) => {
          if (balanceUpdateMessage == "UserBalanceUpdated") {
            this.userTotalBalance =
              this.userDetailsService.getUserTotalBalance();

            this.isBalanceLoading = false;
          }
        }
      ),
      this.gamePlayService.updateIsFavouriteGameSubject$.subscribe(
        (isFavouriteGame: boolean) => {
          this.isFavouriteGame = isFavouriteGame;
        }
      ),
      this.commonService.quickDepositToggle$.subscribe(
        (isQuickDeposit: boolean) => {
          this.isQuickDeposit = isQuickDeposit;
        }
      ),
    ];

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();

      this.isBalanceLoading = false;
    }

    this.onSetBottomNavHeightInMobileLandscape();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();

    this.onSetBottomNavHeightInMobileLandscape();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onActivePageHandler(url: string): void {
    const tree: UrlTree = this.router.parseUrl(url);

    const urlSegmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];

    const urlSegment: UrlSegment[] = urlSegmentGroup
      ? urlSegmentGroup.segments
      : [];

    if (urlSegment && urlSegment[1] && urlSegment[1].path) {
      this.activePage = this.multiLanguageService.getActiveLobby(
        urlSegment[1].path
      );

      if (this.activePage === "game" || this.activePage === "livespins") {
        this.isGamePlay = true;
      } else {
        this.isGamePlay = false;
      }
    } else {
      this.activePage = "";

      this.isGamePlay = false;
    }
  }

  onOpenAccountMenuList(): void {
    this.onGoOutFullScreen();

    const activeTab: ActiveTab = {
      tabName: "menuOptions",
      showBackButton: true,
    };

    this.utilityService.openAccountComponent(activeTab);
  }

  onOpenAccountComponent(viewName: string): void {
    this.commonService.broadcastQuickDepositToggle(false);

    const activeTab: ActiveTab = {
      tabName: viewName,
      showBackButton: false,
    };

    this.utilityService.openAccountComponent(activeTab);
  }

  onOpenLogin(): void {
    const activeTab: ActiveTab = {
      tabName: "login",
      showBackButton: true,
    };

    this.commonService.onBroadcastActiveAcountView(activeTab);
  }

  onOpenRegistrationPopup(): void {
    this.registrationService.onOpenRegistration();
  }

  onGoOutFullScreen(): void {
    this.utilityService.exitFullScreen();
  }

  onMakeMinimizedGame(): void {
    this.gamePlayService.onBroadCastGameWindowMinimized(true);

    this.onGoOutFullScreen();

    this.timeout = setTimeout(() => {
      this.router.navigate([`${this.languageCode}/casino`]);
    }, 200);
  }

  onOpenQuickDeposit(): void {
    this.isQuickDeposit = true;

    this.commonService.broadcastQuickDepositToggle(true);
  }

  onSetBottomNavHeightInMobileLandscape(): void {
    if (window.matchMedia("(orientation: landscape)").matches) {
      let landscapeScreenHeight: number = window.innerHeight;

      document.documentElement.style.setProperty(
        "--landscapeScreenHeight",
        `${landscapeScreenHeight}px`
      );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.timeout) clearTimeout(this.timeout);

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
