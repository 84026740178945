import { forkJoin, Observable, of, Subscription } from "rxjs";
import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { FilteredGamesBy } from "src/app/modules/game-groups/models/games/filtered-games-by.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectLastPlayedState,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-live-casino-play-again",
  templateUrl: "./live-casino-play-again.component.html",
  styleUrls: ["./live-casino-play-again.component.scss"],
})
export class LiveCasinoPlayAgainComponent implements OnDestroy {
  // Strings
  gamesUrlPath: string = environment.gamesUrlPath;
  languageCode: string = "";
  imgixParams: string = "";
  theme: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  // Arrays
  lastPlayedLiveGamesList: GamePregmatic[] = [];
  lastPlayedGamesList: GamePregmatic[] = [];

  // Others
  filterByData: FilteredGamesBy = { typeOfGames: "live-game" };

  // Timeouts
  timeout: number = 0;

  // Subscriptions
  gameSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private gamesFilterPipe: GamesFilterPipe,
    private gamePlayService: GamePlayService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetLastPlayedGames();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.theme = this.utilityService.setLocaleBasedTheme();
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetLastPlayedGames(): void {
    this.lastPlayedGamesList = [];

    let groupGamesApi: Observable<GamePregmatic[]> =
      this.store.select(selectAllGames);

    let lastPlayedGamesApi: Observable<number[]> = this.store.select(
      selectLastPlayedState
    );

    this.gameSubscription = forkJoin({
      gamesResponse: groupGamesApi,
      lastPlayedResponse: lastPlayedGamesApi ? lastPlayedGamesApi : of(null),
    }).subscribe(
      (response: {
        gamesResponse: GamePregmatic[];
        lastPlayedResponse?: number[];
      }) => {
        let gamesList: GamePregmatic[] = response.gamesResponse;

        let lastPlayedGameIds: number[] = response.lastPlayedResponse;

        if (
          gamesList &&
          gamesList.length > 0 &&
          lastPlayedGameIds &&
          lastPlayedGameIds.length > 0
        ) {
          _.each(lastPlayedGameIds, (typeId: number) => {
            let game: GamePregmatic = _.filter(gamesList, {
              beGameTypeId: typeId,
            })[0];

            if (game) {
              this.lastPlayedGamesList.push(game);
            }
          });

          this.onApplyLiveCasinoFilter();
        } else {
          this.lastPlayedLiveGamesList = [];
        }

        this.isLoading = false;
      }
    );
  }

  onApplyLiveCasinoFilter(): void {
    if (this.lastPlayedGamesList.length > 0) {
      this.lastPlayedLiveGamesList = this.gamesFilterPipe.transform(
        this.lastPlayedGamesList,
        this.filterByData
      );

      if (
        this.lastPlayedLiveGamesList &&
        this.lastPlayedLiveGamesList.length > 0
      ) {
        this.gameGroupsService.onLastPlayedLiveCasinoGameAvailable(true);
      }
    }
  }

  onGameLaunch(gameName: string): void {
    if (environment.features.liveCasino.livespins) {
      if (gameName == "Bet Behind") {
        this.router.navigate([`${this.languageCode}/livespins`]);
        return;
      }
    }

    gameName = this.utilityService.convertGameNameToUrl(gameName);

    this.gamePlayService.onClearGameWindowData();

    this.timeout = setTimeout(() => {
      if (gameName && this.isLoggedIn) {
        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.game"
          )}/${gameName}`,
        ]);
      } else {
        const activeTab: ActiveTab = {
          tabName: "login",
          showBackButton: true,
        };

        this.commonService.onBroadcastActiveAcountView(activeTab);

        this.commonService.onSetNavigateAfterLogin({
          type: "url",
          data: { navigateTo: "gameWindow", path: gameName },
        });
      }
    });
  }

  onViewAllHandler(): void {
    this.commonService.onBroadcastNavigationData({ lobby: "play-again" });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.gameSubscription) this.gameSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    if (this.timeout) clearTimeout(this.timeout);
  }
}
