import { Action, createReducer, on } from "@ngrx/store";

// Actions
import {
  loginStatusRequested,
  loginStatusLoaded,
  loginStatusError,
  logoutRequested,
  loginRequested,
  loggedInStatus,
  logoutLoaded,
  loginLoaded,
  logoutError,
  loginError,
} from "src/app/modules/auth/store/actions/auth.actions";

// Models
import { LogoutResponse } from "src/app/modules/auth/models/logout/logout-response.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

export const AuthStateFeatureKey = "auth";

// Reducer States
export interface AuthReducerState {
  loggedIn: LoggedIn;
  isLoaded: boolean;
  isLoading: boolean;
  loginError: string;

  isLoggedIn: boolean;
  isLoggedOut: boolean;

  loginStatus: LoginStatus;
  isLoadedLoginStatus: boolean;
  isLoadingLoginStatus: boolean;
  loginErrorLoginStatus: string;
  isLoginStatusRequested: boolean;

  logout: LogoutResponse;
  isLoadedLogout: boolean;
  isLoadingLogout: boolean;
  loginErrorLogout: string;

  isSessionExpired: boolean;
}

export const initialState: AuthReducerState = {
  // Login
  loggedIn: null,
  isLoaded: false,
  isLoading: false,
  loginError: null,

  // is Logged In
  isLoggedIn: false,
  isLoggedOut: false,

  // Login Status
  loginStatus: null,
  isLoadedLoginStatus: false,
  isLoadingLoginStatus: false,
  loginErrorLoginStatus: null,
  isLoginStatusRequested: true,

  // Logout
  logout: null,
  isLoadedLogout: false,
  isLoadingLogout: false,
  loginErrorLogout: null,

  // Session Expired
  isSessionExpired: false,
};

// Reducer
const AuthReducer = createReducer(
  initialState,
  // Login
  on(loginRequested, (state: AuthReducerState) => {
    return {
      ...state,
      loggedIn: null,
      isLoading: true,
      isLoaded: false,
      loginError: null,

      logoutResponse: null,
      isLoadingLogout: false,
      isLoadedLogout: false,
      loginErrorLogout: null,
    };
  }),
  on(loginLoaded, (state: AuthReducerState, { loggedIn }) => {
    return {
      ...state,
      loggedIn,
      isLoading: false,
      isLoaded: true,
      loginError: null,
      isLoggedOut: !loggedIn,
    };
  }),
  on(loginError, (state: AuthReducerState) => {
    return {
      ...state,
      loggedIn: null,
      isLoading: false,
      isLoaded: false,
      loginError: "Error occurred whilst retrieving Logging in",
    };
  }),
  // Login In Status
  on(loggedInStatus, (state: AuthReducerState, { isLoggedIn }) => {
    return {
      ...state,
      isLoggedIn,
      isLoggedOut: !isLoggedIn,
    };
  }),
  // Login Status
  on(loginStatusRequested, (state: AuthReducerState) => {
    return {
      ...state,
      loginStatus: null,
      isLoadingLoginStatus: true,
      isLoadedLoginStatus: false,
      loginErrorLoginStatus: null,
      isLoginStatusRequested: true,
    };
  }),
  on(loginStatusLoaded, (state: AuthReducerState, { loginStatus }) => {
    return {
      ...state,
      loginStatus,
      isLoadingLoginStatus: false,
      isLoadedLoginStatus: true,
      loginErrorLoginStatus: null,
      isLoginStatusRequested: false,
    };
  }),
  on(loginStatusError, (state: AuthReducerState) => {
    return {
      ...state,
      loginStatus: null,
      isLoadingLoginStatus: false,
      isLoadedLoginStatus: false,
      loginErrorLoginStatus: "Error occurred whilst retrieving Login Status",
    };
  }),
  // Logout
  on(logoutRequested, (state: AuthReducerState) => {
    return {
      ...state,
      isLoggedOut: false,
      logoutResponse: null,
      isLoadingLogout: true,
      isLoadedLogout: false,
      loginErrorLogout: null,
    };
  }),
  on(
    logoutLoaded,
    logoutRequested,
    (state: AuthReducerState, { logoutResponse }) => {
      return {
        ...state,
        // Login
        loggedIn: null,
        isLoading: false,
        isLoaded: false,
        loginError: null,

        isLoggedIn: false,
        isLoggedOut: true,

        // Login Status
        loginStatus: null,
        isLoadingLoginStatus: false,
        isLoadedLoginStatus: false,
        loginErrorLoginStatus: null,

        // Logout
        logoutResponse,
        isLoadingLogout: false,
        isLoadedLogout: true,
        loginErrorLogout: null,

        // If the session is expired from Pregmatic, we need to show the modal session expired
        isSessionExpired: logoutResponse
          ? logoutResponse.isSessionExpired
          : false,
      };
    }
  ),
  on(logoutError, (state: AuthReducerState) => {
    return {
      ...state,
      logoutResponse: null,
      isLoadingLogout: false,
      isLoadedLogout: false,
      loginErrorLogout: "Error occurred whilst retrieving Logout",
    };
  })
);

export function reducer(state: AuthReducerState | undefined, action: Action) {
  return AuthReducer(state, action);
}
