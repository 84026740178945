import { Component } from "@angular/core";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent {
  constructor(private registerationService: RegistrationService) {}

  // -----------------------------------------------------------------
  // Set Methods
  onOpenRegisterationPopup(): void {
    this.registerationService.onOpenRegistration();
  }
}
