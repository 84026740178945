import { Component, EventEmitter, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Configurations
import { livespinsExcludedMarketsConfigurations } from "src/app/modules/livespins/configurations/livespins.configurations";
import {
  liveProviderCheckListPreferredOrderConfigurations,
  mixPanelEventsConfigurations,
} from "src/app/configurations/main.configurations";

// Models
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";

// Services
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-provider-filter",
  templateUrl: "./provider-filter.component.html",
  styleUrls: ["./provider-filter.component.scss"],
})
export class ProviderFilterComponent {
  // Outputs
  @Output() callOnProvidersChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  // Strings
  languageCode: string = "";
  theme: string = "";

  // Booleans
  isLoading: boolean = true;

  // Arrays
  excludedMarketList: string[] = livespinsExcludedMarketsConfigurations;
  checkedProviderNamesList: string[] = [];
  liveProviderList: GameProviders[] = [];

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private gamesFilterPipe: GamesFilterPipe,
    private mixPanelService: MixPanelService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onPrepareLiveProviderList();

    this.theme = this.utilityService.setLocaleBasedTheme();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getPreferredSortedOrder(providerList: GameProviders[]): GameProviders[] {
    const providerPreferredOrder: string[] =
      liveProviderCheckListPreferredOrderConfigurations;

    return providerList.sort(
      (object1: GameProviders, object2: GameProviders) => {
        return (
          providerPreferredOrder.indexOf(object1.vendorCode) -
          providerPreferredOrder.indexOf(object2.vendorCode)
        );
      }
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
    ];
  }

  onPrepareLiveProviderList(): void {
    this.subscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        this.isLoading = false;

        if (gamesList && gamesList.length > 0) {
          gamesList = this.gamesFilterPipe.transform(gamesList, {
            typeOfGames: "live-game",
          });

          if (this.excludedMarketList.includes(this.languageCode)) {
            gamesList = gamesList.filter(
              (game: GamePregmatic) => game.gameCode !== "livespinsBetBehind"
            );
          }

          this.liveProviderList = this.getPreferredSortedOrder(
            this.utilityService.getProviderList(gamesList)
          );
        } else {
          this.liveProviderList = [];
        }
      });
  }

  onChangeSelection(providerName: string, status: boolean): void {
    this.checkedProviderNamesList = [];

    this.liveProviderList.forEach((value: GameProviders) => {
      if (!value.isChecked) {
        this.checkedProviderNamesList.push(value.vendorCode);
      }
    });

    this.onSendMixPanelEvent(providerName, status);

    this.callOnProvidersChanged.emit(this.checkedProviderNamesList);
  }

  onSendMixPanelEvent(providerName: string, status: boolean): void {
    this.mixPanelService.onTrackMixPanelEvents(
      mixPanelEventsConfigurations.clicked_Provider_Filter,
      {
        name: providerName,
        isClicked: status,
      }
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
