// Environments
import { environment } from "src/environments/environment";

// Models
import { LandingFeelings } from "src/app/modules/shared/models/landing-pages/landing-feeling.model";
import { FeelingPage } from "src/app/modules/shared/models/landing-pages/feeling-page.model";

export const feelingConfigurations = (languageCode: string): FeelingPage => {
  let landing: LandingFeelings = {
    "en-row": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_en-row.png",
    },
    "en-ca": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_en-ca.png",
    },
    "es-sa": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_es-sa.png",
    },
    "fi-fi": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_fi-fi.png",
    },
    "hu-hu": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: false,
      rightDesktop: "thatfeel_hu-hu.png",
    },
    "ja-jp": {
      enableLeft: true,
      leftDesktop: "cat_thatfeel_ja-jp.png",
      enableRight: true,
      rightDesktop: "thatfeel_ja-jp.png",
    },
    "nb-no": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_nb-no.png",
    },
    "en-nz": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_en-nz.png",
    },
    "pt-br": {
      enableLeft: true,
      leftDesktop: "frankie-brazil.png",
      enableRight: false,
      rightDesktop: "",
    },
    "de-de": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_de-de.png",
    },
    "en-eu": {
      enableLeft: false,
      leftDesktop: "",
      enableRight: true,
      rightDesktop: "thatfeel_de-de.png",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    enableLeft: environment.thatFeelingMarket
      ? landing[languageCode].enableLeft
        ? landing[languageCode].enableLeft
        : false
      : false,
    leftDisplay: environment.thatFeelingMarket
      ? landing[languageCode].enableLeft
        ? landing[languageCode].leftDesktop
        : ""
      : "",
    enableRight: environment.thatFeelingMarket
      ? landing[languageCode].enableRight
        ? landing[languageCode].enableRight
        : false
      : false,
    rightDisplay: environment.thatFeelingMarket
      ? landing[languageCode].enableRight
        ? landing[languageCode].rightDesktop
        : ""
      : "",
  };
};
