import { BehaviorSubject, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import {
  addScriptDynamically,
  LivespinsSDKClient,
  LivespinsSDK,
} from "livespins/@livespins";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { SessionService } from "src/app/modules/auth/services/session.service";

@Injectable({
  providedIn: "root",
})
export class LivespinsService {
  // Booleans
  isInitStarted: boolean = false;

  // Others
  public static langCodeToLivespinsLanguageCode: Record<string, string> = {
    "en-row": "en",
    "en-ca": "en",
    "en-nz": "en",
    "en-eu": "en",
    "de-de": "de",
    "nb-no": "no",
    "fi-fi": "fi",
  };

  // Subjects
  clientSubject: BehaviorSubject<LivespinsSDKClient | null> =
    new BehaviorSubject<LivespinsSDKClient | null>(null);
  sdkSubject: BehaviorSubject<LivespinsSDK | null> =
    new BehaviorSubject<LivespinsSDK | null>(null);

  // Subscriptions
  subscription: Subscription;

  constructor(
    private sessionService: SessionService,
    private store: Store<AppState>
  ) {}

  // ----------------------------------------------------------
  // Get Methods
  get client(): LivespinsSDKClient | null {
    return this.clientSubject.value;
  }

  get sdk(): LivespinsSDK | null {
    return this.sdkSubject.value;
  }

  // ----------------------------------------------------------
  // Set Methods
  onInitialiseLivespinsSDK(): void {
    // This can be called multiple times, but we only need to initialise the
    // client once.
    this.isInitStarted = true;

    document.addEventListener("LIVESPINS_LOADED", () => {
      console.log("Livespins SDK module ready to be used 💪", window.livespins);

      const livespinsSDK: LivespinsSDK = window.livespins.sdk({
        ...environment.livespins.configurations,
      });

      this.sdkSubject.next(livespinsSDK);

      livespinsSDK.Client().then((client: LivespinsSDKClient) => {
        console.log({ client });

        this.clientSubject.next(client);
      });
    });

    addScriptDynamically("https://assets.livespins.com/sdk/js/livespins.js");
  }

  onInitialise(): void {
    if (!this.isInitStarted && this.sessionService.getIsUserLoggedIn()) {
      this.onInitialiseLivespinsSDK();
    }

    this.subscription = this.store
      .select(selectAuthLoginIsLoggedIn)
      .subscribe((isLoggedIn: boolean) => {
        if (!this.isInitStarted && isLoggedIn) {
          this.onInitialiseLivespinsSDK();
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
