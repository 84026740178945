import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  AfterViewInit,
  HostListener,
  Component,
  OnDestroy,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Models
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-provider-games-display",
  templateUrl: "./provider-games-display.component.html",
  styleUrls: ["./provider-games-display.component.scss"],
})
export class ProvideGamesDisplay implements AfterViewInit, OnDestroy {
  // Strings
  activeGroupName: string = "";
  providerName: string = "";
  languageCode: string = "";
  activeLobby: string = "";
  sortedBy: string = "";

  // Booleans
  isPageHeaderBg: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  // Enums
  deviceType: "desktop" | "mobile" = "desktop";

  // Arrays
  gamesBeforeSortedList: GamePregmatic[] = [];
  filteredGamesList: GamePregmatic[] = [];
  providersList: GameProviders[] = [];
  gamesList: GamePregmatic[] = [];

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private activateRoute: ActivatedRoute,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.onUpdateSEOContent();

    this.onSetSEOContent();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.activateRoute.params.subscribe((params: Params) => {
        if (params && params.providerName) {
          this.providerName = params.providerName;

          this.onGetGroupGames(false);
        }
      }),
    ];
  }

  ngAfterViewInit(): void {
    this.getWindowType();

    this.onOverflowHiddenClass();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:scroll")
  onScroll(): void {
    this.isPageHeaderBg = this.utilityService.scrollAddClass();
  }

  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();

    this.onOverflowHiddenClass();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetGroupGames(isForce: boolean): void {
    this.isLoading = true;

    this.subscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = gamesList;

          this.onGamesInProvider(this.providerName);
        } else {
          this.isLoading = false;

          this.filteredGamesList = [];
        }
      });
  }

  onGamesInProvider(providerName: string): void {
    let selectedProviderList: GameProviders[] = [];

    if (providerName && this.gamesList) {
      this.providersList = this.utilityService.getProviderList(this.gamesList);

      if (this.providersList) {
        selectedProviderList = _.filter(
          this.providersList,
          (provider: GameProviders) => {
            if (
              this.utilityService.convertSpecialCharactersWithSpace(
                provider.vendorDisplayName
              ) ===
              this.utilityService.convertSpecialCharactersWithSpace(
                providerName
              )
            ) {
              this.providerName = provider.vendorDisplayName;

              return true;
            }
          }
        );

        if (!_.isEmpty(selectedProviderList)) {
          this.filteredGamesList =
            this.utilityService.getGamesListByProviderName(
              this.gamesList,
              selectedProviderList[0].vendorCode
            );

          this.gamesBeforeSortedList = this.filteredGamesList;
        }
      }
    }

    if (
      !this.filteredGamesList ||
      (this.filteredGamesList && this.filteredGamesList.length === 0)
    ) {
      this.filteredGamesList = [];
    }

    this.isLoading = false;
  }

  onOverflowHiddenClass(): void {
    setTimeout(() => {
      if (
        document.querySelector(".page-body") &&
        this.deviceType === "mobile"
      ) {
        this.utilityService.onAddClassToAppBody("overflow-hidden");
      } else {
        this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
      }
    });
  }

  onSortByGameName(): void {
    if (this.sortedBy !== "a-z") {
      this.sortedBy = "a-z";

      this.filteredGamesList = this.utilityService.getSortedGamesByGameName(
        this.filteredGamesList,
        "name"
      );
    } else {
      this.sortedBy = undefined;

      this.filteredGamesList = this.gamesBeforeSortedList;
    }
  }

  onGoBack(): void {
    if (!this.commonService.previousURL) {
      this.router.navigate([this.multiLanguageService.getLanguageCode()]);
    } else {
      this.router.navigate([this.commonService.previousURL]);
    }

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onUpdateSEOContent(): void {
    let segments: string[] = this.utilityService
      .getDecodedCurrentPath()
      .split("/");

    if (segments && segments.length === 4) {
      let providerName: string = segments[3].replace(" ", "-");

      this.utilityService.setSEO({
        metaTitle: this.translationService.get(`SEO.${providerName}_metaTitle`),
        metaDescription: this.translationService.get(
          `SEO.${providerName}_metaDescription`
        ),
        metaTags: this.translationService.get(`SEO.${providerName}_metaTag`),
        title: this.utilityService.capitalizeFirstLetter(providerName),
      });
    }
  }

  onSetSEOContent(): void {
    this.multiLanguageService.onSetCanonicalURL("studiopage");
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");

    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
