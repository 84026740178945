import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor() {}

  // -----------------------------------------------------------------
  // Get Methods
  transformFormToData<T>(formGroup: FormGroup, data?: T): T {
    Object.keys(formGroup.controls).forEach((field: string) => {
      const control: AbstractControl = formGroup.get(field);

      if (control instanceof FormControl) {
        data[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.transformFormToData<T>(control, data);
      }
    });

    return data;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onResetFormFields(formGroup: FormGroup, isForceReset?: boolean): void {
    if (isForceReset) {
      formGroup.reset();
    } else {
      Object.keys(formGroup.controls).forEach((field: string) => {
        const control: AbstractControl = formGroup.get(field);

        if (control instanceof FormControl) {
          control.setValue(null);
        } else if (control instanceof FormGroup) {
          this.onResetFormFields(control);
        }
      });
    }
  }

  onRemoveAllFormFieldsValidation(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field: string) => {
      const control: AbstractControl = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });

        control.markAsPristine({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onRemoveAllFormFieldsValidation(control);
      }
    });
  }
}
