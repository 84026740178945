import { environment } from "src/environments/environment";

export const supportedMarketsList = (): string[] => {
  let languages: string[] = [
    "en-row",
    "nb-no",
    "fi-fi",
    "en-ca",
    "en-nz",
    "en-eu",
    "ja-jp",
    "de-de",
    "pt-br",
    "es-sa",
  ];

  if (environment.features.hungarian) {
    languages = [...languages, "hu-hu"];
  }

  return languages;
};

export const supportedLanguagesList = (): string[] => {
  let languages: string[] = [
    "en",
    "no",
    "fi",
    "cd",
    "nz",
    "nl",
    "ja",
    "de",
    "pt",
    "es",
  ];

  if (!environment.features.hungarian) {
    languages = [...languages, "hu"];
  }

  return languages;
};
