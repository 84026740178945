// Environments
import { environment } from "src/environments/environment";

// Models
import { LandingRewards } from "src/app/modules/shared/models/landing-pages/landing-rewards.model";
import { RewardsPage } from "src/app/modules/shared/models/landing-pages/rewards-page.model";

export const rewardsConfigurations = (languageCode: string): RewardsPage => {
  let landing: LandingRewards = {
    "en-row": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_en-row.png",
      rewardsMobile: "rewards-desktop_en-row.png",
    },
    "en-ca": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_en-ca.png",
      rewardsMobile: "rewards-desktop_en-ca.png",
    },
    "es-sa": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_es-sa.png",
      rewardsMobile: "rewards-desktop_es-sa.png",
    },
    "fi-fi": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_fi-fi.png",
      rewardsMobile: "rewards-desktop_fi-fi.png",
    },
    "hu-hu": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_hu-hu.png",
      rewardsMobile: "rewards-desktop_hu-hu.png",
    },
    "ja-jp": {
      isDifferentRewards: true,
      rewardsDesktop: "bonus-ja-desktop.png",
      rewardsMobile: "bonus-ja-desktop-mobile.png",
    },
    "nb-no": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_nb-no.png",
      rewardsMobile: "rewards-desktop_nb-no.png",
    },
    "en-nz": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_en-nz.png",
      rewardsMobile: "rewards-desktop_en-nz.png",
    },
    "pt-br": {
      isDifferentRewards: true,
      rewardsDesktop: "bonus-image-br-desktop.png",
      rewardsMobile: "bonus-image-br-desktop.png",

     // rewardsDesktop: "bonus-ja-desktop.png",
     // rewardsMobile: "bonus-ja-desktop-mobile.png",
    },
    "de-de": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_de-de.png",
      rewardsMobile: "rewards-desktop_de-de.png",
    },
    "en-eu": {
      isDifferentRewards: false,
      rewardsDesktop: "rewards-desktop_de-de.png",
      rewardsMobile: "rewards-desktop_de-de.png",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    rewardsDesktop: landing[languageCode].rewardsDesktop,
    isDifferentRewards: landing[languageCode].isDifferentRewards,
    rewardsMobile: environment.rewardsMarket
      ? landing[languageCode].isDifferentRewards
        ? landing[languageCode].rewardsMobile
        : landing[languageCode].rewardsMobile
      : landing[languageCode].rewardsDesktop,
  };
};
