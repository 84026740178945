import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Libraries
import * as _ from "underscore";

@Injectable()
export class MainService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Get User Data
  onProfileUpdate(request): Observable<any> {
    return this.apiInteractor.post<any, any>(`/ajax/profile/update`, request);
  }

  onGetProfileCountryDetails(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/profile/getcountryDetails`);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Account
  onGetAccountVerificationStatus(): Observable<any> {
    return this.apiInteractor.get<null, any>(
      `/ajax/profile/getAccountVerificationStatus`
    );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Zendesk Token
  onGetZendeskToken(): Observable<any> {
    return this.apiInteractor.get<null, any>(`/ajax/profile/getZendeskToken`);
  }

  // -----------------------------------------------------------------
  // Promises
  // -----------------------------------------------------------------
  // Get Methods - Promises

  getPromotions(requestObject): Observable<any> {
    return this.apiInteractor.get<any, any>(
      `/ajax/promotion/getPromotions`,
      requestObject
    );
  }

  getFooterContent(requestObj): Observable<any> {
    return this.apiInteractor.get<any, any>(
      `/ajax/staticPage/getPage`,
      requestObj
    );
  }

  getAllPromotions(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/promotion/getPromotions`);
  }

  /***********promise call ********** */

  getZendeskRubikoDetails(userName: string): Observable<any> {
    let url: string = `https://zdapi.rubiko.tech/tracking?user=${encodeURIComponent(
      userName
    )}&page=${encodeURIComponent(window.location.href)}`;

    return this.apiInteractor.get(url);
  }

  deleteNetDepositLimits(requestObj): Observable<any> {
    return this.apiInteractor.post<any, any>(
      `/ajax/ResponsibleGaming/deleteNetDepositLimit`,
      requestObj
    );
  }

  getPlayerPromotions(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/bonus/getPlayerPromotions`);
  }

  getPlayerPromoRankingDetails(requestObject): Observable<any> {
    return this.apiInteractor.get<any, any>(
      `/ajax/bonus/getPlayerPromoRankingDetails`,
      requestObject
    );
  }
}
