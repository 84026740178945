import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

// Components
import { PromotionContentPageComponent } from "src/app/modules/promotions/components/promotion-content-page/promotion-content-page.component";
import { GamePlayFullWindowComponent } from "src/app/modules/game-groups/components/game-play-full-window/game-play-full-window.component";
import { LivespinsLiveCasinoComponent } from "src/app/modules/livespins/components/livespins-live-casino/livespins-live-casino.component";
import { ProvideGamesDisplay } from "src/app/modules/game-groups/components/provider-games-display/provider-games-display.component";
import { GamePlayWindowComponent } from "src/app/modules/game-groups/components/game-play-window/game-play-window.component";
import { AllPromotionsComponent } from "src/app/modules/promotions/components/all-promotions/all-promotions.component";
import { ViewAllGamesComponent } from "src/app/modules/game-groups/components/view-all-games/view-all-games.component";
import { LiveSpinsPageComponent } from "src/app/modules/livespins/components/livespins-page/livespins-page.component";
import { PageNotFoundComponent } from "src/app/modules/shared/components/page-not-found/page-not-found.component";
import { LiveCasinoComponent } from "src/app/modules/game-groups/components/live-casino/live-casino.component";
import { LandingPageComponent } from "src/app/modules/shared/components/landing-page/landing-page.component";
import { AllGamesComponent } from "src/app/modules/game-groups/components/all-games/all-games.component";
import { RedirectComponent } from "src/app/modules/shared/components/redirect/redirect.component";
import { HomeComponent } from "src/app/modules/shared/components/home-page/home.component";

// Services
import { AuthGaurd } from "src/app/modules/auth/guards/auth.guard";

// Utilities
import {
  allGameComponentRouteMatcher,
  gamePlayFullPageRouteMatcher,
  promotionContentRouteMatcher,
  promotionPageRouteMatcher,
  allGamePageRouteMatcher,
  liveLobbyRouteMatcher,
  gamePageRouteMatcher,
  lobbyRouteMatcher,
} from "src/app/modules/multi-languages/utilities/route.utilities";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: ":id",
    component: LandingPageComponent,
    canActivate: [AuthGaurd],
    children: [
      {
        path: "reset-password",
        loadChildren:
          "../app/modules/reset-password/reset-password.module#ResetPasswordModule",
      },
      {
        path: "unsubscribe",
        loadChildren:
          "../app/modules/unsubscribe/unsubscribe.module#UnsubscribeModule",
      },
    ],
  },
  {
    matcher: lobbyRouteMatcher,
    component: HomeComponent,
  },
  {
    matcher: liveLobbyRouteMatcher,
    component: LiveCasinoComponent,
  },
  {
    matcher: allGameComponentRouteMatcher,
    component: AllGamesComponent,
  },
  {
    path: ":id/studio/:providerName",
    component: ProvideGamesDisplay,
  },
  {
    matcher: allGamePageRouteMatcher,
    component: ViewAllGamesComponent,
  },
  {
    path: ":id/gameprovider",
    component: ProvideGamesDisplay,
  },
  {
    matcher: gamePageRouteMatcher,
    component: GamePlayWindowComponent,
  },
  {
    matcher: gamePlayFullPageRouteMatcher,
    component: GamePlayFullWindowComponent,
  },
  {
    matcher: promotionPageRouteMatcher,
    component: AllPromotionsComponent,
  },
  {
    matcher: promotionContentRouteMatcher,
    component: PromotionContentPageComponent,
  },
  {
    path: ":lang/livespins",
    component: LivespinsLiveCasinoComponent,
  },
  {
    path: ":lang/livespins/:id",
    component: LiveSpinsPageComponent,
  },
  {
    path: ":id/countryblock",
    loadChildren:
      "../app/modules/country-block/country-block.module#CountryBlockModule",
  },
  {
    path: ":id/maintenance",
    loadChildren:
      "../app/modules/maintenance/maintenance.module#MaintenanceModule",
  },
  {
    path: ":id/redirection",
    component: RedirectComponent,
  },
  {
    path: ":id/:url",
    loadChildren:
      "../app/modules/static-pages/static-page.module#StaticPagesModule",
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
