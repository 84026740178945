import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Models
import { EligibleBonusItem } from "src/app/modules/rewards/models/eligible-bonus.model";
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-deposit-reward-card",
  templateUrl: "./deposit-reward-card.component.html",
  styleUrls: ["./deposit-reward-card.component.scss"],
})
export class DepositRewardCardComponent implements OnChanges, OnDestroy {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() callOnBonusSelected: EventEmitter<CampaignItem> =
    new EventEmitter<CampaignItem>();

  // Inputs
  /*
    Selected Bonus is required only for payment bonus
  */
  @Input() selectedBonus: CampaignItem;
  @Input() bonusDetails: CampaignItem;
  @Input() callingFrom: string = "";

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  gamesUrlPath: string = environment.gamesUrlPath;
  currencySymbol: string = "";
  imgixParams: string = "";
  bonusType: string = "";
  theme: string = "";

  // Booleans'
  isRealUser: boolean = false;
  isLoggedIn: boolean = false;

  // Enums
  currencyCode: WorldCurrencyCode;

  // Objects
  userProfileDetails: UserData;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private cashierService: CashierService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.userProfileDetails = userData;

            this.isRealUser =
              this.userProfileDetails.userStatus === "real" ? true : false;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;

      this.bonusType = this.bonusDetails["bonusTypeFD"];
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["selectedBonus"] &&
      changes["selectedBonus"].previousValue !==
        changes["selectedBonus"].currentValue
    ) {
      this.selectedBonus = changes["selectedBonus"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onOpenDepositView(bonusDetails: CampaignItem): void {
    this.cashierService.onSetActiveDepositBonus(
      bonusDetails as EligibleBonusItem
    );

    const activeTab: ActiveTab = {
      tabName: "deposit",
      showBackButton: true,
    };

    this.utilityService.openAccountComponent(activeTab);
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }

  onSelectBonus(selectedBonus: CampaignItem): void {
    this.selectedBonus = selectedBonus;

    this.callOnBonusSelected.emit(selectedBonus);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
