import { Component, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { ccConfigurations } from "src/app/configurations/cashier-config/cc.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { DepositMethodInputField } from "src/app/models/configurations/deposit-method-configuration.model";
import { CCRegexExp } from "src/app/modules/dynamic-form/models/cc-regex-exp.model";
import { CCBlockSep } from "src/app/modules/dynamic-form/models/cc-block-sep.model";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormInputComponent extends FormValidationComponent {
  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  selectedCardType: string = "";
  focusedElement: string = "";
  previousValue: string = "";
  callingFrom: string = "";
  imgixParams: string = "";

  // Booleans
  isUsedAccount: boolean = false;

  // Arrays
  selectedCardTypeList: number[] = [];

  // Objects
  selectedMethodData: UserPaymentMethod;
  config: DepositMethodInputField;

  // Form Groups
  formGroup: FormGroup;

  // Regex
  ccBlockSep: CCBlockSep;
  ccReExp: CCRegexExp;

  constructor(private utilityService: UtilityService) {
    super();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onFormatInputValue(config: DepositMethodInputField): void {
    if (config.key === "creditcardNumber") {
      this.onFormatCreditCard(config);
    } else if (config.key === "expiryMonthYear") {
      this.onFormatExpiryDate(config);
    }
  }

  onFormatCreditCard(config: DepositMethodInputField): void {
    this.ccReExp = ccConfigurations.re;

    this.ccBlockSep = ccConfigurations.blocks;

    let regex: RegExp = /^[0-9\s]+$/;

    if (
      regex.test(this.formGroup.controls["creditcardNumber"].value) === false
    ) {
      this.formGroup.controls["creditcardNumber"].setValue(
        this.formGroup.controls["creditcardNumber"].value.substring(
          0,
          this.formGroup.controls["creditcardNumber"].value.length - 1
        )
      );
    }

    let ccValue: string = this.formGroup.controls["creditcardNumber"].value;

    if (ccValue) {
      this.ccReExp = ccConfigurations.re;

      this.ccBlockSep = ccConfigurations.blocks;

      for (let key in this.ccReExp) {
        if (this.ccReExp[key].test(ccValue)) {
          this.selectedCardType = key;

          this.selectedCardTypeList = this.ccBlockSep[key];
        }
      }

      let ccString: string = ccValue
        ? JSON.stringify(ccValue)
            .replace(/\s+/g, "")
            .replace(/[^0-9]/gi, "")
        : "";

      let matches: RegExpMatchArray = ccString.match(
        ccConfigurations.minMaxlines[this.selectedCardType]
      );

      let match: string = (matches && matches[0]) || "";

      let list: string[] = [];

      for (
        let i: number = 0, j = 0, len = match.length;
        i < len && match.length > 4;
        i += this.selectedCardTypeList[j++]
      ) {
        list.push(ccString.substring(i, i + this.selectedCardTypeList[j]));
      }

      if (list.length) {
        this.formGroup.controls["creditcardNumber"].setValue(list.join(" "));
      } else {
        this.formGroup.controls["creditcardNumber"].setValue(ccValue);
      }
    }
  }

  onFormatExpiryDate(config: DepositMethodInputField): void {
    let regex: RegExp = /^[0-9\/]+$/;

    if (
      regex.test(this.formGroup.controls["expiryMonthYear"].value) === false ||
      (this.formGroup.controls["expiryMonthYear"].value &&
        this.formGroup.controls["expiryMonthYear"].value.length > 5)
    ) {
      this.formGroup.controls["expiryMonthYear"].setValue(
        this.formGroup.controls["expiryMonthYear"].value.substring(
          0,
          this.formGroup.controls["expiryMonthYear"].value.length - 1
        )
      );
    }

    let expiryValue: string = this.formGroup.controls["expiryMonthYear"].value;

    if (
      expiryValue &&
      expiryValue.length === 2 &&
      expiryValue.length > this.previousValue.length
    ) {
      this.formGroup.controls["expiryMonthYear"].setValue(`${expiryValue}/`);
    }

    this.previousValue = this.formGroup.controls["expiryMonthYear"].value;
  }
}
