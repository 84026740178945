import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-side-menu-modal",
  templateUrl: "./side-menu-modal.component.html",
  styleUrls: ["./side-menu-modal.component.scss"],
})
export class SideMenuModalComponent implements OnInit, OnChanges {
  // Outputs
  @Output() callBackButton: EventEmitter<void> = new EventEmitter<void>();

  // Inputs
  @Input() show2LineHeaderTitle: boolean = false;
  @Input() isShowBackButton: boolean = true;
  @Input() showBackBtn: boolean = true;
  @Input() showFooter: boolean = false;
  @Input() sideMenuTitle: string = "";
  @Input() customClass: string = "";
  @Input() callingFrom: string = "";

  // Strings
  languageCode: string = "";
  theme: string = "";

  // Subscriptions
  subscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;

        this.theme = this.utilityService.setLocaleBasedTheme();
      });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["showBackBtn"] &&
      changes["showBackBtn"].previousValue !==
        changes["showBackBtn"].currentValue
    ) {
      this.showBackBtn = changes["showBackBtn"].currentValue;
    }

    if (
      changes["sideMenuTitle"] &&
      changes["sideMenuTitle"].previousValue !==
        changes["sideMenuTitle"].currentValue
    ) {
      this.sideMenuTitle = changes["sideMenuTitle"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onCloseComponent(navigateTo?: string): void {
    if (navigateTo && this.callingFrom === "betHistory") {
      this.callBackButton.emit();
    } else if (this.callingFrom === "resetPassword") {
      this.router.navigate([`${this.languageCode}/casino`]);
    } else {
      this.utilityService.closeAccountComponent(navigateTo);
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
