// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Models
import { MinMaxDepositAmount } from "src/app/modules/account/models/min-max-deposit-amount.model";
import {
  EligibleBonusItem,
  CurrencyTierItem,
} from "src/app/modules/rewards/models/eligible-bonus.model";

export const getMinMaxDepositAmount = (
  selectedBonus: EligibleBonusItem,
  currencyCode: WorldCurrencyCode
): MinMaxDepositAmount => {
  if (selectedBonus && selectedBonus.currencyTier && currencyCode) {
    const bonusCurrencyTier: CurrencyTierItem =
      selectedBonus.currencyTier[currencyCode][0];

    if (bonusCurrencyTier) {
      return {
        minAmmount: bonusCurrencyTier.minDepositValue / 100,
        maxAmount: bonusCurrencyTier.maxDepositValue / 100,
      };
    }
  }
};
