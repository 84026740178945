import { map, catchError, pairwise, filter } from "rxjs/operators";
import { Router, RoutesRecognized } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  Subscription,
  Observable,
  throwError,
  Subject,
  of,
} from "rxjs";

// Actions
import {
  userProfileBalanceRequested,
  profileBalanceRequested,
} from "src/app/modules/user/store/actions/profile.actions";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { marketLocaleCurrencyMappingConfigurations } from "src/app/configurations/main.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";
import { ValidationResponse } from "src/app/modules/registration/models/validation/validation-response.model";
import { ValidationRequest } from "src/app/modules/registration/models/validation/validation-request.model";
import { NavigationAfterLogin } from "src/app/modules/shared/models/navigation/navigate-after-login.model";
import { KycDetailsToUnblock } from "src/app/modules/shared/models/kyc/kyc-details-to-unblock.model";
import { ProfileCountryDetails } from "src/app/modules/user/models/profile-country-details.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import {
  NavigationRoute,
  NavigationData,
} from "src/app/modules/shared/models/navigation/navigation.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { MainService } from "src/app/modules/shared/services/main.service";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  previousComponentURL: string = "";
  activeAccountTab: string = "";
  activeLobbyName: string = "";
  languageCode: string = "";
  previousURL: string = "";

  // Booleans
  isKycBlocked: boolean = true;

  // Arrays
  userBalanceByPocketsCallQueue = [];

  // To Discover
  profileCountryDetails: any;

  // Others
  navigateAfterLogin: NavigationAfterLogin = {};
  kycDetailsToUnblock: KycDetailsToUnblock = {
    address: false,
    identity: false,
    paymentInstrument: false,
    sourceOfIncome: false,
    enableKyc: false,
  };

  // --------------------------------------------------------
  // Subject - Active Account View
  private activeAccountViewSubject: Subject<ActiveTab> =
    new Subject<ActiveTab>();
  public activeAccountViewSubject$: Observable<ActiveTab> =
    this.activeAccountViewSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Active Left Menu
  private activeLeftMenuSubject: Subject<string> = new Subject<string>();
  public activeLeftMenuSubject$: Observable<string> =
    this.activeLeftMenuSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Navigation Data
  public navigationDataSubject: Subject<NavigationData> =
    new Subject<NavigationData>();
  public navigationDataSubject$: Observable<NavigationData> =
    this.navigationDataSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Is Game Window Regily Pop Up Timer Update
  private isGameWindowRegilyPopUpTimerUpdateSubject: Subject<boolean> =
    new Subject<boolean>();
  public isGameWindowRegilyPopUpTimerUpdateSubject$: Observable<boolean> =
    this.isGameWindowRegilyPopUpTimerUpdateSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Is Game Window Regily Pop Up Timer Update

  // --------------------------------------------------------
  // Subject - Active Account View
  private current404PageSubject: Subject<string> = new Subject<string>();
  public current404PageSubject$: Observable<string> =
    this.current404PageSubject.asObservable();

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    @Inject(DOCUMENT) private document: Document,
    private mainService: MainService,
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            this.store.dispatch(profileBalanceRequested());

            this.store.dispatch(userProfileBalanceRequested());
          }
        }),
      /*
      Game window back navigation handling related code
      */
      this.router.events
        .pipe(
          filter((evt: RoutesRecognized) => evt instanceof RoutesRecognized),
          pairwise()
        )
        .subscribe((events: RoutesRecognized[]) => {
          const urlAfterRedirect: string = decodeURIComponent(
            events[0].urlAfterRedirects
          );

          const temp: string[] = this.getDecodedCurrentPath().split("/");

          const translatedString: string = this.translationService
            .get("url.game")
            .toLowerCase();

          if (temp.length > 2 && temp[2] != translatedString) {
            this.previousComponentURL = urlAfterRedirect;

            if (temp[2] != "studio") {
              this.previousURL = this.previousComponentURL;
            }
          } else if (temp.length == 2) {
            this.previousComponentURL = undefined;

            this.previousURL = undefined;
          }
        }),
    ];
  }

  private quickDepositToggle = new Subject<boolean>();
  public quickDepositToggle$ = this.quickDepositToggle.asObservable();

  private pendingWithdrawCancel = new Subject<boolean>();
  public pendingWithdrawCancel$ = this.pendingWithdrawCancel.asObservable();

  countryCode: string;
  private updateCountryCode: Subject<string> = new Subject<string>();
  public updateCountryCode$: Observable<string> =
    this.updateCountryCode.asObservable();

  public gameplayFooterHover: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public gameplayFooterHover$: Observable<boolean> =
    this.gameplayFooterHover.asObservable();

  public isGamesToasterOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGamesToasterOpen$: Observable<boolean> =
    this.isGamesToasterOpen.asObservable();

  public closeGame: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public closeGame$: Observable<boolean> = this.closeGame.asObservable();

  private isLiveCasinoPage: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isLiveCasinoPage$: Observable<boolean> =
    this.isLiveCasinoPage.asObservable();

  // -----------------------------------------------------------------
  // Get Methods
  getActiveLobby(): string {
    return this.activeLobbyName;
  }

  getNavigateAfterLogin(): NavigationRoute {
    return this.navigateAfterLogin;
  }

  getActiveAccountMenu(): string {
    return this.activeAccountTab;
  }

  // -----------------------------------------------------------------
  // Get Observables
  onGetValidateUniqueness(
    fieldToValidate: ValidationRequest
  ): Observable<ValidationResponse> {
    let url = "";

    _.each(fieldToValidate, (_, key: string) => {
      url =
        key == "txtNickname"
          ? "/ajax/registration/isUniqueNickname"
          : "/ajax/registration/isUniqueEmail";
    });

    return this.apiInteractor
      .post<ValidationRequest, ValidationResponse>(url, fieldToValidate)
      .pipe(
        map((validationResponse: ValidationResponse) => {
          return validationResponse;
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onBroadcastActiveAcountView(tabName: ActiveTab): void {
    this.activeAccountViewSubject.next(tabName);
  }

  onBroadcastActiveLeftMenu(tabName: string): void {
    this.activeLeftMenuSubject.next(tabName);
  }

  onSetActiveLobby(activeLobbyName: string): void {
    this.activeLobbyName = activeLobbyName;
  }

  onBroadcastNavigationData(navigationData?: NavigationData): void {
    if (navigationData && navigationData.lobby) {
      this.activeLobbyName = navigationData.lobby;
    }

    this.navigationDataSubject.next(navigationData);
  }

  onSetNavigateAfterLogin(navigateAfterLogin: NavigationRoute): void {
    this.navigateAfterLogin = navigateAfterLogin;
  }

  onBroadcastIsGameWindowRegilyPopUpTimerUpdate(): void {
    this.isGameWindowRegilyPopUpTimerUpdateSubject.next(true);
  }

  onBroadcastCurrent404Page(current404Page: string): void {
    this.current404PageSubject.next(current404Page);
  }

  // -----------------------------------------------------------------
  // To Discover
  /*
    ApI calls...
  */

  doProfileUpdate(requestObj): Observable<any> {
    return this.mainService.onProfileUpdate(requestObj).pipe(
      map((response) => {
        if (response) {
          this.userDetailsService.onSetUserProfileDetails(response);
        }
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getProfileCountryDetails(): Observable<ProfileCountryDetails> {
    if (!_.isEmpty(this.profileCountryDetails)) {
      return of(this.profileCountryDetails);
    } else {
      return this.mainService.onGetProfileCountryDetails().pipe(
        map((response) => {
          this.profileCountryDetails = response;
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  forceGetUserProfileDetails(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/profile/getBalance`).pipe(
      map((balanceDetails) => {
        if (balanceDetails) {
          this.userDetailsService.onSetUserBalanceDetails(balanceDetails);
        }
        return balanceDetails;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  broadcastQuickDepositToggle(flag) {
    this.quickDepositToggle.next(flag);
  }

  broadcastPendingWithdrawCancel(flag) {
    this.pendingWithdrawCancel.next(flag);
  }

  getZendeskToken(): Observable<any> {
    return this.mainService.onGetZendeskToken().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  broadcastUpdateCountryCode(countryCode: string): void {
    this.setCountryCode(countryCode);

    this.updateCountryCode.next(countryCode);
  }

  setCountryCode(countryCode: string): void {
    this.countryCode = countryCode;
  }

  getCountryCode(): string {
    return this.countryCode;
  }

  setCurrencyByLocality(): void {
    const languageCodeFromURL: string =
      this.getDecodedCurrentPath().split("/")[1];

    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.languageCode &&
      _.contains(supportedMarketsList(), languageCodeFromURL)
    ) {
      this.languageCode = languageCodeFromURL;
    }

    if (
      this.languageCode &&
      marketLocaleCurrencyMappingConfigurations &&
      marketLocaleCurrencyMappingConfigurations.hasOwnProperty(
        this.languageCode
      )
    ) {
      const marketLocaleCurrencyMappingConfigClone: {
        [key: string]: CurrencyConfigurations;
      } = {
        ...marketLocaleCurrencyMappingConfigurations,
      };

      this.userDetailsService.onSetCurrencySymbol(
        marketLocaleCurrencyMappingConfigClone[this.languageCode].currencySymbol
      );

      this.userDetailsService.onSetUserCurrencyCode(
        marketLocaleCurrencyMappingConfigClone[this.languageCode].currencyCode
      );
    } else {
      this.userDetailsService.onSetCurrencySymbol(
        environment.defaultCurrencySymbol
      );

      this.userDetailsService.onSetUserCurrencyCode(
        WorldCurrencyCode[environment.defaultCurrencyCode]
      );
    }
  }

  getZendeskRubikoDetails(userName: string): Observable<any> {
    return this.mainService.getZendeskRubikoDetails(userName).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getDecodedCurrentPath(): string {
    return decodeURIComponent(window.location.pathname);
  }

  broadCastIsLiveCasinoPage(flag: boolean): void {
    this.isLiveCasinoPage.next(flag);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
