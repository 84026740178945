import { Pipe, PipeTransform } from "@angular/core";

// Libraries
import * as _ from "underscore";

// Models
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

@Pipe({
  name: "gamesfilter",
})
export class GamesFilterPipe implements PipeTransform {
  readonly liveGameTypes: string[] = ["live_dealer", "live_casino"];

  constructor() {}

  /*
    Used this pipe to filter games based on
    1.live-games & non-live-games
    2. & To discard Games of the providers that are listed
       under unCheckedProviders list
    3. & games lies b/w min & max bet range  only(not yet added In-progress).
  */
  transform(
    gamesList: GamePregmatic[],
    filterbyData?: { typeOfGames?: string; unCheckedProviders?: string[] }
  ): GamePregmatic[] {
    const filterdGamesList: GamePregmatic[] = _.filter(
      gamesList,
      (game: GamePregmatic) => {
        if (
          filterbyData.typeOfGames === "live-game" &&
          game &&
          game.gameType &&
          this.liveGameTypes.indexOf(game.gameType) > -1 &&
          (!filterbyData.unCheckedProviders ||
            (filterbyData.unCheckedProviders &&
              filterbyData.unCheckedProviders.indexOf(game.vendorCode) === -1))
        ) {
          return game;
        } else if (
          filterbyData.typeOfGames === "non-live-game" &&
          game &&
          game.gameType &&
          this.liveGameTypes.indexOf(game.gameType) < 0
        ) {
          return game;
        }
      }
    );

    return filterdGamesList.sort(
      (primary: GamePregmatic, secondary: GamePregmatic) =>
        primary.displayOrder - secondary.displayOrder
    );
  }
}
