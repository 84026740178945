import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { QueryParams } from "src/app/modules/shared/models/parameters.model";

@Injectable({
  providedIn: "root",
})
export class AffiliateService {
  // Strings
  userCountryLanguage: string = "";
  affiliateLangKey: string = `${environment.websiteCode}_affiliateLang`;
  trackerIdKey: string = `${environment.websiteCode}_trackerId`;
  bannerIdKey: string = `${environment.websiteCode}_bannerId`;
  referrerKey: string = `${environment.websiteCode}_referrer`;
  affliateIdKey: string = `${environment.websiteCode}_affId`;

  // Subscriptions
  subscription: Subscription;

  constructor(private router: Router) {}

  // -----------------------------------------------------------------
  // Get Methods
  getIsUserLoggedIn(): boolean {
    const isUserLoggedIn: boolean = JSON.parse(
      localStorage.getItem(localStorageKeys.caf_user)
    );

    return isUserLoggedIn ? true : false;
  }

  getAffiliateCookies(cookieKey: string): string {
    const name: string = `${environment.websiteCode}_${cookieKey}=`;

    const ca: string[] = document.cookie.split(";");

    for (let i: number = 0; i < ca.length; i++) {
      let c: string = ca[i];

      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  }

  // -----------------------------------------------------------------
  // Set Methods
  onNavigateToLandingPage(
    params: QueryParams,
    userLanguage: string,
    path?: string
  ): void {
    if (this.getIsUserLoggedIn()) {
      if (path !== undefined) {
        this.router.navigate([`${userLanguage}/${path}`], {
          queryParams: params,
        });
      } else {
        this.router.navigate([`${userLanguage}/casino`], {
          queryParams: params,
        });
      }
    }
  }

  onSetAffiliateCookie(affiliateId: string, affilateLanguage?: string): void {
    const date: Date = new Date();
    date.setDate(date.getDate() + 30);

    const expires: string = `expires=${date.toUTCString()}`;

    if (affiliateId) {
      document.cookie = `${this.affliateIdKey}=${affiliateId};${expires};path=/`;
    }

    if (affilateLanguage) {
      document.cookie = `${this.affiliateLangKey}=${affilateLanguage};${expires};path=/`;
    }
  }

  onDeleteAffiliateCookies(): void {
    let expiration: string = "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";

    this.onSetCookie(this.affliateIdKey, expiration);
    this.onSetCookie(this.bannerIdKey, expiration);
    this.onSetCookie(this.trackerIdKey, expiration);
    this.onSetCookie(this.referrerKey, expiration);
    this.onSetCookie(this.affiliateLangKey, expiration);
  }

  onSetCookie(key: string, value: string): void {
    document.cookie = `${key}=${value}`;
  }

  onSetAffiliateCookies(
    affiliateId?: string,
    trackerId?: string,
    bannerId?: string
  ): void {
    const date: Date = new Date();
    date.setDate(date.getDate() + 30);

    const expires: string = `expires=${date.toUTCString()}`;

    if (affiliateId) {
      document.cookie = `${this.affliateIdKey}=${affiliateId};${expires};path=/`;
    }

    if (trackerId) {
      document.cookie = `${this.trackerIdKey}=${trackerId};${expires};path=/`;
    }

    if (bannerId) {
      document.cookie = `${this.bannerIdKey}=${bannerId};${expires};path=/`;
    }

    if (
      document.referrer &&
      !document.referrer.includes(window.location.origin)
    ) {
      document.cookie = `${this.referrerKey}=${document.referrer};${expires};path=/`;
    }
  }
}
