import { Component, OnInit } from "@angular/core";

// Models
import { PostMessage } from "src/app/modules/shared/models/preferences/post-message.model";

@Component({
  selector: "app-redirect",
  templateUrl: "./../../../../../redirection.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  constructor() {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    let postMessage: PostMessage = {
      message: "getTransactionStatus",
    };

    window.parent.postMessage(postMessage, window.location.origin);
  }
}
