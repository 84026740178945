import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Component, HostListener } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Components
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { CashierTransactionsRequest } from "src/app/modules/transactions/models/cashier-transactions-request.model";
import { TransactionHistoryResponse } from "src/app/modules/transactions/models/transaction-history-response.model";
import { TransactionTypes } from "src/app/modules/transactions/models/transaction-types.model";
import {
  WithdrawalDepositResponse,
  WithdrawalCashoutDetails,
  DepositCashoutDetails,
} from "src/app/modules/transactions/models/withdrawal-deposit-response.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { TransactionsService } from "src/app/modules/transactions/services/transactions.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-transaction-history",
  templateUrl: "./transaction-history.component.html",
  styleUrls: ["./transaction-history.component.scss"],
})
export class TransactionHistoryComponent extends SharedTransactionComponent {
  // Strings
  currencySymbol: string = "";
  languageCode: string = "";
  theme: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isLoading: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Array
  transactionHistoryList:
    | DepositCashoutDetails[]
    | WithdrawalCashoutDetails[]
    | TransactionHistoryResponse[] = [];

  // Objects
  cashierTransactionsRequest: CashierTransactionsRequest = {
    size: 1000,
    index: 0,
    order: true,
    interval: {
      end: undefined,
      start: undefined,
    },
    transactionTypes: [],
  };
  transactionTypesList: TransactionTypes[] = [
    {
      transactionText: this.translationService.get(
        "transactionhistory.deposit"
      ),
      transactionType: "deposit",
    },
    {
      transactionText: this.translationService.get(
        "transactionhistory.withdraw"
      ),
      transactionType: "withdraw",
    },
  ];
  activeTransactionType: TransactionTypes;

  // Subscriptions
  transactionSubscription: Subscription;
  accountSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private transactionsService: TransactionsService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    public utilityService: UtilityService,
    public formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    super(utilityService, formBuilder);

    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.getWindowType();

    this.activeTransactionType = this.transactionTypesList[0];

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getCashierTransactionsRequest(): CashierTransactionsRequest {
    this.cashierTransactionsRequest.interval = {
      start: new Date(
        Math.floor(this.txnDetailsFrom.controls["startDate"].value)
      ),
      end: new Date(
        Math.floor(
          this.txnDetailsFrom.controls["endDate"].value.setHours(
            23,
            59,
            59,
            999
          )
        )
      ),
    };

    let transactionType: string =
      this.txnDetailsFrom.controls["transactionTypes"].value;

    if (transactionType === "deposit") {
      this.cashierTransactionsRequest.transactionTypes = ["REAL_CASH_DEPOSIT"];
    } else if (transactionType === "withdraw") {
      this.cashierTransactionsRequest.transactionTypes = ["REAL_CASH_WITHDRAW"];
    } else {
      this.cashierTransactionsRequest.transactionTypes = [
        "REAL_CASH_DEPOSIT",
        "REAL_CASH_WITHDRAW",
        "PLAYER_SUBSCRIPTION",
        "REAL_CASH_ADDITION_BY_CS",
        "REAL_CASH_REMOVAL_BY_CS",
        "NEGATIVE_BALANCE_ADDITION_FOR_WIN_REVERSE",
        "REAL_CASH_ADJUSTMENT_FOR_WIN_REVERSE_CAPTURE",
        "POSITIVE_ADJUSTMENT",
        "NEGATIVE_ADJUSTMENT",
        "NEGATIVE_BALANCE_ADDITION_FOR_FRAUD",
        "REAL_CASH_ADJUSTMENT_FOR_FRAUD_CAPTURE_BY_CS",
        "REAL_CASH_CASHOUT_REVERSAL",
      ];
    }

    return this.cashierTransactionsRequest;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    /*
      we need one extra  control on top of common shared form builder..
      so we are create dynamically here 
    */
    let formControl: FormControl = new FormControl(
      this.transactionTypesList[0].transactionType,
      [Validators.required]
    );

    this.txnDetailsFrom.addControl("transactionTypes", formControl);
  }

  onTransactionTypeChange(): void {
    this.transactionHistoryList = undefined;
  }

  onFilterTransactionHistory(): void {
    if (this.txnDetailsFrom.valid) {
      let transactionType =
        this.txnDetailsFrom.controls["transactionTypes"].value;

      if (transactionType === "deposit") {
        this.onGetCashierDepositWithdrawalHistory(transactionType);
      } else if (transactionType === "withdraw") {
        this.onGetCashierDepositWithdrawalHistory(transactionType);
      } else {
        /*
          As part of PROD-198 we are not using All from transaction history
        */
        this.onGetTransactionHistory();
      }
    }
  }

  onGetCashierDepositWithdrawalHistory(transactionType: string): void {
    this.isButtonLoader = true;

    this.transactionHistoryList = undefined;

    this.isLoading = true;

    let cashierTransactionsRequest: CashierTransactionsRequest =
      this.getCashierTransactionsRequest();

    this.accountSubscription = this.transactionsService
      .onGetCashierTransactionHistory(cashierTransactionsRequest)
      .subscribe((withdrawalDepositResponse: WithdrawalDepositResponse) => {
        this.isButtonLoader = false;
        this.isLoading = false;
        if (
          withdrawalDepositResponse &&
          withdrawalDepositResponse.status === StatusResponse.SUCCESS &&
          ((withdrawalDepositResponse.depositDetails &&
            withdrawalDepositResponse.depositDetails.length > 0) ||
            (withdrawalDepositResponse.cashoutDetails &&
              withdrawalDepositResponse.cashoutDetails.length > 0))
        ) {
          this.transactionHistoryList =
            transactionType === "deposit"
              ? withdrawalDepositResponse.depositDetails
              : withdrawalDepositResponse.cashoutDetails;
        } else {
          this.transactionHistoryList = [];
        }
      });
  }

  /*
    As part of PROD-198 we are not using All from transaction history so below 
    transaction history function is not required for now
  */
  onGetTransactionHistory(): void {
    this.transactionHistoryList = undefined;

    this.isLoading = true;

    this.isButtonLoader = true;

    let cashierTransactionsRequest: CashierTransactionsRequest =
      this.getCashierTransactionsRequest();

    this.transactionSubscription = this.transactionsService
      .onGetTransactionHistory(cashierTransactionsRequest)
      .subscribe((transactionHistoryList: TransactionHistoryResponse[]) => {
        this.isButtonLoader = false;

        this.isLoading = false;

        if (transactionHistoryList && transactionHistoryList.length > 0) {
          this.transactionHistoryList = transactionHistoryList;
        } else {
          this.transactionHistoryList = [];
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.activeTransactionType = this.transactionTypesList[0];

    this.transactionHistoryList = undefined;

    if (this.accountSubscription) this.accountSubscription.unsubscribe();

    if (this.transactionSubscription)
      this.transactionSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
