import { HttpClient } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { blockedCountriesList } from "src/app/modules/registration/configurations/blocked-countries.configurations";
import {
  localToRegilyLanguageConfigurations,
  languageToRegisterConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Models
import { RegilyDifferenceCredentialsUsername } from "src/app/modules/registration/models/regily-difference/regily-difference-credentials-username.model";
import { RegilyDifferenceEmailCredentials } from "src/app/modules/registration/models/regily-difference/regily-difference-email-credentials.model";
import { UniqueNicknameValidationResponse } from "src/app/modules/registration/models/nicknames/unique-nickname-validation-response.model";
import { UniqueNicknameValidationRequest } from "src/app/modules/registration/models/nicknames/unique-nickname-validation-request.model";
import { UniqueEmailValidationResponse } from "src/app/modules/registration/models/emails/unique-email-validation-response.model";
import { UniqueEmailValidationRequest } from "src/app/modules/registration/models/emails/unique-email-validation-request.model";
import { PhoneAvailableResponse } from "src/app/modules/registration/models/phone-available/phone-available-response.model";
import { PregmaticRegistrationRequest } from "src/app/modules/registration/models/pregmatic-registration-request.model";
import { RegistrationResponse } from "src/app/modules/registration/models/registration/registration-response.model";
import { RegilyRegistrationRequest } from "src/app/modules/registration/models/regily-registration-request.model";
import { RegilyDifference } from "src/app/modules/registration/models/regily-difference/regily-difference.model";
import { RegilyEventCallback } from "src/app/modules/registration/models/regily/regily-event-callback.model";
import { RegilyRegisterCallback } from "src/app/modules/registration/models/regily-register-callback.model";
import { RegilyWelcomeOffer } from "src/app/modules/registration/models/regily-welcome-offer.model";
import { RegilyRegistration } from "src/app/modules/registration/models/regily-registration.model";
import { TranslatedError } from "src/app/modules/registration/models/translated-error.model";
import { LoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { PhoneNumber } from "src/app/modules/registration/models/phone-number.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { LoginAPIService } from "src/app/modules/auth/store/services/login-api.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

// Utilities
import { onCheckinOtp } from "src/app/modules/registration/utilities/checkin-otp.utilities";
import {
  getRegilyVariantByLocale,
  getRegilyWelcomeOffer,
} from "src/app/modules/registration/utilities/regily-welcome-offer.utilities";

@Injectable({
  providedIn: "root",
})
export class RegistrationService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  languageCode: string = "";

  // Booleans
  isPhoneUniqueness: boolean = false;

  // Subscriptions
  languageCodeSubscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private affiliateService: AffiliateService,
    private loginAPIService: LoginAPIService,
    private emitterService: EmitterService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private httpClient: HttpClient,
    private gtmService: GtmService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Get Methods
  /**
    @param signupData User final Data from the regily callback
    we have this data in our backend & return promise on success
    & reject on failure to regily in order to show proper message.
  */
  onRegisterDataToBackend(
    signupData: PregmaticRegistrationRequest
  ): Promise<void | null> {
    if (environment.features.showConsoles) {
      console.log(
        "registerDataToBackend dddddddddddddddddddddddddd",
        signupData
      );
    }

    if (environment.features.disabledCountries) {
      if (
        signupData &&
        signupData.user &&
        blockedCountriesList.includes(signupData.user.country)
      ) {
        if (environment.features.showConsoles) {
          console.log("signup sweden ongoing ", signupData);
        }

        return;
      }
    }

    if (environment.features.showConsoles) {
      console.log("signup sweden stopped ", signupData);
    }

    if (signupData) {
      delete signupData.user.birthdate;

      const userData: RegilyRegistration = {
        ...signupData.user,
        ...signupData.consent,
        ...signupData.mapped,
      };

      if (signupData.sanction && signupData.sanction.category) {
        userData.pepAndSanctionDetails = {
          category: signupData.sanction.category,
          flag: signupData.sanction.flag,
          program: signupData.sanction.program,
          source: signupData.sanction.source,
        };
      }

      userData.bannerId = this.affiliateService.getAffiliateCookies("bannerId");
      userData.affiliateId = this.affiliateService.getAffiliateCookies("affId");
      userData.trackerId =
        this.affiliateService.getAffiliateCookies("trackerId");
      userData.referenceUrl =
        this.affiliateService.getAffiliateCookies("referrer");

      /*
        We mapped user language based on country he selected while registration
        instead of his locale or the language with which he landed
      */
      userData.userLang = languageToRegisterConfigurations[userData.country]
        ? languageToRegisterConfigurations[userData.country]
        : languageToRegisterConfigurations.others;

      const requestData: RegilyRegistrationRequest =
        new RegilyRegistrationRequest(userData);

      return new Promise((resolve, reject) => {
        this.onRegistration(requestData)
          .toPromise()
          .then((registrationResponse: RegistrationResponse) => {
            if (registrationResponse && registrationResponse.success) {
              /*
                  After Succesfull registration..we call login here
                  with user credentails provide on registration flow.
                  the after we resolve it as successfully(after successfull r)
              */
              this.gtmService.onRegistrationGTMEvent("register", {
                eventType: "register",
                event_context: "finish",
                userid: registrationResponse.externalEcrId,
              });

              this.affiliateService.onDeleteAffiliateCookies();

              const loginCredentials: LoginCredentials = {
                emailId: requestData.emailId,
                password: requestData.password,
              };

              this.loginAPIService
                .onLogin(loginCredentials)
                .subscribe((loginResponse: LoggedIn) => {
                  if (loginResponse && loginResponse.success) {
                    resolve(null);

                    this.onProcessAfterLogin();
                  } else {
                    resolve(null);

                    this.router.navigate([`${this.languageCode}/casino`]);
                  }
                });
            } else {
              const errorMessage: string = this.translationService.get(
                "registration.register_something_wrong"
              );

              let error: TranslatedError = new TranslatedError(errorMessage);

              error.name = "something-wrong";

              error.title = this.translationService.get(
                "registration.register_failed"
              );

              reject(error);
            }
          })
          .catch(() => {
            const errorMessage: string = this.translationService.get(
              "registration.register_something_wrong"
            );

            let error: TranslatedError = new TranslatedError(errorMessage);

            error.name = "something-wrong";

            error.title = this.translationService.get(
              "registration.register_failed"
            );

            reject(error);
          });
      });
    }
  }

  onUniqueEmailValidation(
    emailRequest: UniqueEmailValidationRequest
  ): Promise<void | null> {
    return new Promise((resolve, reject) => {
      this.apiInteractor
        .post<UniqueEmailValidationRequest, UniqueEmailValidationResponse>(
          `/ajax/registration/isUniqueEmail`,
          emailRequest
        )
        .toPromise()
        .then(
          (uniqueEmailValidationResponse: UniqueEmailValidationResponse) => {
            if (
              uniqueEmailValidationResponse &&
              uniqueEmailValidationResponse.response === 1
            ) {
              resolve(null);
            } else {
              const errorMessage: string = this.translationService.get(
                "registration.email_already_message"
              );

              let error: TranslatedError = new TranslatedError(errorMessage);

              error.name = "custom-duplicateAccount";

              error.title = this.translationService.get(
                "registration.email_already_title"
              );

              error.action = "close";

              reject(error);
            }
          }
        )
        .catch(() => {
          const errorMessage: string = this.translationService.get(
            "registration.email_already_error"
          );

          let error: TranslatedError = new TranslatedError(errorMessage);

          error.name = "custom-duplicateAccount";

          error.title = this.translationService.get(
            "registration.email_already_error_title"
          );

          reject(error);
        });
    });
  }

  onUniqueNameValidation(
    nicknameRequest: UniqueNicknameValidationRequest
  ): Promise<void | null> {
    return new Promise((resolve, reject) => {
      this.apiInteractor
        .post<
          UniqueNicknameValidationRequest,
          UniqueNicknameValidationResponse
        >(`/ajax/registration/isUniqueNickname`, nicknameRequest)
        .toPromise()
        .then(
          (
            uniqueNicknameValidationResponse: UniqueNicknameValidationResponse
          ) => {
            if (
              uniqueNicknameValidationResponse &&
              uniqueNicknameValidationResponse.response === 1
            ) {
              resolve(null);
            } else {
              const errorMessage: string = this.translationService.get(
                "registration.username_already_message"
              );

              let error: TranslatedError = new TranslatedError(errorMessage);

              error.name = "custom-duplicateUserName";

              error.title = this.translationService.get(
                "registration.username_already_title"
              );

              error.action = "close";

              reject(error);
            }
          }
        )
        .catch(() => {
          const errorMessage: string = this.translationService.get(
            "registration.username_already_error_message"
          );

          let error: TranslatedError = new TranslatedError(errorMessage);

          error.name = "custom-duplicateUserName";

          error.title = this.translationService.get(
            "registration.username_already_title"
          );

          reject(error);
        });
    });
  }

  onUniquePhoneValidation(
    regilyDoneCallbackData: PregmaticRegistrationRequest
  ): Promise<void | null> {
    let phoneRequest: PhoneNumber = {
      mobileNumber: regilyDoneCallbackData.user.phone,
      mobileIsdCode: regilyDoneCallbackData.user.phonePrefix.replace("+", ""),
    };

    return new Promise((resolve, reject) => {
      this.apiInteractor
        .post<PhoneNumber, PhoneAvailableResponse>(
          `/ajax/registration/checkUserPhoneAvailablity`,
          phoneRequest
        )
        .toPromise()
        .then((phoneAvailableResponse: PhoneAvailableResponse) => {
          if (
            phoneAvailableResponse &&
            phoneAvailableResponse.status === StatusResponse.SUCCESS
          ) {
            this.onRegisterDataToBackend(regilyDoneCallbackData)
              .then(() => {
                resolve(null);
              })
              .catch(() => {
                const errorMessage: string = this.translationService.get(
                  "registration.register_something_wrong"
                );

                let error: TranslatedError = new TranslatedError(errorMessage);

                error.name = "something-wrong";

                error.title = this.translationService.get(
                  "registration.register_failed"
                );

                reject(error);
              });
          } else {
            const errorMessage: string = this.translationService.get(
              "registration.phoneNumber_already_exist"
            );

            let error: TranslatedError = new TranslatedError(errorMessage);

            error.name = "custom-duplicatePhoneNumber";

            error.title = this.translationService.get(
              "registration.phoneNumber_already_title"
            );

            this.isPhoneUniqueness = false;

            reject(error);
          }
        })
        .catch(() => {
          const errorMessage: string = this.translationService.get(
            "registration.phoneNumber_already_exist"
          );

          let error: TranslatedError = new TranslatedError(errorMessage);

          error.name = "custom-duplicatePhoneNumber";

          error.title = this.translationService.get(
            "registration.phoneNumber_already_title"
          );

          this.isPhoneUniqueness = false;

          reject(error);
        });
    });
  }

  onRegistration(
    regilyRegistrationRequest: RegilyRegistrationRequest
  ): Observable<RegistrationResponse> {
    return this.apiInteractor.post<
      RegilyRegistrationRequest,
      RegistrationResponse
    >(`/ajax/registration`, regilyRegistrationRequest);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.languageCodeSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  /*
    Opens third party Regily pop-up with
    Complete Registration flow in it..
  */
  onOpenRegistration(): void {
    this.gtmService.onRegistrationGTMEvent("register", {
      event_type: "register",
      event_context: "start",
    });

    this.onInitiatedRegistrationCallBacks();

    if (window.regily) {
      window.regily.signUp.open();
    } else {
      console.log(
        `Something went wrong with Registration flow.. Please try again after sometime`
      );
    }
  }

  /*
    Funcationality to set up a listener to regily regesteration
    flow using regilyDoneCallback(Triggers after final step) &
    regilyUpdateCallback(triggers after every step).
  */
  onSetRegilyDataSets(): void {
    let regilyWelcomeOffer: RegilyWelcomeOffer = getRegilyWelcomeOffer(
      this.affiliateService,
      this.utilityService,
      this.translationService,
      this.languageCode
    );

    document.documentElement.dataset.regilyCustomData =
      JSON.stringify(regilyWelcomeOffer);

    document.documentElement.dataset.regilyLanguage =
      localToRegilyLanguageConfigurations[this.languageCode];

    /*
      We have two variant on regily variant1 & varient2
      1. If use load website with /en-eu then varient2 need to pass for regilyVariant to load varient2
      2. For rest of all other languages(like /en-ca /en-nz /en-ro /fi-fi and /nb-no and /ja-jp ect..) varient1 need
         to pass for regilyVariant for varient1
      
         If nothing is passed the varient1 will be loaded as default(varient1 is the default on regily side).
    */
    document.documentElement.dataset.regilyVariant = getRegilyVariantByLocale(
      this.languageCode
    );

    onCheckinOtp(this.languageCode);
  }

  onInitiatedRegistrationCallBacks(): void {
    this.onSetRegilyDataSets();

    Object.defineProperty(window, "regilyDoneCallback", {
      value: (regilyDoneCallbackData: PregmaticRegistrationRequest) => {
        if (environment.features.showConsoles) {
          console.log(
            "reggggggggggggggggggggggggggggggggg regilyDoneCallback",
            regilyDoneCallbackData
          );
        }

        if (environment.production) {
          if (
            regilyDoneCallbackData &&
            regilyDoneCallbackData.user &&
            regilyDoneCallbackData.user.phone &&
            regilyDoneCallbackData.user.phonePrefix
          ) {
            return new Promise((resolve, reject) => {
              this.onUniquePhoneValidation(regilyDoneCallbackData)
                .then(() => {
                  resolve(null);
                })
                .catch(() => {
                  const errorMessage: string = this.translationService.get(
                    "registration.phoneNumber_already_exist"
                  );

                  let error: TranslatedError = new TranslatedError(
                    errorMessage
                  );

                  error.name = "custom-duplicatePhoneNumber";

                  error.title = this.translationService.get(
                    "registration.phoneNumber_already_title"
                  );

                  this.isPhoneUniqueness = false;

                  reject(error);
                });
            });
          } else {
            const errorMessage: string = this.translationService.get(
              "registration.register_something_wrong"
            );

            let error: TranslatedError = new TranslatedError(errorMessage);

            error.name = "something-wrong";

            error.title = this.translationService.get(
              "registration.register_failed"
            );

            return Promise.reject(error);
          }
        } else {
          return this.onRegisterDataToBackend(regilyDoneCallbackData);
        }
      },
    });

    Object.defineProperty(window, "regilyUpdateCallback", {
      value: (
        data: RegilyRegisterCallback,
        regilyDifference: RegilyDifference
      ) => {
        if (environment.features.showConsoles) {
          console.log(
            "reggggggggggggggggggggggggggggggggg regilyUpdateCallback data",
            data
          );
          console.log(
            "reggggggggggggggggggggggggggggggggg regilyUpdateCallback data-diff",
            regilyDifference
          );
        }

        for (let property in regilyDifference) {
          for (let key in regilyDifference[property]) {
            if (key === "doneChapter" && regilyDifference[property][key]) {
              this.gtmService.onSendRegilyAnalyticsEvent(
                "event",
                "chapterDone",
                regilyDifference[property][key]
              );
            } else if (
              regilyDifference[property][key] &&
              regilyDifference[property][key] !== ""
            ) {
              this.gtmService.onSendRegilyAnalyticsEvent(
                "event",
                "collectedData",
                key
              );
            }
          }
        }

        if (
          (regilyDifference as RegilyDifferenceEmailCredentials).credentials &&
          (regilyDifference as RegilyDifferenceEmailCredentials).credentials
            .email
        ) {
          /*
            The email has been updated and validation will be triggered
          */
          return this.onUniqueEmailValidation({
            txtEmail: (regilyDifference as RegilyDifferenceEmailCredentials)
              .credentials.email,
          });
        } else if (
          (regilyDifference as RegilyDifferenceCredentialsUsername)
            .credentials &&
          (regilyDifference as RegilyDifferenceCredentialsUsername).credentials
            .username
        ) {
          return this.onUniqueNameValidation({
            txtNickname: (
              regilyDifference as RegilyDifferenceCredentialsUsername
            ).credentials.username,
          });
        } else {
          /*
            we need to resolve promise here..in order to handle other feilds
            if thet are not emials..
          */
          return Promise.resolve();
        }
      },
    });

    Object.defineProperty(window, "regilyEventCallback", {
      value: (data: RegilyEventCallback) => {
        if (
          data &&
          data.action === "close-module" &&
          !data.flowStatus.complete
        ) {
          this.gtmService.onRegistrationGTMEvent("register", {
            eventType: "register",
            event_context: "closed",
          });

          this.commonService.onBroadcastIsGameWindowRegilyPopUpTimerUpdate();

          this.utilityService.removeUrlQueryParams();
        }
      },
    });
  }

  onProcessAfterLogin(): void {
    const activeTab: ActiveTab = {
      tabName: "deposit",
      showBackButton: true,
    };

    this.utilityService.openAccountComponent(activeTab);

    if (
      this.utilityService.getDecodedCurrentPath().split("/")[2] ===
      "live-casino"
    ) {
      this.router.navigate([`${this.languageCode}/live-casino`], {
        queryParams: { ignoreRefresh: "ignoreRefresh" },
      });
    } else {
      this.router.navigate([`${this.languageCode}/casino`], {
        queryParams: { ignoreRefresh: "ignoreRefresh" },
      });
    }

    /*
      Below code is to show a promotional video only for japanese user
      after their first login & it's based on language code
      not on ip address
    */
    if (
      this.languageCode === "ja-jp" &&
      /^\d+$/.test(this.translationService.get("configdata.afterlogin-videoId"))
    ) {
      this.onOpenPromotionalVideo();
    }
  }

  onOpenPromotionalVideo(): void {
    this.emitterService.onBroadcastIsPromotionalVideoEnabled(true);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.languageCodeSubscription)
      this.languageCodeSubscription.unsubscribe();
  }
}
