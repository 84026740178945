import { FormGroup } from "@angular/forms";
import {
  ViewEncapsulation,
  EventEmitter,
  Component,
  Output,
  Input,
} from "@angular/core";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { DepositMethodInputField } from "src/app/models/configurations/deposit-method-configuration.model";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormComponent {
  // Outputs
  @Output() buttonClickedInForm: EventEmitter<string> =
    new EventEmitter<string>();

  // Inputs
  @Input() config: DepositMethodInputField[] = [];
  @Input() selectedMethodData: UserPaymentMethod;
  @Input() usedAccount: boolean = false;
  @Input() callingFrom: string = "";
  @Input() form: FormGroup;

  constructor() {}

  // -----------------------------------------------------------------
  // Set Methods
  onButtonClick(message: string): void {
    this.buttonClickedInForm.emit(message);
  }
}
