// Library
import { uuid } from "uuidv4";

// Models
import { FirestoreGame } from "src/app/modules/firelogger/models/pregmatic-game.model";
import { Firelogger } from "src/app/modules/firelogger/models/firelogger.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { FireLoggerStoreService } from "src/app/modules/firelogger/services/firelogger-store.service";

export const fireLoggerTrackGamesLog = (
  gamesList: GamePregmatic[],
  fireLoggerStoreService: FireLoggerStoreService<Firelogger>,
  ipAddress: string,
  market: string,
  countryCode: string,
  countryName: string
): void => {
  const guid: string = uuid();

  const todayDate: Date = new Date();

  const newDate: string = todayDate.toLocaleDateString("en-CA");

  const newDateTime: string = todayDate.toLocaleString("en-CA");

  const milliseconds: number = todayDate.getTime();

  const gamesStoreList: FirestoreGame[] = gamesList.map(
    (game: GamePregmatic) => {
      const firestoreGame: FirestoreGame = {
        gameCode: game.gameCode,
        vendorDisplayName: game.vendorDisplayName,
        gameName: game.name,
      } as FirestoreGame;

      return firestoreGame;
    }
  );

  const firelogger: Firelogger = {
    id: `${guid}-${newDate}`,
    guid,
    requestedDate: newDate,
    requestedDateTime: newDateTime,
    requestedTime: milliseconds,
    ipAddress,
    market,
    countryCode,
    countryName,
    log: JSON.stringify(gamesStoreList),
  };

  fireLoggerStoreService.addFireloggerOption(firelogger);
};
