import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

// RXJS
import { Subscription } from "rxjs";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { PolicyService } from "src/app/modules/shared/services/policy.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Models
import {
  PolicyConfigs,
  PolicyConfigsUserDetails,
} from "src/app/modules/shared/models/policy/policy.model";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  // Constant
  readonly termsAndConditionsPolicyType: string = "TERMS_AND_CONDITIONS";

  // Subscriptions
  subscriptions: Subscription[] = [];

  // Booleans {
  isTermsAndConditionsPopup: boolean = false;
  isLoggedIn: boolean = false;

  // Strings
  languageCode: string = "";

  // Models
  termsAndConditionsUserPolicy: PolicyConfigsUserDetails;
  currentTermsAndConditions: PolicyConfigs;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private policyService: PolicyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageCode = this.multiLanguageService.getLanguageCode();
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    if (this.isLoggedIn) {
      this.onGetCurrentPolicy();
    }
  }

  // Get Methods
  onGetCurrentPolicy(): void {
    this.subscriptions.push(
      this.policyService
        .onGetPolicies(this.termsAndConditionsPolicyType)
        .subscribe((terms: PolicyConfigs) => {
          if (terms) {
            this.currentTermsAndConditions = terms;
            this.onGetUserPolicyDetails();
          }
        })
    );
  }

  onGetUserPolicyDetails(): void {
    this.subscriptions.push(
      this.policyService
        .onGetUserPolicyDetails(this.termsAndConditionsPolicyType)
        .subscribe((terms: PolicyConfigsUserDetails) => {
          console.log("get user T&C", terms);
          if (terms != null) {
            if (
              this.currentTermsAndConditions.policyVersionId !=
              terms.policyVersionId
            ) {
              this.isTermsAndConditionsPopup = true;
            }
          } else {
            this.isTermsAndConditionsPopup = true;
          }
        })
    );
  }

  // Not being used but just in case for the future...
  /*onGetUserPolicyDetailsHistory(): void {
    this.subscriptions.push(
      this.policyService
        .OnGetUserPolicyDetailsHistory(this.termsAndConditionsPolicyType)
        .subscribe((terms: PolicyConfigsUserDetails[]) => {
          console.log("get user T&C", terms);
        })
    );
  }*/

  // Set Methods
  onTermsAndConditionsBackdropRemove(): void {
    this.isTermsAndConditionsPopup = false;
    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onAcceptTermsAndConditions() {
    const policy: PolicyConfigsUserDetails = {
      policyType: this.currentTermsAndConditions.policyType,
      policyVersionId: this.currentTermsAndConditions.policyVersionId,
      policyAccepted: true,
    };

    this.policyService.updateUserPolicyDetails(policy).subscribe((response) => {
      if (response.status == "SUCCESS") {
        this.onTermsAndConditionsBackdropRemove();

        let termsAndConditionsUrl = this.translateService.instant(
          "url.terms_conditions"
        );

        this.router.navigate([`${this.languageCode}/${termsAndConditionsUrl}`]);
      } else {
        console.log(response);
      }
    });
  }

  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
