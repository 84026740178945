// Environments
import { environment } from "src/environments/environment";

// Models
import { JackpotResult } from "src/app/modules/shared/models/landing-pages/jackpot-result.model";
import { JackpotPage } from "src/app/modules/shared/models/landing-pages/jackpot-page.model";

export const jackpotConfigurations = (languageCode: string): JackpotResult => {
  let landing: JackpotPage = {
    "en-row": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "en-ca": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "es-sa": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "fi-fi": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "hu-hu": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "ja-jp": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "nb-no": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "en-nz": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "pt-br": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: true,
      newJackpotDesktop: "jackpot-brazil-desktop.png",
      newJackpotMobile: "jackpot-brazil-desktop.png",
    },
    "de-de": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
    "en-eu": {
      originalJackpotDesktop: "jackpots_desktop.png",
      originalJackpotMobile: "jackpots_mobile.png",
      enableJackpot: false,
      newJackpotDesktop: "jackpots_desktop.png",
      newJackpotMobile: "jackpots_mobile.png",
    },
  };

  if (!landing.hasOwnProperty(languageCode)) {
    languageCode = "en-row";
  }

  return {
    newJackpotDesktop: environment.jackpotMarket
      ? landing[languageCode].enableJackpot
        ? landing[languageCode].newJackpotDesktop
        : landing[languageCode].originalJackpotDesktop
      : landing[languageCode].originalJackpotDesktop,

    newJackpotMobile: environment.jackpotMarket
      ? landing[languageCode].enableJackpot
        ? landing[languageCode].newJackpotMobile
        : landing[languageCode].originalJackpotMobile
      : landing[languageCode].originalJackpotMobile,
  };
};
