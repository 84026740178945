import { combineLatest, Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";
import {
  userProfileBalanceRequested,
  profileBalanceRequested,
} from "src/app/modules/user/store/actions/profile.actions";

// Configurations
import { languageToPIQConfigurations } from "src/app/configurations/main.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { SetupCashierTransactionInit } from "src/app/modules/account/models/setup-cashier/setup-cashier-transaction-init.model";
import { SetupCashierPaymentSelect } from "src/app/modules/account/models/setup-cashier/setup-cashier-payment-select.model";
import { SetupCashierUnresolved } from "src/app/modules/account/models/setup-cashier/setup-cashier-unresolved.model";
import { SetupCashierLoadError } from "src/app/modules/account/models/setup-cashier/setup-cashier-load-error.model";
import { SetupCashierNavigate } from "src/app/modules/account/models/setup-cashier/setup-cashier-navigate.model";
import { SetupCashierJourney } from "src/app/modules/account/models/setup-cashier/setup-cashier-journey.model";
import { SetupCashierSuccess } from "src/app/modules/account/models/setup-cashier/setup-cashier-success.model";
import { SetupCashierPending } from "src/app/modules/account/models/setup-cashier/setup-cashier-pending.model";
import { SetupCashierFailure } from "src/app/modules/account/models/setup-cashier/setup-cashier-failure.model";
import { SetupCashierData } from "src/app/modules/account/models/setup-cashier/setup-cashier-data.model";
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import { SelectedMethodDetails } from "src/app/modules/account/models/selected-method-details.model";
import { DepositEventPayload } from "src/app/modules/account/models/deposit-event-payload.model";
import { EligibleRequest } from "src/app/modules/rewards/models/eligible-request.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { PiqDetails } from "src/app/modules/account/models/piq-details.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { TokenResponse } from "src/app/models/games/token.response";
import { TokenRequest } from "src/app/models/games/token.request";
import {
  EligibleBonusItem,
  EligibleBonus,
} from "src/app/modules/rewards/models/eligible-bonus.model";

// Payment IQ Cashier
import _PaymentIQCashier, {
  IPiqCashierApiMethods,
  IPiqCashierConfig,
  CashierConfig,
} from "paymentiq-cashier-bootstrapper";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthProfileBalance,
  selectUserProfileBalance,
} from "src/app/modules/user/store/selectors/profile.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

// Utilities
import { feeEnabling } from "src/app/modules/multi-languages/utilities/enabling-fees.utilities";
import {
  purpleThemeHostedCashierStyle,
  brazilThemeHostedCashierStyle,
  defaultHostedCashierStyle,
} from "src/app/modules/account/utilities/hosted-cashier-styles.utilities";

@Component({
  selector: "app-hosted-cashier",
  templateUrl: "./hosted-cashier.component.html",
  styleUrls: ["./hosted-cashier.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HostedCashierComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() cashierType: "deposit" | "withdrawal" = "deposit";
  @Input() isShowBackButton: boolean = true;
  @Input() cashierStep: string = "";

  // Strings
  sideMenuTitleText: string = "";
  languageCode: string = "";
  channelType: string = "";
  theme: string = "";

  // Booleans
  isRedirectNewProviderWindow: boolean = false;
  isCashierIFrameLoaded: boolean = false;
  isClearSelectedBonus: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";
  currencyCode: WorldCurrencyCode;

  // Arrays
  availableDepositBonusList: EligibleBonusItem[] = [];
  gamesList: GamePregmatic[] = [];

  // Objects
  selectedMethodDetails: SelectedMethodDetails = {
    method_name: "creditcard",
    provider_name: "unknown",
  };
  userBalanceDetails: UserBalanceDetails;
  selectedBonus: EligibleBonusItem;
  piqDetails: PiqDetails = {
    sessionId: "",
    userId: environment.paymentIqUID,
    merchantId: environment.paymentIqMID,
    method: "",
    ipAddr: "",
  };
  profileDetails: UserData;

  // Payment IQ Cashier
  paymentIQAPICallbackInstance: IPiqCashierApiMethods;
  cashierInstance: _PaymentIQCashier;

  // Subscriptions
  combineLatestSubscription: Subscription;
  rewardsSubscription: Subscription;
  tokenSubscription: Subscription;
  gameSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private cashierService: CashierService,
    private emitterService: EmitterService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private gtmService: GtmService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.store.dispatch(userProfileBalanceRequested());

    this.store.dispatch(profileBalanceRequested());

    this.channelType = this.utilityService.getDeviceType();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.getWindowType();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.theme = this.utilityService.setLocaleBasedTheme();
        }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["cashierType"] &&
      changes["cashierType"].previousValue !==
        changes["cashierType"].currentValue
    ) {
      this.cashierType = changes["cashierType"].currentValue;

      this.onResetValues();

      this.onSetUpCashier();

      if (this.cashierType === "deposit") {
        this.sideMenuTitleText =
          this.translationService.get("cashier.add_money");

        this.onGetEligibleBonus();
      } else if (this.cashierType === "withdrawal") {
        this.sideMenuTitleText =
          this.translationService.get("cashier.withdraw");
      }
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  /*
    We check user device type(like Is it  ISO | Android | Linux | windows | Mac)
    and then pass this information in Channel property to hosted cashier.

    But if we have selected bonus we mark this property value(_channel) as "Bonus" and
    overridding device info (important note..)
  */
  getChannelId(): string {
    return this.channelType;
  }

  getCashierConfigurations(): CashierConfig {
    let { showFee, showTransactionOverview, hideTxOverview } = feeEnabling(
      this.languageCode
    );

    let cashierConfigurations: CashierConfig = {
      merchantId: this.piqDetails.merchantId,
      userId: this.piqDetails.userId,
      sessionId: this.piqDetails.sessionId,
      // production: if not set, defaults to production
      environment: environment.production ? "production" : "test",
      // method: if not set, defaults to deposit (deposit or withdrawal),
      // method: this.cashierType,
      method: this.cashierType,
      // locale: available values: sv_SE, en_US, en_GB, no_NO, fi_FI, pt_BR
      locale: languageToPIQConfigurations[this.languageCode],
      // mode: set a mode for the cashier (gambling or ecommerce),
      mode: "gambling",
      // gaTag: Pass in your own Google analytic
      gaTag: "UA-173996710-1",
      showAmountLimits: true,
      showReceipt: true,
      receiptAmountDisplayStyle: "symbol",
      channelId: this.getChannelId(),
      showAccounts: "inline",
      // default is false..we set it to true to show amount field
      amountFirst: false,
      fetchConfig: true,
      showFooter: false,
      displayLogoOrName: "name",
      // zebraffeLoader: false,
      defaultLoader: false,
      font: "google, Inter",
      customLogoFileName: "",
      gtm: "",
      showFee,
      showTransactionOverview,
      hideTxOverview,
      attributes: {
        labelId: "casinofriday",
        productId: "CASINO",
        ipAddr: this.piqDetails.ipAddr,
      },
    };

    if (this.selectedBonus && this.selectedBonus.bonusCode) {
      cashierConfigurations.attributes.bnsCode = this.selectedBonus.bonusCode;
    }

    return cashierConfigurations;
  }

  getCashierConfigurationsStaging(): CashierConfig {
    let cashierConfigurations: CashierConfig = {
      merchantId: this.piqDetails.merchantId,
      userId: this.piqDetails.userId,
      sessionId: this.piqDetails.sessionId,
      // production: if not set, defaults to production
      environment: environment.production ? "production" : "test",
      // method: if not set, defaults to deposit (deposit or withdrawal),
      // method: this.cashierType,
      method: this.cashierType,
      // locale: available values: sv_SE, en_US, en_GB, no_NO, fi_FI, pt_BR
      locale: languageToPIQConfigurations[this.languageCode],
      // mode: set a mode for the cashier (gambling or ecommerce),
      mode: "gambling",
      // gaTag: Pass in your own Google analytic
      gaTag: "UA-173996710-1",
      // showAmountLimits: true,
      // showReceipt: true,
      receiptAmountDisplayStyle: "symbol",
      channelId: this.getChannelId(),
      // showAccounts: "inline",
      // default is false..we set it to true to show amount field
      // amountFirst: false,
      fetchConfig: true,
      // showFooter: false,
      // displayLogoOrName: "name",
      // zebraffeLoader: false,
      defaultLoader: false,
      // font: "google, Inter",
      // customLogoFileName: "",
      gtm: "",
      containerMinHeight: "50rem",
      // showFee,
      // showTransactionOverview,
      // hideTxOverview,
      attributes: {
        labelId: "casinofriday",
        productId: "CASINO",
        ipAddr: this.piqDetails.ipAddr,
      },
    };

    if (this.selectedBonus && this.selectedBonus.bonusCode) {
      cashierConfigurations.attributes.bnsCode = this.selectedBonus.bonusCode;
    }

    return cashierConfigurations;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onResetValues(): void {
    this.isRedirectNewProviderWindow = false;

    this.availableDepositBonusList = undefined;

    this.isCashierIFrameLoaded = false;

    this.isLoading = false;

    this.selectedBonus = undefined;

    if (
      this.cashierService.getActiveDepositBonus() &&
      this.cashierType === "withdrawal"
    ) {
      this.cashierService.onSetActiveDepositBonus(undefined);
    }

    if ($("#cashier").contents("iframe").length) {
      $("#cashier").empty();
    }
  }

  onSetUpCashier(): void {
    this.isLoading = true;

    this.userBalanceDetails = this.userDetailsService.getUserBalanceDetails();

    if (
      !_.isEmpty(this.profileDetails) &&
      !_.isEmpty(this.userBalanceDetails)
    ) {
      this.piqDetails.userId = this.profileDetails.playerID;

      this.onGetCashierToken();
    } else {
      let tokenRequest: TokenRequest = {
        token: "cashier",
      };

      const tokenObservable: Observable<TokenResponse> =
        this.cashierService.onGetToken(tokenRequest);

      const profileBalanceObservable: Observable<ProfileBalance> =
        this.store.select(selectAuthProfileBalance);

      const userProfileBalanceObservable: Observable<UserProfileBalance> =
        this.store.select(selectUserProfileBalance);

      this.combineLatestSubscription = combineLatest([
        tokenObservable,
        profileBalanceObservable,
        userProfileBalanceObservable,
      ]).subscribe(
        ([
          tokenResponse,
          profileBalanceResponse,
          userProfileBalanceResponse,
        ]) => {
          let token: TokenResponse = tokenResponse;

          let profileBalance: ProfileBalance = profileBalanceResponse;

          let userProfileBalance: UserProfileBalance =
            userProfileBalanceResponse;

          if (
            profileBalance &&
            profileBalance.profile &&
            profileBalance.profile.playerID &&
            userProfileBalance
          ) {
            this.userBalanceDetails =
              this.userDetailsService.getUserBalanceDetails();

            this.piqDetails.userId = profileBalance.profile.playerID;

            this.onParseToken(token);
          }
        }
      );
    }
  }

  onGetCashierToken(): void {
    let tokenRequest: TokenRequest = {
      token: "cashier",
    };

    this.tokenSubscription = this.cashierService
      .onGetToken(tokenRequest)
      .subscribe((tokenResponse: TokenResponse) => {
        if (
          tokenResponse.status === StatusResponse.SUCCESS &&
          tokenResponse.token &&
          tokenResponse.token !== ""
        ) {
          this.onParseToken(tokenResponse);

          this.piqDetails.sessionId = tokenResponse.token;
        }
      });
  }

  onParseToken(token: TokenResponse): void {
    this.onGetActiveBonus();

    if (
      token.status === StatusResponse.SUCCESS &&
      token.token &&
      token.token !== ""
    ) {
      this.piqDetails.sessionId = token.token;

      this.piqDetails.merchantId = environment.paymentIqMID;

      this.piqDetails.ipAddr = token.ipAddr;

      this.onSetupCashier();
    }
  }

  onResetCashier(): void {
    this.channelType = this.utilityService.getDeviceType();

    window["_PaymentIQCashierReset"]();

    this.onSetupCashier();
  }

  onResetCashierWithBonus(): void {
    window["_PaymentIQCashierReset"]();

    this.channelType = "Bonus";

    this.onSetupCashier();
  }

  onSetupCashier(): void {
    document.getElementById("cashier").innerHTML = "";

    let configurations: IPiqCashierConfig = (
      environment.production
        ? this.getCashierConfigurations()
        : this.getCashierConfigurationsStaging()
    ) as IPiqCashierConfig;

    this.cashierInstance = new _PaymentIQCashier(
      "#cashier",
      configurations,
      (api: IPiqCashierApiMethods) => {
        this.paymentIQAPICallbackInstance = api;

        let { set, on } = api;

        set({
          ...configurations,
          showFee: true,
        });

        on({
          cashierInitLoad: () => {
            if (document.getElementById("cashier")) {
              document.getElementById("cashier").style.opacity = "1";
            }

            if (document.getElementById("loader")) {
              document.getElementById("loader").style.opacity = "0";
            }

            this.onUserCashierJourney("cashierInitLoad");
          },
          success: (setupCashierSuccess: SetupCashierSuccess) => {
            this.onCloseCashier("sucess-callback", setupCashierSuccess);
          },
          failure: (setupCashierFailure: SetupCashierFailure) => {
            this.onUserCashierJourney("failure", setupCashierFailure.data);

            this.onCloseCashier("failure-callback", setupCashierFailure);
          },
          pending: (setupCashierPending: SetupCashierPending) => {
            this.onCloseCashier("pending-callback", setupCashierPending);
          },
          unresolved: (setupCashierUnresolved: SetupCashierUnresolved) => {
            this.onCloseCashier("unresolved-callback", setupCashierUnresolved);
          },
          doneLoading: () => {
            this.isLoading = false;

            this.isCashierIFrameLoaded = true;
          },
          newProviderWindow: () => {
            /*
              As we have requirment that rewards cards should along with
              iframe & it's should not load first.
            
              so when below flag becames true  deposit rewards cards will be load
              in cashier page.
            */
            this.isRedirectNewProviderWindow = true;
          },
          paymentMethodSelect: (
            setupCashierPaymentSelect: SetupCashierPaymentSelect
          ) => {
            this.selectedMethodDetails = {
              method_name: setupCashierPaymentSelect.data.txType,
              provider_name: setupCashierPaymentSelect.data.providerType,
            };

            this.onUserCashierJourney(
              "paymentMethodSelect",
              setupCashierPaymentSelect.data
            );
          },
          onLoadError: (setupCashierLoadError: SetupCashierLoadError) => {
            this.onUserCashierJourney("onLoadError", setupCashierLoadError);
          },
          navigate: (setupCashierNavigate: SetupCashierNavigate) => {
            this.onUserCashierJourney("navigate", setupCashierNavigate);
          },
          transactionInit: (
            transactionInitData: SetupCashierTransactionInit
          ) => {
            this.onUserCashierJourney(
              "transactionInit",
              transactionInitData.data
            );
          },
        });

        if (environment.production) {
          if (this.languageCode == 'pt-br') {
            api.css(defaultHostedCashierStyle("#003B00"));
          } else {
            api.css(defaultHostedCashierStyle());
          }

          if (this.theme === "latamTheme") {
            api.css(purpleThemeHostedCashierStyle);
          }

          if (this.theme === "brazilTheme") {
            api.css(brazilThemeHostedCashierStyle);
          }
        }
      }
    );
  }

  onCloseCashier(
    callingFrom?: string,
    setupCashierSuccess?: SetupCashierData
  ): void {
    if (this.cashierType === "deposit" && callingFrom === "sucess-callback") {
      this.onUpdateDespositSuccessGTMEvent(setupCashierSuccess);

      this.emitterService.onBroadcastSuccessfulDeposit(!!setupCashierSuccess);
    }

    setTimeout(() => {
      this.onCloseComponent();
    }, 5000);
  }

  onUpdateDespositSuccessGTMEvent(
    setupCashierSuccess?: SetupCashierData
  ): void {
    if (
      this.profileDetails &&
      (setupCashierSuccess as SetupCashierSuccess).data.payload
    ) {
      let eventPayload: DepositEventPayload = {
        event: "user_interaction",
        event_type: "deposit",
        currency: this.currencyCode,
        txAmount: (setupCashierSuccess as SetupCashierSuccess).data.payload
          .txAmount,
        transactioRefId: (setupCashierSuccess as SetupCashierSuccess).data
          .payload.txRefId,
        userId: this.profileDetails.playerID,
        event_context:
          this.profileDetails.userStatus === "play" ? "first" : "subsequent",
        country: this.profileDetails.country,
        payment_method: this.selectedMethodDetails.method_name,
        payment_status: "SUCCESS",
      };

      if (this.selectedMethodDetails.provider_name) {
        eventPayload.provider_name = this.selectedMethodDetails.provider_name;
      }

      if (this.selectedBonus && this.selectedBonus.bonusCode) {
        eventPayload.bonusCode = this.selectedBonus.bonusCode;
      }

      this.gtmService.onGtmDataLayerEvent(eventPayload);
    }
  }

  onGetEligibleBonus(): void {
    let eligibleRequest: EligibleRequest = { criteriaType: "DEPOSIT" };

    this.rewardsSubscription = this.rewardsService
      .onGetEligibleBonus(eligibleRequest)
      .subscribe((eligibleBonus: EligibleBonus) => {
        if (eligibleBonus && eligibleBonus.status === StatusResponse.SUCCESS) {
          let eligibleBonusItemList: EligibleBonusItem[] =
            eligibleBonus.eligibleBonusList;

          if (eligibleBonusItemList) {
            this.onProcessEligibleBonus(eligibleBonusItemList);

            this.onGetGames();
          }
        }
      });
  }

  onProcessEligibleBonus(eligibleBonusItemList: EligibleBonusItem[]): void {
    this.availableDepositBonusList = eligibleBonusItemList.filter(
      (eligibleBonusItem: EligibleBonusItem) =>
        eligibleBonusItem.criteriaType == "DEPOSIT"
    );
  }

  onGetGames(): void {
    this.gameSubscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = gamesList;

          this.availableDepositBonusList =
            this.availableDepositBonusList.filter(
              (eligibleBonusItem: EligibleBonusItem) => {
                if (
                  eligibleBonusItem.gameIds &&
                  eligibleBonusItem.gameIds.length > 0
                ) {
                  let filteredGame: GamePregmatic = gamesList.find(
                    (game: GamePregmatic) =>
                      game.gameCode == eligibleBonusItem.gameIds[0]
                  );

                  if (filteredGame && Object.keys(filteredGame).length > 0) {
                    eligibleBonusItem.gameData = filteredGame;
                  }
                }

                return eligibleBonusItem;
              }
            );
        }
      });
  }

  /**
    Below code complete deal with passing of bonus code
    in make transaction
    
    Below we check is there any active bonus already been selected &
    user navigated to cashier like we have reward area where user can click on
    reward card & land on cashier page
    so we store bonus selected in rewards component into service
    & fetch back here to apply & passed it on pass in payment flow
  */
  onGetActiveBonus(): void {
    let activeDepositBonus: EligibleBonusItem =
      this.cashierService.getActiveDepositBonus();

    if (activeDepositBonus && activeDepositBonus.bonusCode) {
      this.selectedBonus = activeDepositBonus;

      this.channelType = "Bonus";
    }
  }

  onSelectedBonus(eligibleBonusItem: EligibleBonusItem): void {
    this.selectedBonus = eligibleBonusItem;

    if (eligibleBonusItem && eligibleBonusItem.bonusCode) {
      this.isClearSelectedBonus = false;

      this.onResetCashierWithBonus();
    } else {
      this.isClearSelectedBonus = true;

      this.onResetCashier();
    }
  }

  onCloseComponent(navigateTo?: string): void {
    this.utilityService.closeAccountComponent(navigateTo);
  }

  onUserCashierJourney(
    callingFrom: string,
    setupCashier: SetupCashierJourney = {}
  ): void {
    // if (setupCashier) {
    if (this.selectedBonus && this.selectedBonus.bonusCode) {
      setupCashier.bonusCode = this.selectedBonus.bonusCode;
    }

    if (this.profileDetails.playerID) {
      setupCashier.userId = this.profileDetails.playerID;
    }

    setupCashier = {
      ...setupCashier,
      ...this.selectedMethodDetails,
    };

    if (this.cashierType === "deposit") {
      this.gtmService.onUserCashierJourneyEvents(callingFrom, setupCashier);
    }
    // }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.cashierService.getActiveDepositBonus()) {
      this.cashierService.onSetActiveDepositBonus(undefined);
    }

    if (this.profileDetails && this.profileDetails.userStatus === "play") {
      this.store.dispatch(profileBalanceRequested());
    }

    if (this.combineLatestSubscription)
      this.combineLatestSubscription.unsubscribe();

    if (this.rewardsSubscription) this.rewardsSubscription.unsubscribe();

    if (this.tokenSubscription) this.tokenSubscription.unsubscribe();

    if (this.gameSubscription) this.gameSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
