import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { DOCUMENT } from "@angular/common";

// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import {
  seoLanguageCodeUpdateConfigurations,
  localToCmsLanguageConfigurations,
  hrefLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { GeoLocationResponse } from "src/app/modules/multi-languages/models/geo-location-response.model";
import { MultiLanguageURL } from "src/app/modules/shared/models/multi-language.model";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class MultiLanguageService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  languageCode: string = environment.defaultLanguage;

  // Arrays
  acceptedLanguagesList: string[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.acceptedLanguagesList = supportedMarketsList();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getLanguageCode(): string {
    if (localStorage.getItem(localStorageKeys.languageCode)) {
      return localStorage.getItem(localStorageKeys.languageCode);
    } else {
      const urlSegmentsList: string[] = this.getDecodedCurrentPath().split("/");

      let languageCode: string =
        urlSegmentsList && urlSegmentsList.length >= 2
          ? urlSegmentsList[1]
          : "";

      if (languageCode && this.acceptedLanguagesList.includes(languageCode)) {
        languageCode = urlSegmentsList[1];
      } else {
        languageCode = environment.defaultLanguage;
      }

      return languageCode;
    }
  }

  getDecodedCurrentPath(): string {
    return decodeURIComponent(window.location.pathname);
  }

  getActiveLobby(path: string): string {
    let activeLobby: string = "";

    switch (path) {
      case "slots":
      case "spielautomaten":
      case "spilleautomater":
      case "kolikkopelit":
      case "caça-níqueis":
      case "tragaperras":
      case "nyerogepek": {
        activeLobby = "slots";

        break;
      }
      case "table-games":
      case "tischspiele":
      case "bordspill":
      case "kolikkopelit":
      case "jogos-de-mesa":
      case "juego-de-Casino":
      case "asztali-jatekok": {
        activeLobby = "table-games";

        break;
      }
      case "all":
      case "alle":
      case "kaikki":
      case "tudo":
      case "todos":
      case "osszes": {
        activeLobby = "all";

        break;
      }
      case "game":
      case "spiel":
      case "spill":
      case "peli":
      case "jogo":
      case "juego":
      case "jatek": {
        activeLobby = "game";

        break;
      }
      case "promotions":
      case "aktionen":
      case "kampanjer":
      case "kampanjat":
      case "promoções":
      case "promociones":
      case "promociok": {
        activeLobby = "promotions";

        break;
      }
      case "rewards":
      case "boni":
      case "premier":
      case "palkinnot":
      case "recompensas":
      case "jutalmak": {
        activeLobby = "rewards";

        break;
      }
      default: {
        activeLobby = path;

        break;
      }
    }

    return activeLobby;
  }

  getTranslatedText(value: string, languageCode: string): string {
    switch (languageCode) {
      case "en-row":
      case "ja-jp":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "de-de":
        if (value === "promotions") {
          return "aktionen";
        }
        break;
      case "fi-fi":
        if (value === "promotions") {
          return "kampanjat";
        }
        break;
      case "en-ca":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "nb-no":
        if (value === "promotions") {
          return "kampanjer";
        }
        break;
      case "pt-br":
        if (value === "promotions") {
          return "promoções";
        }
        break;
      case "es-sa":
        if (value === "promotions") {
          return "promociones";
        }
        break;
      case "hu-hu":
        if (value === "promotions") {
          return "promociok";
        }
        break;
      default:
        if (value === "promotions") {
          return "promotions";
        }
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetGeoLocation(): Observable<GeoLocationResponse> {
    return this.httpClient
      .get(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${environment.geoLocationAPIKey}`
      )
      .pipe(
        map((geoLocationResponse: GeoLocationResponse) => {
          return geoLocationResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetCanonicalURL(multiLanguageURL: MultiLanguageURL | string): void {
    let urlPath: string = "";

    if (typeof multiLanguageURL === "string") {
      switch (multiLanguageURL) {
        case "homepage": {
          multiLanguageURL = {
            en: "casino",
            ja: "casino",
            fi: "casino",
            cd: "casino",
            nl: "casino",
            nz: "casino",
            no: "casino",
            de: "casino",
            pt: "casino",
            es: "casino",
            hu: "casino",
          };
          break;
        }
        case "liveCasinoPage": {
          multiLanguageURL = {
            en: "live-casino",
            ja: "live-casino",
            fi: "live-casino",
            cd: "live-casino",
            nl: "live-casino",
            nz: "live-casino",
            no: "live-casino",
            de: "live-casino",
            pt: "live-casino",
            es: "live-casino",
            hu: "live-casino",
          };
          break;
        }
        case "promotionspage": {
          multiLanguageURL = {
            en: "promotions",
            no: "kampanjer",
            fi: "kampanjat",
            ja: "promotions",
            nz: "promotions",
            nl: "promotions",
            cd: "promotions",
            de: "aktionen",
            pt: "promoções",
            es: "promociones",
            hu: "promociok",
          };
          break;
        }
        case "landingpage": {
          multiLanguageURL = {
            en: "",
            ja: "",
            fi: "",
            cd: "",
            nl: "",
            nz: "",
            no: "",
            de: "",
            pt: "",
            es: "",
            hu: "",
          };

          break;
        }
        case "studiopage": {
          let path: string = this.getDecodedCurrentPath().replace(
            `/${this.languageCode}/`,
            ""
          );

          multiLanguageURL = {
            en: path,
            ja: path,
            fi: path,
            cd: path,
            nl: path,
            nz: path,
            no: path,
            de: path,
            pt: path,
            es: path,
            hu: path,
          };

          break;
        }
      }
    }

    if (multiLanguageURL && Object.keys(multiLanguageURL).length > 0) {
      urlPath =
        multiLanguageURL[localToCmsLanguageConfigurations[this.languageCode]];

      this.onSetCanonicalLink(urlPath);

      this.onSetAlternateLinks(multiLanguageURL);

      this.onSetDefaultLink(urlPath);

      document
        .querySelector("html")
        .setAttribute(
          "lang",
          seoLanguageCodeUpdateConfigurations[this.languageCode]
        );
    }
  }

  onSetCanonicalLink(urlPath: string): void {
    let canonicalLink: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null;

    if (!canonicalLink) {
      const link: HTMLLinkElement = this.document.createElement("link");

      link.setAttribute("rel", "canonical");

      this.document.head.appendChild(link);

      link.setAttribute(
        "href",
        `${environment.siteUrl}/${this.languageCode}/${urlPath}`
      );

      link.setAttribute(
        "hreflang",
        hrefLanguageConfigurations[this.languageCode]
      );
    } else {
      canonicalLink.setAttribute("rel", "canonical");

      canonicalLink.setAttribute(
        "href",
        `${environment.siteUrl}/${this.languageCode}/${urlPath}`
      );

      canonicalLink.setAttribute(
        "hreflang",
        hrefLanguageConfigurations[this.languageCode]
      );
    }
  }

  onSetAlternateLinks(multiLanguageURL: string | MultiLanguageURL): void {
    let alternateLink: NodeListOf<Element> = this.document.querySelectorAll(
      `link[rel='alternate']`
    );

    if (alternateLink.length > 0) {
      alternateLink.forEach((removeExistedAlternateLink: Element) =>
        removeExistedAlternateLink.remove()
      );
    }

    supportedMarketsList().forEach((language: string) => {
      const link: HTMLLinkElement = this.document.createElement("link");

      let urlPath: string =
        multiLanguageURL[localToCmsLanguageConfigurations[language]];

      link.setAttribute("rel", "alternate");

      this.document.head.appendChild(link);

      link.setAttribute(
        "href",
        `${environment.siteUrl}/${language}/${urlPath}`
      );

      link.setAttribute("hreflang", hrefLanguageConfigurations[language]);
    });
  }

  onSetDefaultLink(urlPath: string): void {
    const linkDefault: HTMLLinkElement = this.document.createElement("link");

    linkDefault.setAttribute("rel", "alternate");

    this.document.head.appendChild(linkDefault);

    linkDefault.setAttribute(
      "href",
      `${environment.siteUrl}/${environment.defaultLanguage}/${urlPath}`
    );

    linkDefault.setAttribute("hreflang", "x-default");
  }
}
