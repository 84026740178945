import { Directive, ElementRef, Renderer2 } from "@angular/core";

// Configurations
import { currencyMappingConfigurations } from "src/app/configurations/main.configurations";

// Enums
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";

// Environments
import { environment } from "src/environments/environment";

// Models
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Directive({
  selector: "[appInputCurrencyPlacement]",
})
export class InputCurrencyPlacementDirective {
  constructor(
    private userDetailsService: UserDetailsService,
    private commonService: CommonService,
    private renderer2: Renderer2,
    private el: ElementRef
  ) {
    this.onAppendCurrencySymbol(this.el);
  }

  /*
   Below function/directive will decide user currency position
   Both login user & logout user currency position
   is decided here
   
   case 1: logout User currency is decide based on geo location vs
   currencyMappingConfigurations which is present under config.ts file
   
   case 1: login user currency & position is decided based on user country
  */
  onAppendCurrencySymbol(elementRef: ElementRef): void {
    let countryCode: string = "";

    let currencyConfigurations: CurrencyConfigurations;

    if (this.userDetailsService.userProfileDetails) {
      countryCode = this.userDetailsService.userProfileDetails.country;
    } else {
      countryCode = this.commonService.getCountryCode();
    }

    currencyConfigurations = currencyMappingConfigurations.hasOwnProperty(
      countryCode
    )
      ? currencyMappingConfigurations[countryCode]
      : undefined;

    if (currencyConfigurations) {
      currencyConfigurations.currencySymbol =
        CurrencySymbol[this.userDetailsService.getCurrencySymbol()];
    } else {
      currencyConfigurations = {
        ...currencyConfigurations,
        position: CurrencyPosition.before,
        currencySymbol: CurrencySymbol[environment.defaultCurrencySymbol],
      };
    }

    const span: HTMLSpanElement = this.renderer2.createElement("span");

    const text: string = this.renderer2.createText(
      currencyConfigurations.currencySymbol
    );

    this.renderer2.appendChild(span, text);

    this.renderer2.addClass(span, "currency-symbol");

    this.renderer2.addClass(span, "currency-before");

    if (
      currencyConfigurations &&
      currencyConfigurations.position === CurrencyPosition.after
    ) {
      elementRef.nativeElement.parentNode.insertBefore(
        span,
        elementRef.nativeElement.nextSibling
      );
    } else {
      this.renderer2.insertBefore(
        this.renderer2.parentNode(elementRef.nativeElement),
        span,
        elementRef.nativeElement
      );
    }
  }
}
