import { environment } from "src/environments/environment";

export const purpleTheme = (): string[] => {
  let latinLocale = ["es-sa"];

  if (environment.features.hungarian) {
    // latinLocale = [...latinLocale, "hu-hu"];
  }

  return latinLocale;
};

export const brazilTheme = (): string[] => {
  let latinLocale = ["pt-br"];

  return latinLocale;
};

export const japanTheme = (): string[] => {
  let japanLocale = ["ja-jp"];

  return japanLocale;
};
