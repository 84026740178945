import { Component, Input, OnChanges, SimpleChange } from "@angular/core";

// Models
import { PromotionVideo } from "src/app/modules/promotions/models/promotion-video.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";

@Component({
  selector: "app-brand-promotion",
  templateUrl: "./brand-promotion.component.html",
  styleUrls: ["./brand-promotion.component.scss"],
})
export class BrandPromotionComponent implements OnChanges {
  // Inputs
  @Input() callingFrom: string = "";

  // Objects
  promotionVideo: PromotionVideo = {
    externalAsset: "",
  };

  constructor(private translationService: TranslationService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;

      this.onSetExternalAsset();
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetExternalAsset(): void {
    let translation: string = "";

    if (this.callingFrom === "landing-page") {
      translation = "configdata.landingpage-videoId";
    } else if (this.callingFrom === "app-component") {
      translation = "configdata.afterlogin-videoId";
    }

    this.promotionVideo.externalAsset =
      this.translationService.get(translation);
  }
}
