import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { Meta } from "@angular/platform-browser";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

// Configurations
import { menuConfigurations } from "src/app/configurations/menu.configurations";
import {
  localToCmsLanguageConfigurations,
  accountViewConfigurations,
  bannerCTAConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Libraries
import * as _ from "underscore";

// Models
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";
import { MenuConfigurations } from "src/app/models/configurations/menu/menu-configuration.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { SeoRequest } from "src/app/modules/shared/models/seo/seo-request.model";
import { GameGroup } from "src/app/modules/game-groups/models/game-group.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { Faq } from "src/app/modules/shared/models/faq/faq.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import {
  brazilTheme,
  purpleTheme,
  japanTheme,
} from "src/app/modules/multi-languages/utilities/theme.utilities";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  // Numbers
  public footerContainerOffsetHeight: number = 0;
  userTotalBalance: number = 0;

  // Strings
  activeLeftMenuOption: string = "";
  previousContent: string = "";
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Enums
  currencyCode: WorldCurrencyCode;

  // Subject and BehaviorSubject

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private cashierService: CashierService,
    private sessionService: SessionService,
    private emitterService: EmitterService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private metaService: Meta,
    private router: Router
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getBrandMenuConfigurationType(key: string): MenuConfigurationType {
    const menu: MenuConfigurations = menuConfigurations();

    return _.clone(menu[key]);
  }

  getFooterHeight(): number {
    const footerContainerDom: HTMLCollectionOf<Element> =
      document.getElementsByClassName("footer-conatiner");

    if (footerContainerDom && footerContainerDom.length > 0) {
      return (footerContainerDom[0] as HTMLElement).offsetHeight;
    } else {
      return 0;
    }
  }

  getFilterLobbyListByLobbyName(
    lobbyPregmaticList: LobbyPregmatic[],
    lobbyName: string
  ): LobbyGameGroup[] {
    let filterredLobbyPregmaticList: LobbyPregmatic[] =
      lobbyPregmaticList.filter((lobbyPregmatic: LobbyPregmatic) => {
        return lobbyPregmatic.name.toLowerCase() === lobbyName.toLowerCase();
      });

    return filterredLobbyPregmaticList.length > 0
      ? this.getSortedLobbyGamesGroupByCountry(
          filterredLobbyPregmaticList[0].gameGroupList
        )
      : [];
  }

  getFilterLobbyByLobbyName(
    lobbyPregmaticList: LobbyPregmatic[],
    lobbyName: string
  ): LobbyPregmatic | null {
    let filterredLobbyPregmaticList: LobbyPregmatic[] = [];

    if (lobbyPregmaticList && lobbyPregmaticList.length > 0) {
      filterredLobbyPregmaticList = lobbyPregmaticList.filter(
        (lobbyPregmatic: LobbyPregmatic) => {
          return lobbyPregmatic.name.toLowerCase() === lobbyName.toLowerCase();
        }
      );
    }

    return filterredLobbyPregmaticList.length > 0
      ? filterredLobbyPregmaticList[0]
      : null;
  }

  getSortedUniqueProviderList(gamesList: GamePregmatic[]): GameProviders[] {
    /*
      This condition " game['gameGroupList'] ? game['gameGroupList']['provider_order'] : 0" is not needed
      here...because gameGroupList is already filter to only single object & it exist all time...
      But for double check we have added it.
    */
    if (gamesList && gamesList.length > 0) {
      let providerList: GameProviders[] = _.map(
        gamesList,
        (gamePregmatic: GamePregmatic) => {
          return {
            vendorCode: gamePregmatic.vendorCode,
            vendorDisplayName: gamePregmatic.vendorDisplayName,
            vendorName: gamePregmatic.vendorName,
            provider_order: gamePregmatic.gameGroupList
              ? (gamePregmatic.gameGroupList as GameGroup).provider_order
              : 0,
          } as GameProviders;
        }
      );

      if (providerList) {
        let uniqueProviderList: GameProviders[] = _.uniq(
          providerList,
          false,
          (provider: GameProviders) => {
            return provider.vendorCode;
          }
        );

        uniqueProviderList = _.sortBy(
          uniqueProviderList,
          "provider_order"
        ).reverse();

        return uniqueProviderList;
      } else {
        return [];
      }
    }
  }

  getProviderList(gamesList: GamePregmatic[]): GameProviders[] {
    let uniqueProviderList: GameProviders[] = [];

    if (gamesList && gamesList.length > 0) {
      let gameProvidersList: GameProviders[] = _.map(
        gamesList,
        (gamePregmatic: GamePregmatic) => {
          return {
            vendorCode: gamePregmatic.vendorCode,
            vendorDisplayName: gamePregmatic.vendorDisplayName,
            vendorName: gamePregmatic.vendorName,
            provider_order: gamePregmatic.gameGroupList
              ? (gamePregmatic.gameGroupList as GameGroup).provider_order
              : 0,
            isChecked: true,
          } as GameProviders;
        }
      );

      if (gameProvidersList) {
        uniqueProviderList = _.uniq(
          gameProvidersList,
          false,
          (gameProvider: GameProviders) => {
            return gameProvider.vendorCode;
          }
        );
      } else {
        uniqueProviderList = [];
      }
    }

    return uniqueProviderList;
  }

  getGamesListByProviderName(
    gamesList: GamePregmatic[],
    providerName: string
  ): GamePregmatic[] {
    if (gamesList) {
      return gamesList.filter(
        (gamePregmatic: GamePregmatic) =>
          gamePregmatic.vendorCode == providerName
      );
    } else {
      return [];
    }
  }

  getSortedGamesByGameName(
    gamesList: GamePregmatic[],
    key: string
  ): GamePregmatic[] {
    let sortedGamesList: GamePregmatic[] = _.sortBy(gamesList, key);

    return sortedGamesList;
  }

  getSortedGamesByReleaseDate(
    gamesList: GamePregmatic[],
    key: string
  ): GamePregmatic[] {
    let sortedGamesList: GamePregmatic[] = _.sortBy(
      gamesList,
      (gamePregmatic: GamePregmatic) => {
        return gamePregmatic[key];
      }
    );

    return sortedGamesList;
  }

  getSortedLobbyGamesGroupByCountry(
    lobbyGamesGroupList: LobbyGameGroup[]
  ): LobbyGameGroup[] {
    let sortedLobbyGameGroupList: LobbyGameGroup[] = _.sortBy(
      lobbyGamesGroupList,
      (lobbyGameGroup: LobbyGameGroup) => {
        return lobbyGameGroup.game_group_order;
      }
    );

    return sortedLobbyGameGroupList.reverse();
  }

  getSortedGamesListByCountry(gamesList: GamePregmatic[]): GamePregmatic[] {
    let sortedGamesList: GamePregmatic[] = _.sortBy(
      gamesList,
      (gamePregmatic: GamePregmatic) => {
        return gamePregmatic.gameGroupList
          ? (gamePregmatic.gameGroupList as GameGroup).game_order
          : 0;
      }
    );

    return sortedGamesList.reverse();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
    ];
  }

  onAddClassToAppBody(className: string): void {
    const documentBody: HTMLElement = document.body;

    if (documentBody && !documentBody.classList.contains(className)) {
      documentBody.classList.add(className);
    }
  }

  onRemoveClassFromAppBody(className: string): void {
    const documentBody: HTMLElement = document.body;

    if (documentBody && documentBody.classList.contains(className)) {
      documentBody.classList.remove(className);
    }
  }

  /*
    All the navigation related to cms banner will be handler here Itself
    irrespective of where it loads on website...
  */
  onCmsRoutingHandler(
    routerPath: string,
    data?: {
      bonusCode: string;
    }
  ): void {
    const accountRoutingConfig: string[] = accountViewConfigurations;

    if (routerPath && routerPath === "login") {
      if (!this.isLoggedIn) {
        const activeTab: ActiveTab = {
          tabName: routerPath,
          showBackButton: true,
        };

        this.openAccountComponent(activeTab);
      }
    } else if (
      routerPath &&
      accountRoutingConfig &&
      accountRoutingConfig.indexOf(routerPath) > -1
    ) {
      if (this.isLoggedIn) {
        const activeTab: ActiveTab = {
          tabName: routerPath,
          showBackButton: true,
        };

        this.openAccountComponent(activeTab);

        if (data && data.bonusCode) {
          this.cashierService.onSetActiveDepositBonus({
            bonusCode: data.bonusCode,
          });
        }
      } else {
        const activeTab: ActiveTab = {
          tabName: "login",
          showBackButton: true,
        };

        this.openAccountComponent(activeTab);

        this.commonService.onSetNavigateAfterLogin(
          data
            ? { type: "view", path: routerPath, data: data }
            : { type: "view", path: routerPath }
        );
      }
    } else if (
      routerPath &&
      this.multiLanguageService.getActiveLobby(routerPath.split("/")[1]) ===
        "game"
    ) {
      setTimeout(() => {
        if (this.isLoggedIn) {
          this.router.navigate([`${this.languageCode}/${routerPath}`]);
        } else {
          this.commonService.onSetNavigateAfterLogin({
            type: "url",
            data: { navigateTo: "gameWindow", path: routerPath.split("/")[2] },
          });

          const activeTab: ActiveTab = {
            tabName: "login",
            showBackButton: true,
          };

          this.openAccountComponent(activeTab);
        }
      });
    } else if (routerPath) {
      this.router.navigate([`${this.languageCode}/${routerPath}`]);
    } else {
      this.router.navigate([`${this.languageCode}/casino`]);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onToggleCMSButtons(): void {
    const loggedIn: NodeListOf<Element> =
      document.querySelectorAll(".logged-in-user");
    const loggedOut: NodeListOf<Element> =
      document.querySelectorAll(".logged-out-user");

    if (this.isLoggedIn) {
      loggedIn.forEach((element: Element) => element.classList.remove("hide"));

      loggedOut.forEach((element: Element) => element.classList.add("hide"));
    } else {
      loggedIn.forEach((element: Element) => element.classList.add("hide"));

      loggedOut.forEach((element: Element) => element.classList.remove("hide"));
    }
  }

  onToggleFinnishGeolocationCMSButtons(): void {
    const originalFinnishTC: NodeListOf<Element> = document.querySelectorAll(
      ".original-finnish-tc"
    );
    const ipFinnishTC: NodeListOf<Element> =
      document.querySelectorAll(".ip-finnish-tc");

    if (this.languageCode == "fi-fi") {
      originalFinnishTC.forEach((element: Element) =>
        element.classList.remove("hide")
      );

      ipFinnishTC.forEach((element: Element) => element.classList.add("hide"));
    } else {
      originalFinnishTC.forEach((element: Element) =>
        element.classList.add("hide")
      );

      ipFinnishTC.forEach((element: Element) =>
        element.classList.remove("hide")
      );
    }
  }

  onNavigateAllGamesPage(
    providerPregmatic: ProviderPregmatic,
    providerName?: string
  ): void {
    if (
      providerPregmatic &&
      this.languageCode &&
      providerPregmatic.multilingualUrlGameGroup &&
      providerPregmatic.multilingualUrlGameGroup[
        localToCmsLanguageConfigurations[this.languageCode]
      ]
    ) {
      window.scrollTo(0, 0);

      let multiLingualUrlGameGroupName: string =
        providerPregmatic.multilingualUrlGameGroup[
          localToCmsLanguageConfigurations[this.languageCode]
        ];

      let allGamesPathURL: string = multiLingualUrlGameGroupName
        .toLowerCase()
        .trim();

      if (providerName) {
        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            `url.casino`
          )}/${allGamesPathURL}/${this.convertGameNameToUrl(providerName)}`,
        ]);
      } else {
        this.router.navigate([`${this.languageCode}/${allGamesPathURL}`]);
      }
    }
  }

  // -------------------------------------------------------
  // To Discover

  openAccountComponent(viewName: ActiveTab): void {
    if (viewName) {
      this.commonService.onBroadcastActiveAcountView(viewName);
    } else {
      this.closeAccountComponent();
    }
  }

  closeAccountComponent(navigateTo?: string): void {
    this.removeUrlQueryParams();

    if (navigateTo === "menuOptions") {
      const activeTab: ActiveTab = {
        tabName: "menuOptions",
        showBackButton: true,
      };

      this.commonService.onBroadcastActiveAcountView(activeTab);
    } else {
      const activeTab: ActiveTab = {
        tabName: "",
        showBackButton: true,
      };

      this.commonService.onBroadcastActiveAcountView(activeTab);
    }
  }

  getSessionTimeText(minutes: number): string {
    let sessionTimeText: string = "";

    if (minutes <= 60) {
      sessionTimeText = `${minutes} ${this.translationService.get(
        "limits.minutes"
      )}`;
    } else {
      let hours: number = Math.floor(minutes / 60);
      let mins: number | string = minutes % 60;

      if (hours && mins && mins !== 0) {
        mins = mins < 10 ? "0" + mins : mins;

        sessionTimeText = `${hours} ${this.translationService.get(
          "limits.hours"
        )} ${mins} ${this.translationService.get("limits.minutes")}`;
      } else {
        sessionTimeText = `${hours} ${this.translationService.get(
          "limits.hours"
        )}`;
      }
    }

    return sessionTimeText;
  }

  addMetaTag(metaTag: string, metaDescription: string): void {
    if (metaDescription && metaDescription !== this.previousContent) {
      this.removeMetaTag();

      this.previousContent = metaDescription;

      this.metaService.addTag({
        name: "description",
        content: metaDescription,
      });
    }
  }

  removeMetaTag(): void {
    this.metaService.removeTag("name='description'");
  }

  scrollAddClass(): boolean {
    const doc: HTMLElement = document.documentElement;

    if (doc) {
      const top: number =
        (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      return top > 25 ? true : false;
    } else {
      return false;
    }
  }

  convertGameNameToUrl(gameName: string): string {
    return gameName
      .replace(/[\W_]+/g, " ")
      .trim()
      .toLowerCase()
      .split(" ")
      .join("-");
  }

  convertSpecialCharactersWithSpace(gameName: string): string {
    return gameName
      .toUpperCase()
      .replace(/[\W_]+/g, " ")
      .trim();
  }

  capitalizeFirstLetter(providerName: string): string {
    return (
      providerName.charAt(0).toUpperCase() +
      providerName
        .slice(1)
        .replace(/[\W_]+/g, " ")
        .trim()
    );
  }

  backNavigationURlHandler(): void {
    if (!this.commonService.previousComponentURL) {
      this.router.navigate([`${this.languageCode}/casino`]);
    } else {
      this.router.navigate([this.commonService.previousComponentURL]);
    }
  }

  scrollToFragment(location: string): void {
    const element: Element = document.querySelector(`#${location}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 1000);
    }
  }

  updateActiveLeftMenu(name?: string): void {
    let segment: string = "";

    if (["rewards"].indexOf(name) > -1) {
      segment = name;
    } else if (!this.commonService.getActiveAccountMenu()) {
      let urlSegments: string[] = this.getDecodedCurrentPath().split("/");

      if (urlSegments && urlSegments.length >= 3) {
        segment = this.multiLanguageService.getActiveLobby(urlSegments[2]);
      }
    } else if (this.commonService.getActiveAccountMenu() === "rewards") {
      segment = "rewards";
    }

    this.commonService.onBroadcastActiveLeftMenu(segment);
  }

  /**open terms & conditions page in new tab */
  toggleRewardTCPopUp(flag: boolean): void {
    if (flag) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    this.emitterService.onBroadcastRewardTermsAndConditions(flag);
  }

  fullScreenInit(targetEle): void {
    let fullscreenPromise;

    if (targetEle) {
      if (targetEle["requestFullscreen"]) {
        fullscreenPromise = targetEle["requestFullscreen"]();
      } else if (targetEle["webkitRequestFullscreen"]) {
        fullscreenPromise = targetEle["webkitRequestFullscreen"]();
      } else if (targetEle["mozRequestFullScreen"]) {
        fullscreenPromise = targetEle["mozRequestFullScreen"]();
      } else if (targetEle["msRequestFullscreen"]) {
        fullscreenPromise = targetEle["msRequestFullscreen"]();
      }
    }

    if (fullscreenPromise) {
      fullscreenPromise
        .catch(() => {})
        .then(() => {
          fullscreenPromise.done = true;
        });
    }
  }

  exitFullScreen(): void {
    let exitPromise;

    if (document && document["exitFullscreen"]) {
      exitPromise = document["exitFullscreen"]();
    } else if (document && document["mozCancelFullScreen"]) {
      exitPromise = document["mozCancelFullScreen"]();
    } else if (document && document["webkitExitFullscreen"]) {
      exitPromise = document["webkitExitFullscreen"]();
    } else if (document && document["msExitFullscreen"]) {
      exitPromise = document["msExitFullscreen"]();
    }
    if (exitPromise) {
      exitPromise
        .catch(() => {})
        .then(() => {
          exitPromise.done = true;
        });
    }
  }

  /**Code to remove query params from url if we have any on closing of component
   * because we have chance of opening account component from query params,
   * which we need to remove before close.
   */
  removeUrlQueryParams(): void {
    if (window.location.search.length > 0) {
      this.router.navigate([this.getDecodedCurrentPath()]);
    }
  }

  public gameplayFooterHover: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public gameplayFooterHover$: Observable<boolean> =
    this.gameplayFooterHover.asObservable();

  public isGamesToasterOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isGamesToasterOpen$: Observable<boolean> =
    this.isGamesToasterOpen.asObservable();

  public closeGame: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public closeGame$: Observable<boolean> = this.closeGame.asObservable();

  gameplayFooterMouseOver(val: boolean): void {
    this.gameplayFooterHover.next(val);
  }

  GamesToasterOpen(val: boolean): void {
    this.isGamesToasterOpen.next(val);
  }

  setSEO(contents: SeoRequest | Faq, isGame: boolean = false): void {
    if (contents.metaTitle || contents["metatitle"]) {
      document
        .querySelector("meta[name=title]")
        .setAttribute(
          "content",
          contents.metaTitle ? contents.metaTitle : contents["metatitle"]
        );

      document
        .querySelector('meta[property="og:title"]')
        .setAttribute(
          "content",
          contents.metaTitle ? contents.metaTitle : contents["metatitle"]
        );
    } else {
      document
        .querySelector("meta[name=title]")
        .setAttribute("content", "Casinofriday");

      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", "Casinofriday.com - #ThatFeeling.");
    }
    if (contents.metaDescription || contents["metadescription"]) {
      document
        .querySelector("meta[name=description]")
        .setAttribute(
          "content",
          contents.metaDescription
            ? contents.metaDescription
            : contents["metadescription"]
        );

      document
        .querySelector('meta[property="og:description"]')
        .setAttribute(
          "content",
          contents.metaDescription
            ? contents.metaDescription
            : contents["metadescription"]
        );
    } else {
      document
        .querySelector("meta[name=description]")
        .setAttribute(
          "content",
          "Casinofriday.com casino is the innovative casino that has all the things you as a player want such as Free Spins, bonuses and loads of casino games."
        );

      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", "100% Bonus up to €200, + 200 Free Spins");
    }

    if (contents.metaTags || contents["metatags"]) {
      if (document.querySelectorAll("meta[name=keywords]").length > 0) {
        document
          .querySelector("meta[name=keywords]")
          .setAttribute(
            "content",
            contents.metaTags ? contents.metaTags : contents["metatags"]
          );
      } else {
        let meta: HTMLMetaElement = document.createElement("meta");
        meta.name = "keywords";
        meta.content = contents.metaTags;

        document.getElementsByTagName("head")[0].appendChild(meta);
      }
    } else if (document.querySelectorAll("meta[name=keywords]").length > 0) {
      document.querySelector("meta[name=keywords]").setAttribute("content", "");
    } else {
      let meta: HTMLMetaElement = document.createElement("meta");
      meta.name = "keywords";
      meta.content = "";

      document.getElementsByTagName("head")[0].appendChild(meta);
    }

    if (contents.title) {
      document.querySelector("title").textContent = contents.title;
    } else {
      document.querySelector("title").textContent = "Casinofriday";
    }

    if (isGame && contents["name"]) {
      document.querySelector("title").textContent = contents["name"];
    }
  }

  getDecodedCurrentPath(): string {
    return decodeURIComponent(window.location.pathname);
  }

  /**
   * In hosted cashier we have a property called channel
   * where we have to pass what kind of device types it is, Below logic
   * detect & pass it.
   */
  getDeviceType(): "Android" | "iOS" | "windows" | "Mac" | "Linux" | "unknown" {
    const userDeviceInfo: string = navigator.userAgent;

    if (/android/i.test(userDeviceInfo)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userDeviceInfo)) {
      return "iOS";
    } else if (/Windows/.test(userDeviceInfo)) {
      return "windows";
    } else if (/ Mac/.test(userDeviceInfo)) {
      return "Mac";
    } else if (/Linux/.test(userDeviceInfo)) {
      return "Linux";
    } else {
      return "unknown";
    }
  }

  getImgixParams(opts?: { [key: string]: string }): string {
    // https://docs.imgix.com/apis/rendering
    const defaults: {
      auto: string;
    } = {
      auto: "format,compress",
    };

    const options: {
      [key: string]: string;
    } = opts ? { ...defaults, ...opts } : defaults;

    const params: string = `?${Object.keys(options)
      .map((k) => `${k}=${options[k]}`)
      .join("&")}`;

    return params;
  }

  /**
   * Functionality to hide & show "Despoit & Game play" (If they configured on banner)
   * Based on banner CTA Config vs user total balance
   */
  toggleBannerDepositBtn(userTotalBalance?: number): void {
    const isDepositBtnAvailable: NodeListOf<Element> =
      document.querySelectorAll(".show-banner-cta-deposit");

    const isGamePlayBtnAvailable: NodeListOf<Element> =
      document.querySelectorAll(".show-banner-cta-play");

    if (userTotalBalance) {
      this.userTotalBalance = userTotalBalance;
    }

    if (isDepositBtnAvailable.length > 0 && isGamePlayBtnAvailable.length > 0) {
      if (this.isLoggedIn) {
        if (
          this.currencyCode &&
          this.userTotalBalance < bannerCTAConfigurations[this.currencyCode]
        ) {
          this.removeClassFromDomElements(isDepositBtnAvailable, "hide");
          this.addHideClassToDomElements(isGamePlayBtnAvailable, "hide");
        } else {
          this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
          this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
        }
      } else {
        this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
        this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
      }
    }
  }

  addHideClassToDomElements(
    domElements: NodeListOf<Element>,
    className: string
  ): void {
    domElements.forEach((val: Element) => val.classList.add(className));
  }

  removeClassFromDomElements(
    domElements: NodeListOf<Element>,
    className: string
  ): void {
    domElements.forEach((val: Element) => val.classList.remove(className));
  }

  getTimeFormat(expiryDate: number): string {
    let translated_days: string = this.translationService.get("rewards.days");

    let translated_day: string = this.translationService.get("rewards.day");

    let date_future: Date = new Date(expiryDate);
    let date_now: Date = new Date();
    let seconds: number = 0;
    let minutes: number = 0;
    let hours: number = 0;
    let days: number = 0;

    let durationTime: string = "";

    if (date_future > date_now) {
      seconds = Math.floor((date_future.getTime() - date_now.getTime()) / 1000);

      minutes = Math.floor(seconds / 60);
      hours = Math.floor(minutes / 60);

      days = Math.floor(hours / 24);
      hours = hours - days * 24;

      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      if (days > 30) {
        durationTime = `>30 ${translated_days}`;
      } else if (days > 0) {
        /**Logic to round up days if left time is 2 days 23h 10mins 1 sec/23 days 0h 0mins 1sec is 3 days
         * (2 days plus X we always display as 3 days)
         * below 24h will have a timer in that format: 23h 59m 20s*/

        if (days > 0 && (hours || minutes || seconds)) {
          days = days + 1;
        }

        durationTime = `${days} ${
          days === 1 ? translated_day : translated_days
        }`;
      } else if (hours > 0) {
        durationTime =
          hours +
          this.translationService.get("common.hours") +
          minutes +
          this.translationService.get("common.minutes");
      } else if (minutes > 0) {
        durationTime =
          minutes +
          this.translationService.get("common.minutes") +
          seconds +
          this.translationService.get("common.seconds");
      } else if (seconds > 0) {
        durationTime = seconds + this.translationService.get("common.seconds");
      } else {
        durationTime = seconds + this.translationService.get("common.seconds");
      }
    }

    return durationTime;
  }

  setLocaleBasedTheme(): string {
    if (this.languageCode && purpleTheme().indexOf(this.languageCode) > -1) {
      return "latamTheme";
    } else if (
      this.languageCode &&
      brazilTheme().indexOf(this.languageCode) > -1
    ) {
      return "brazilTheme";
    } else if (japanTheme().indexOf(this.languageCode) > -1) {
      return "japanTheme";
    } else {
      return "blueTheme";
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
