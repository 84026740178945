import { Observable, Subject, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import { PromoConfigurationsDetailsResponse } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-details-response.model";
import { PromoConfigurationsDetailsRequest } from "src/app/modules/rewards/models/promo-configurations-details/promo-configurations-details-request.model";
import { PlayerPromoProgressResponse } from "src/app/modules/rewards/models/player-promo-progress/player-promo-progress-response.model";
import { PlayerPromoProgressRequest } from "src/app/modules/rewards/models/player-promo-progress/player-promo-progress-request.model";
import { OptInResponse } from "src/app/modules/rewards/models/opt-in/opt-in-response.model";
import { OptInRequest } from "src/app/modules/rewards/models/opt-in/opt-in-request.model";
import { UserCampaign } from "src/app/modules/rewards/models/user-campaign.model";

@Injectable({
  providedIn: "root",
})
export class CashbackPromoService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Numbers
  userLastAuthTime: number = 0;

  // Booleans
  isPromoOptnSuccess: boolean = false;

  // To Discover
  userCashbackPromoInfo: any;

  // --------------------------------------------------------
  // Subject - Promo Cashback Optn Success
  private promoCashbackOptnSuccessSubject: Subject<void> = new Subject<void>();
  public promoCashbackOptnSuccessSubject$: Observable<void> =
    this.promoCashbackOptnSuccessSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Stop Cashback Promo
  private stopCashbackPromoSubject: Subject<string> = new Subject<string>();
  public stopCashbackPromoSubject$: Observable<string> =
    this.stopCashbackPromoSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Cashback Offer Amount
  private cashbackOfferAmountSubject: Subject<string> = new Subject<string>();
  public cashbackOfferAmountSubject$: Observable<string> =
    this.cashbackOfferAmountSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Cashback Started Promo Popup
  private cashbackStartedPromoPopupSubject: Subject<any> = new Subject<any>();
  public cashbackStartedPromoPopupSubject$: Observable<any> =
    this.cashbackStartedPromoPopupSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getUserLastAuthTime(): number {
    return this.userLastAuthTime;
  }

  getUserCashbackPromoInfo(): any {
    return this.userCashbackPromoInfo;
  }

  // -----------------------------------------------------------------
  // Get Observables - Get User Campaigns
  onGetUserCampaigns(): Observable<UserCampaign> {
    return this.apiInteractor
      .get<null, UserCampaign>(`/ajax/bonus/getUserCampaigns`)
      .pipe(
        map((response: UserCampaign) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get Promo Configurations
  onGetPromoConfigurationsDetails(
    request: PromoConfigurationsDetailsRequest
  ): Observable<PromoConfigurationsDetailsResponse> {
    return this.apiInteractor
      .get<
        PromoConfigurationsDetailsRequest,
        PromoConfigurationsDetailsResponse
      >(`/ajax/bonus/getPromoConfigDetails`, request)
      .pipe(
        map(
          (
            promoConfigurationsDetailsResponse: PromoConfigurationsDetailsResponse
          ) => {
            return promoConfigurationsDetailsResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get Opt In
  onGetOptIn(optInRequest: OptInRequest): Observable<OptInResponse> {
    return this.apiInteractor
      .get<OptInRequest, OptInResponse>(`/ajax/bonus/optIn`, optInRequest)
      .pipe(
        map((optInResponse: OptInResponse) => {
          return optInResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get Player Promo Progress
  onGetPlayerPromoProgress(
    playerPromoProgressRequest: PlayerPromoProgressRequest
  ): Observable<PlayerPromoProgressResponse> {
    return this.apiInteractor
      .get<PlayerPromoProgressRequest, PlayerPromoProgressResponse>(
        `/ajax/bonus/getPlayerPromoProgress`,
        playerPromoProgressRequest
      )
      .pipe(
        map((playerPromoProgressResponse: PlayerPromoProgressResponse) => {
          return playerPromoProgressResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Promo Cashback Optn Success
  onBroadcastPromoCashbackOptnSuccess(): void {
    this.promoCashbackOptnSuccessSubject.next();
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Stop Cashback Promo
  onBroadcastStopCashbackPromo(stopCashbackPromo: string): void {
    this.stopCashbackPromoSubject.next(stopCashbackPromo);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Cashback Offer Amount
  onBroadcastCashbackOfferAmount(cashbackOfferAmount: string): void {
    this.cashbackOfferAmountSubject.next(cashbackOfferAmount);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Cashback Offer Amount
  onSetUserLastAuthTime(lastLoginTimeInMilliseconds: number): void {
    this.userLastAuthTime = lastLoginTimeInMilliseconds * 1000;
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Cashback Started Promo Popup
  onBroadcastCashbackStartedPromoPopup(cashbackStartedPromoPopup: any): void {
    this.cashbackStartedPromoPopupSubject.next(cashbackStartedPromoPopup);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Set User Cashback Promo Info
  onSetUserCashbackPromoInfo(userCashbackPromoInfo: any): void {
    this.userCashbackPromoInfo = userCashbackPromoInfo;
  }
}
