import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Models
import { GeneralHeaderOptions } from "src/app/modules/shared/models/headers/general-header-options.model";
import { JackpotHeaderOptions } from "src/app/modules/shared/models/headers/jackpot-header-options.model";

// Utilities
import { converterObjectQueryString } from "src/app/modules/shared/utilities/converter-object-query-string.utilities";
import {
  generalHeaderOptions,
  jackpotHeaderOptions,
} from "src/app/modules/shared/utilities/general-header.utilities";

export class ApiInteractors {
  // Strings
  baseUrl: string = environment.apiUrl;

  // Http Clients
  private httpClient: HttpClient;

  // Objects
  generalHeaderOptions: GeneralHeaderOptions = generalHeaderOptions();
  jackpotHeaderOptions: JackpotHeaderOptions = jackpotHeaderOptions();

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  // -----------------------------------------------------------------
  // Get Methods - Base Request
  /*
    @param url endpoint url
    @param queryParams optional query params
  */
  get<K, T>(url: string, queryParameters?: K): Observable<T> {
    let queryString: string = converterObjectQueryString<K>(queryParameters);

    return this.httpClient.get<T>(`${this.baseUrl}${url}${queryString}`, {
      observe: "body",
      withCredentials: true,
    });
  }

  getJackpot<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url, this.jackpotHeaderOptions);
  }

  /*
    @param url endpoint url
    @param requestBody data that need to be saved/posted
  */
  post<K, T>(url: string, requestBody?: K): Observable<T> {
    return this.httpClient.post<T>(
      `${this.baseUrl}${url}`,
      requestBody ? this.getUrlParams<K>(requestBody) : null,
      this.generalHeaderOptions
    );
  }

  /*
    @param url endpoint url
    @param requestBody data that need to be saved/posted
  */
  upload<K, T>(url: string, requestBody: K): Observable<T> {
    return this.httpClient.post<T>(`${this.baseUrl}${url}`, requestBody, {
      observe: "body",
      withCredentials: true,
    });
  }

  /*
    Convert object to FromObject
  */
  private getUrlParams<K>(fields: K): HttpParams {
    const urlParams: HttpParams = new HttpParams({ fromObject: fields as any });

    return urlParams;
  }
}
