import { FormGroup } from "@angular/forms";
import { Component } from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { cryptoCurrencyListConfigurations } from "src/app/configurations/main.configurations";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { DepositMethodInputField } from "src/app/models/configurations/deposit-method-configuration.model";

@Component({
  selector: "app-form-select",
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.scss"],
})
export class FormSelectComponent extends FormValidationComponent {
  // Strings
  callingFrom: string = "";

  // Booleans
  isUsedAccount: boolean = false;

  // Arrays
  monthRangeList: number[] = [];
  yearRangeList: number[] = [];

  // Objects
  selectedMethodData: UserPaymentMethod;
  config: DepositMethodInputField;

  // Form Groups
  formGroup: FormGroup;

  constructor() {
    super();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    if (this.config.key === "expiryMonth") {
      for (let i: number = 1; i <= 12; i++) {
        this.monthRangeList.push(i);
      }

      this.config.options = this.monthRangeList;

      this.config.placeholder = "MM";
    }

    if (this.config.key === "expiryYear") {
      let currentYear: number = new Date().getFullYear();

      for (let i: number = currentYear; i < currentYear + 10; i++) {
        this.yearRangeList.push(i);
      }

      this.config.options = this.yearRangeList;

      this.config.placeholder = "YYYY";
    }

    if (this.config.key === "cryptoCurrency") {
      this.config.options = cryptoCurrencyListConfigurations;
    }
  }
}
