import { livespinsLanguageCodeConfigurations } from "src/app/modules/livespins/configurations/livespins.configurations";

export const livespinsByLanguage = <T>(
  list: T[],
  languageCode: string
): T[] => {
  let language: string = languageCode.split("-")[0];

  let array: T[] = [];

  list.map((value: T) => {
    if (
      livespinsLanguageCodeConfigurations[language.toLowerCase()].includes(
        (value["language"] as string).toLowerCase()
      )
    ) {
      array.push(value);
    }
  });

  return array;
};
