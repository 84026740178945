import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";

@Component({
  selector: "app-cookie-message",
  templateUrl: "./cookie-message.component.html",
  styleUrls: ["./cookie-message.component.scss"],
})
export class CookieMessageComponent implements OnInit {
  // Strings
  languageCode: string = "";
  cookieExist: string = "";

  // Booleans
  isCookieMessagePopup: boolean = true;
  isPageAvailable: boolean = false;
  isButtonLoader: boolean = false;
  isPopupLoader: boolean = false;

  // Subscriptions
  subscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.cookieExist = this.getCookieName("CAFCookiePolicyAccepted");

    if (this.cookieExist) {
      this.isCookieMessagePopup = false;
    }

    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getCookieName(name: string): string {
    let cookieList: string[] = document.cookie.split(";");

    for (let i: number = 0; i < cookieList.length; i++) {
      let cookiePair: string[] = cookieList[i].split("=");

      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }

    return null;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onOpenCookiePolicy(): void {
    let urlPath: string = this.translationService.get("url.cookies-policy");

    this.isPopupLoader = true;

    this.router.navigate([`${this.languageCode}/${urlPath}`]);
  }

  onAcceptedCookiePolicy(): void {
    this.isCookieMessagePopup = false;

    this.isPageAvailable = false;

    this.onSetCookieExpiry();
  }

  onSetCookieExpiry(): void {
    let currentDate: Date = new Date();

    currentDate.setTime(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

    let expires: string = `expires=${currentDate.toUTCString()}`;

    document.cookie = `CAFCookiePolicyAccepted=true;${expires};path=/`;
  }
}
