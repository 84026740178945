import { Component } from "@angular/core";

@Component({
  selector: "app-limits",
  templateUrl: "./limits.component.html",
  styleUrls: ["./limits.component.scss"],
})
export class LimitsComponent {
  constructor() {}
}
