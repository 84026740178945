import { Observable, of, Subscription } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";

// Actions
import { logoutRequestedRefresh } from "src/app/modules/auth/store/actions/auth.actions";

// Configurations
import {
  languageToGameLaunchConfigurations,
  localToCmsLanguageConfigurations,
  cmsToLocalLanguageConfigurations,
  countryLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectCountryBlock } from "src/app/modules/country-block/store/selectors/country-block.selectors";
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  //Strings
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Arrays
  acceptedLanguagesList: string[] = [];

  // Objects
  countryBlockResponse: CountryBlockResponse;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.acceptedLanguagesList = supportedMarketsList();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.store
        .select(selectCountryBlock)
        .subscribe((countryBlockResponse: CountryBlockResponse) => {
          if (countryBlockResponse) {
            this.countryBlockResponse = countryBlockResponse;
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Intercept
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes("countryblock")) {
      return next.handle(request);
    } else {
      const urlSegments: string[] = this.utilityService
        .getDecodedCurrentPath()
        .split("/");

      let languageCode: string =
        urlSegments && urlSegments.length >= 2 ? urlSegments[1] : "";

      let userLanguageCode: string =
        this.multiLanguageService.getLanguageCode();

      if (languageCode == "") {
        languageCode = userLanguageCode;
      }

      if (this.isLoggedIn && userLanguageCode) {
        this.languageCode = userLanguageCode;
      } else if (
        languageCode &&
        _.contains(this.acceptedLanguagesList, languageCode)
      ) {
        this.languageCode = languageCode;
      } else {
        this.languageCode = environment.defaultLanguage;
      }

      /*
        If we want to modify any request object..we can do it there like
        adding header or modifying header base on endpoint ur or request
        Method type
      */
      let updatedRequest: HttpRequest<any> = request;

      if (
        this.languageCode &&
        request.url &&
        (request.url.includes("/ajax/game/") ||
          request.url.includes("/ajax/banner/getBanners") ||
          request.url.includes("/ajax/promotion/getPromotions"))
      ) {
        updatedRequest = request.clone({
          setParams: {
            languageId: localToCmsLanguageConfigurations[this.languageCode],
          },
        });
      }

      if (
        this.languageCode &&
        request.url &&
        request.url.includes("/ajax/staticPage")
      ) {
        updatedRequest = request.clone({
          setParams: {
            language: localToCmsLanguageConfigurations[this.languageCode],
          },
        });
      }

      if (
        this.languageCode &&
        request.url &&
        request.url.includes("/ajax/faq/")
      ) {
        updatedRequest = request.clone({
          setParams: {
            lang: localToCmsLanguageConfigurations[this.languageCode],
          },
        });
      }

      if (
        this.languageCode &&
        request.url &&
        (request.url.includes("/ajax/launcher/getRealGames") ||
          request.url.includes("/ajax/launcher/getFreeGames"))
      ) {
        updatedRequest = request.clone({
          setParams: {
            languageId: languageToGameLaunchConfigurations[this.languageCode],
          },
        });
      }

      return next.handle(updatedRequest).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            /*
              You will receive every http call response object here
              we can write some global logic to handle it
              like navigation to different page based on response.
            */
            if (event.body && event.body.error && event.body.error === 150) {
              this.onSetLanguage(event.body.countryCode);

              this.onNavigatorBasedOnError(event.body.error);
            } else if (event.body && event.body.error && event.body.error) {
              this.onNavigatorBasedOnError(event.body.error);
            } else if (event && event["error"]) {
              this.onNavigatorBasedOnError(event["error"]);
            } else if (event && event.body.errors && event.body.errors[0]) {
              this.onNavigatorBasedOnError(event.body.errors[0].errorCode);
            } else if (
              event &&
              event.body &&
              event.body.is_IpBlocked &&
              JSON.parse(event.body.is_IpBlocked) == true
            ) {
              this.onSetLanguage(event.body.countryCode);

              this.onNavigatorBasedOnError("country_blocked");
            } else {
              let pathName: string =
                this.utilityService.getDecodedCurrentPath();

              if (
                pathName.includes("maintenance") ||
                pathName.includes("countryblock")
              ) {
                this.router.navigate([`${this.languageCode}/casino`]);
              }
            }
          }

          return event;
        }),
        catchError((event: HttpEvent<any>) => {
          return of(event);
        })
      );
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetLanguage(countryCode: string): void {
    let ipBasedLangCode: string = "";

    if (
      countryCode &&
      countryLanguageConfigurations.hasOwnProperty(countryCode)
    ) {
      ipBasedLangCode =
        cmsToLocalLanguageConfigurations[
          countryLanguageConfigurations[countryCode]
        ];
    }

    this.languageCode = ipBasedLangCode
      ? ipBasedLangCode
      : environment.defaultLanguage;

    this.translationService.onUseLanguage(
      localToCmsLanguageConfigurations[this.languageCode]
    );
  }

  onNavigatorBasedOnError(code: string | number): void {
    switch (code) {
      case 150: {
        this.router.navigate([`${this.languageCode}/maintenance`]);
        break;
      }
      case "country_blocked": {
        this.router.navigate([`${this.languageCode}/countryblock`]);
        break;
      }
      case 100187: {
        this.store.dispatch(logoutRequestedRefresh());
        break;
      }
      default:
        return;
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
