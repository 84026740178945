import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";

// Actions
import { loginRequested } from "src/app/modules/auth/store/actions/auth.actions";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { KYCDetailsToUnblock } from "src/app/modules/kyc/models/kyc-details/kyc-details-to-unblock.model";
import { LoginEventProperties } from "src/app/modules/auth/models/login-event-properties.model";
import { LoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";
import {
  NavigationRoute,
  NavigationData,
} from "src/app/modules/shared/models/navigation/navigation.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

// Validators
import { UniqueFieldsValidator } from "src/app/modules/validators/validators/unique.validators";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent extends FormValidationComponent {
  // Strings
  languageCode: string = "";
  serverError: string = "";
  theme: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isPassword: boolean = true;

  // Forms
  loginForm: FormGroup;

  // Subscriptions
  loginSubscription: Subscription;
  kycSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private mixPanelService: MixPanelService,
    private cashierService: CashierService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private kycService: KYCService,
    private router: Router
  ) {
    super();

    // Forms Initialization
    this.loginForm = this.formBuilder.group({
      txtEmail: new FormControl(
        "",
        [],
        [
          UniqueFieldsValidator.createValidator(
            "txtEmail",
            this.commonService,
            false
          ),
        ]
      ),
      txtPassword: ["", [Validators.required]],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoggedIn)
        .subscribe((request: { loggedIn: LoggedIn; isLoaded: boolean }) => {
          if (request.isLoaded) {
            this.isButtonLoader = false;

            if (request.loggedIn && request.loggedIn.success === true) {
              const activeTab: ActiveTab = {
                tabName: "",
                showBackButton: true,
              };

              this.commonService.onBroadcastActiveAcountView(activeTab);

              this.onNavigateAfterLogin();
            } else {
              this.onLoginFailure(request.loggedIn);
            }
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLogin(): void {
    this.isButtonLoader = true;

    this.serverError = undefined;

    if (this.loginForm.valid) {
      const credentials: LoginCredentials = {
        emailId: this.loginForm.controls["txtEmail"].value.trim(),
        password: this.loginForm.controls["txtPassword"].value,
      };

      this.store.dispatch(loginRequested({ credentials }));

      this.loginSubscription = this.store
        .select(selectAuthLoggedIn)
        .subscribe(
          (result: {
            loggedIn: LoggedIn;
            isLoaded: boolean;
            isLoading: boolean;
          }) => {
            if (result.isLoaded) {
              this.isButtonLoader = false;

              if (result.loggedIn && result.loggedIn.success) {
                const activeTab: ActiveTab = {
                  tabName: "",
                  showBackButton: true,
                };

                this.commonService.onBroadcastActiveAcountView(activeTab);

                this.onNavigateAfterLogin();
              } else {
                this.onLoginFailure(result.loggedIn);
              }
            }
          }
        );
    }
  }

  onLoginFailure(loginData: LoggedIn): void {
    this.serverError = this.translationService.get("common.error44");

    if (loginData && loginData.errors) {
      if (loginData.errors.accountLocked) {
        let messase: string = loginData.errors.accountLocked;

        const errorMessage: string = this.translationService.get(
          "logincomponent.error_rg_cool_off"
        );

        if (environment.features.showCoolOffWithoutTime) {
          this.serverError = `${errorMessage}`;
        } else {
          /*
            Below logic/work around is required to remove crossbrowser issue in 
            safari, firefox & windows
          */
          let accountLockedDate: Date = new Date(
            Date.parse(
              `${messase
                .substr(messase.indexOf(":") + 1)
                .trim()
                .replace(" ", "T")}+02:00`
            )
          );

          this.serverError = `${errorMessage}${accountLockedDate}`;
        }

        return;
      }

      let errorCode: string = "";

      if (loginData.errors.errorCode) {
        errorCode = `${loginData.errors.errorCode}`;
      } else if (loginData.errors.internalErrorCode) {
        errorCode = `${loginData.errors.internalErrorCode}`;
      }

      if (errorCode) {
        this.onDisplayLoginErrorMessage(errorCode);
      }
    }
  }

  onDisplayLoginErrorMessage(errorCode: string): void {
    switch (errorCode) {
      case "100152": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100152_loginAccountdisabled"
        );
        break;
      }
      case "100157": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100157_rg_closed"
        );
        break;
      }
      case "100173": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100173_rg_cool_off"
        );
        break;
      }
      case "100174": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100174_login_attempts_exceeded"
        );
        break;
      }
      case "100161": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100161_wrong_password"
        );
        break;
      }
      case "100166": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100166_wrong_emailid"
        );
        break;
      }
      default: {
        this.serverError = this.translationService.get("common.error44");
      }
    }
  }

  /*
    Below complete functioanlity deals with deep links before login
    basically when user try to access few part of product without login
    we useful ask user to force login before we ask for force login
    we store it's related data in service data layer & reuse it after successful
    login so that user lands on exact place where we preferred to be before login
  */
  onNavigateAfterLogin(): void {
    const navigateAfterLogin: NavigationRoute =
      this.commonService.getNavigateAfterLogin();

    if (navigateAfterLogin && navigateAfterLogin.type === "url") {
      this.onNavigateByUrlData(navigateAfterLogin);
    } else if (navigateAfterLogin && navigateAfterLogin.type === "view") {
      this.onNavigateByViewData(navigateAfterLogin);
    } else if (this.utilityService.getDecodedCurrentPath()) {
      this.onNavigationCurrentRoute();
    }
  }

  onNavigateByUrlData(navigateAfterLogin: NavigationRoute): void {
    if (navigateAfterLogin.data.navigateTo === "lobby") {
      this.commonService.onBroadcastNavigationData(navigateAfterLogin.data);

      if (navigateAfterLogin.data.group) {
        this.router.navigate([
          `${this.languageCode}/casino/${navigateAfterLogin.data.group}`,
        ]);
      }
    }

    if (navigateAfterLogin.data.navigateTo === "gameWindow") {
      this.gamePlayService.onSetGameCalledFrom("realgame");

      this.onSendMixPanelEvent(navigateAfterLogin.data);

      this.router.navigate([
        `${this.languageCode}/${this.translationService.get("url.game")}/${
          navigateAfterLogin.data.path
        }`,
      ]);
    }

    if (navigateAfterLogin.data.navigateTo === "livespins") {
      this.router.navigate([
        `${this.languageCode}/${navigateAfterLogin.data.navigateTo}`,
      ]);
    }
  }

  onNavigateByViewData(navigateAfterLogin: NavigationRoute): void {
    if (navigateAfterLogin.path === "confirmidentity") {
      this.onKycLevelDetails(navigateAfterLogin);
    } else {
      if (navigateAfterLogin.path === "rewards") {
        this.utilityService.updateActiveLeftMenu("rewards");
      }

      if (navigateAfterLogin.path === "deposit" && navigateAfterLogin.data) {
        this.cashierService.onSetActiveDepositBonus({
          bonusCode: navigateAfterLogin.data.bonusCode,
        });
      }

      const activeTab: ActiveTab = {
        tabName: navigateAfterLogin.path,
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);
    }
  }

  onKycLevelDetails(navigateAfterLogin: NavigationRoute): void {
    this.kycSubscription = this.kycService
      .onGetUserKycLevelDetails()
      .subscribe((kycDetailsToUnblock: KYCDetailsToUnblock) => {
        if (!kycDetailsToUnblock.enableKyc) {
          const activeTab: ActiveTab = {
            tabName: "menuOptions",
            showBackButton: true,
          };

          this.commonService.onBroadcastActiveAcountView(activeTab);
        } else {
          const activeTab: ActiveTab = {
            tabName: navigateAfterLogin.path,
            showBackButton: true,
          };

          this.utilityService.openAccountComponent(activeTab);
        }
      });
  }

  onNavigationCurrentRoute(): void {
    const pathName: string = this.utilityService.getDecodedCurrentPath();

    if (pathName.length <= 7) {
      this.router.navigate([`${this.languageCode}/casino`]);
    } else if (pathName.includes(this.translationService.get("url.game"))) {
      let gamePregmatic: GamePregmatic =
        this.gamePlayService.getCurrentGamePregmatic() as GamePregmatic;

      if (gamePregmatic && gamePregmatic.gameType) {
        gamePregmatic.gameType = "realgame";
      }

      this.gamePlayService.onSetGameCalledFrom("realgame");

      this.gamePlayService.onSetCurrentGamePregmatic(gamePregmatic);

      this.gamePlayService.onBroadcastIsRelaunchGame(true);
    }
  }

  onCloseComponent(): void {
    if (this.commonService.getNavigateAfterLogin()) {
      this.commonService.onSetNavigateAfterLogin(undefined);
    }

    this.utilityService.closeAccountComponent("");
  }

  onGoToForgotPage(): void {
    this.onCloseComponent();

    const activeTab: ActiveTab = {
      tabName: "forgot-password",
      showBackButton: true,
    };

    this.commonService.onBroadcastActiveAcountView(activeTab);
  }

  onOpenRegistrationPopup(): void {
    this.onCloseComponent();

    this.registrationService.onOpenRegistration();
  }

  onSendMixPanelEvent(gameDetails: NavigationData): void {
    if (Object.keys(gameDetails).length > 0) {
      let eventProperties: LoginEventProperties = {
        name: gameDetails.path,
        playType: "realgame",
        location: gameDetails.gameGroupName,
        category: gameDetails.lobbyName,
      };

      if (this.mixPanelService.getVerticalByUrl()) {
        eventProperties.vertical = this.mixPanelService.getVerticalByUrl();
      }

      this.mixPanelService.onTrackMixPanelEvents(
        mixPanelEventsConfigurations.launched_game,
        eventProperties
      );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.loginSubscription) this.loginSubscription.unsubscribe();

    if (this.kycSubscription) this.kycSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
