import { Component, Input, OnChanges, SimpleChange } from "@angular/core";

// Models
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";

@Component({
  selector: "app-upcoming-reward-card",
  templateUrl: "./upcoming-reward-card.component.html",
  styleUrls: ["./upcoming-reward-card.component.scss"],
})
export class UpcomingRewardCardComponent implements OnChanges {
  // Inputs
  @Input() bonusDetails: CampaignItem;

  // Strings
  bonusType: string = "";

  constructor() {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;

      this.bonusType = this.bonusDetails.bonusTypeFD;
    }
  }
}
