import { HostListener, Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
  // Strings
  languageCode: string = "";
  theme: string = "";

  // Enums
  windowType: "device" | "mobile" = "device";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    @Inject(DOCUMENT) private document: Document,
    private liveChatService: LiveChatService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.commonService.onBroadcastCurrent404Page("404");

    this.document.body.classList.add("page-not-found");
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.getWindowType();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.theme = this.utilityService.setLocaleBasedTheme();
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType() {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGoToHome(): void {
    this.router.navigate([`${this.languageCode}/casino`]);
  }

  onNavigate(route: string): void {
    this.router.navigate([`${this.languageCode}/${route}`]);
  }

  onInitiateLiveChat(): void {
    this.liveChatService.onInitializeChat();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    this.commonService.onBroadcastCurrent404Page("");

    this.document.body.classList.remove("page-not-found");
  }
}
